import { useQuery } from "react-query";
import { postsService } from "../../../services/PostApi";
import { Pagelimit } from "constants/data";


export const getPosts = async (page, limit) => {
  const res = await postsService.getPosts(page, limit);
  if (res) {
    return res;
  }
  return null;
};

// export default function usePosts() {
//   return useQuery('posts', getPosts);
// }
export default function usePosts(page = 1, limit = Pagelimit
  
) {
  return useQuery(["posts", page, limit], () => getPosts(page, limit));
}
