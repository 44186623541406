/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import YupPassword from "yup-password";
import LogoImage from "../../assets/images/logo-login.png";
import LoginImage from "../../assets/images/logo-login.webp";
import Emaillogo from "../../assets/images/emai-icon.png";
import LeftSidebar from "../../components/leftSideBar/LeftSideBar";
// import { AuthContext } from "../../context/authContext";
import ModalComponent from "../../components/modalComponent/ModalComponent";
import UserDetailForm from "./components/UserDetailForm";
// import { isNumber } from "../../helpers";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Swal from "sweetalert2";
import { AuthContext } from "context/authContext";

YupPassword(yup);
const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const schema = yup.object({
  email: yup.string().email().required(),

  password: yup
    .string()
    .password()
    .min(
      8,
      "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special"
    )
    .minLowercase(1, "password must contain at least 1 lower case letter")
    .minUppercase(1, "password must contain at least 1 upper case letter")
    .minNumbers(1, "password must contain at least 1 number")
    .minSymbols(1, "password must contain at least 1 special character"),
});

const Register = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  // const value = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const passwordValue = watch("password");
  const emailValue = watch("email");
  const value = useContext(AuthContext);
  useEffect(() => {
    value?.setAuth({
      token: "",
      isAuthenticated: false,
      message: "",
      userEmail: "",
      userId: "",
    });
  }, []);

  const onSubmit = async () => {
    // value?.register(data);
    if (isAcceptTermAndCondition) {
      return setModalShow(true);
    }
    Swal.fire({
      title: "Error",
      text: "Please accept Terms & Conditions",
      icon: "error",
      confirmButtonText: "Ok",
    });
  };

  const togglePasswordVisibility = () => {
    setPasswordShown(!passwordShown);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isAcceptTermAndCondition, setIsAcceptTermAndCondition] =
    useState(false);
  const [UserType, setUserType] = useState("Agent");
  return (
    <section className="main main-register">
      <div className="container">
        <div className="row flex-row-reverse">
          <div className="col-lg-6 m-auto">
            <div className="login mob main-right mx-4">
              <picture>
                <source srcSet={LoginImage} type="image/webp" />
                <source srcSet={LogoImage} type="image/png" />
                <img
                  loading="lazy"
                  src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                  srcSet="assets/images/logo.png"
                  alt="login logo"
                  className="img-fluid"
                  width="200"
                  height="92"
                />
              </picture>
              <h3>Create an Account</h3>
              <h5>
                  Connecting <strong>Renters</strong> with{" "}
                  <strong>Agents</strong> and <strong>Landlords</strong> in a few clicks 
              </h5>
              <form>
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="common-form">
                      <div className="btn-group reader-writer">
                        <input
                          type="radio"
                          className="btn-check"
                          name="reader"
                          id="Agent"
                          autoComplete="off"
                          defaultChecked
                          onClick={() => setUserType("Agent")}
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="Agent"
                        >
                          Agent
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="reader"
                          id="Landlord"
                          autoComplete="off"
                          onClick={() => setUserType("Landlord")}
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="Landlord"
                        >
                          Landlord
                        </label>
                      </div>
                      {/* {value?.auth?.message ? (
                        <div className="alert alert-danger" role="alert">
                          {value?.auth?.message}
                        </div>
                      ) : null} */}

                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            {...register("email")}
                          />
                          <span>
                            <img
                              src={Emaillogo}
                              alt="email"
                              width="16"
                              height="16"
                            />
                          </span>
                          <label htmlFor="floatingInput">Email ID</label>
                        </div>
                        {errors?.email?.message ? (
                          <div className="alert alert-danger" role="alert">
                            {errors?.email?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="mb-4">
                        <div className="form-floating">
                          <input
                            minLength="8"
                            name="password"
                            className="form-control"
                            id="floatingPassword"
                            placeholder="Password"
                            {...register("password")}
                            type={passwordShown ? "text" : "password"}
                          />
                          {/* // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                          <span
                            role="button"
                            onClick={togglePasswordVisibility}
                          >
                            {passwordShown ? eye : eyeSlash}
                          </span>
                          <label htmlFor="floatingPassword">Password</label>
                        </div>
                        {errors?.password?.message ? (
                          <div className="alert alert-danger" role="alert">
                            {errors?.password?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="mb-3 form-check text-start login-signup">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="exampleCheck1"
                          value={isAcceptTermAndCondition}
                          onClick={() =>
                            setIsAcceptTermAndCondition(
                              !isAcceptTermAndCondition
                            )
                          }
                        />
                        <label className="form-check-label" for="exampleCheck1">
                          I agree with{" "}
                          <a href="#" onClick={handleShow}>
                            Terms & Conditions .
                          </a>
                        </label>
                      </div>
                      <div className="mt-3">
                        <button
                          type="button"
                          className="btn btn-common w-100 mb-3"
                          onClick={handleSubmit(onSubmit)}
                        >
                          Sign Up
                        </button>
                      </div>
                      <div className="login-signup">
                        <div className="mb-0">
                          If you have already account!
                          <Link to="/login"> Login</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <LeftSidebar />

          {/* //////////////////photo upload model///////////////// */}

          {/* //////////////////////////xxxxx///////////////////////////////// */}

          <ModalComponent
            show={modalShow}
            onHide={() => setModalShow(false)}
            heading="Complete Profile"
            size="xs"
          >
            <UserDetailForm
              passwordValue={passwordValue}
              emailValue={emailValue}
              userType={UserType}
            />
          </ModalComponent>
          {/* <Modal
            show={isOpen}
            onHide={closeModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <h5 className="modal-title" id="exampleModalLabel">
                Complete Profile
              </h5>
            </Modal.Header>
            <Modal.Body>
              <Form
                email={registerData.email}
                password={registerData.password}
                userid={registerData.userid}
              />
            </Modal.Body>
          </Modal> */}
          <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            scrollable={true}
          >
            <Modal.Header closeButton>
              <Modal.Title>Terms & Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h2>
                <strong>Terms and Conditions</strong>
              </h2>
              <p>Welcome to Sociallocket!</p>
              <p>
                These terms and conditions outline the rules and regulations for
                the use of Sociallocket's Website, located at sociallocket.com.
              </p>
              <p>
                By accessing this website we assume you accept these terms and
                conditions. Do not continue to use Sociallocket if you do not
                agree to take all of the terms and conditions stated on this
                page.
              </p>
              <p>
                The following terminology applies to these Terms and Conditions,
                Privacy Statement and Disclaimer Notice and all Agreements:
                "Client", "You" and "Your" refers to you, the person log on this
                website and compliant to the Company's terms and conditions.
                "The Company", "Ourselves", "We", "Our" and "Us", refers to our
                Company. "Party", "Parties", or "Us", refers to both the Client
                and ourselves. All terms refer to the offer, acceptance and
                consideration of payment necessary to undertake the process of
                our assistance to the Client in the most appropriate manner for
                the express purpose of meeting the Client's needs in respect of
                provision of the Company's stated services, in accordance with
                and subject to, prevailing law of af. Any use of the above
                terminology or other words in the singular, plural,
                capitalization and/or he/she or they, are taken as
                interchangeable and therefore as referring to same.
              </p>
              <h3>
                <strong>Cookies</strong>
              </h3>
              <p>
                We employ the use of cookies. By accessing Sociallocket, you
                agreed to use cookies in agreement with the Sociallocket's
                Privacy Policy.{" "}
              </p>
              <p>
                Most interactive websites use cookies to let us retrieve the
                user's details for each visit. Cookies are used by our website
                to enable the functionality of certain areas to make it easier
                for people visiting our website. Some of our
                affiliate/advertising partners may also use cookies.
              </p>
              <h3>
                <strong>License</strong>
              </h3>
              <p>
                Unless otherwise stated, Sociallocket and/or its licensors own
                the intellectual property rights for all material on
                Sociallocket. All intellectual property rights are reserved. You
                may access this from Sociallocket for your own personal use
                subjected to restrictions set in these terms and conditions.
              </p>
              <p>You must not:</p>
              <ul>
                <li>Republish material from Sociallocket</li>
                <li>Sell, rent or sub-license material from Sociallocket</li>
                <li>Reproduce, duplicate or copy material from Sociallocket</li>
                <li>Redistribute content from Sociallocket</li>
              </ul>
              <p>
                This Agreement shall begin on the date hereof. Our Terms and
                Conditions were created with the help of the{" "}
                <a href="https://www.termsandconditionsgenerator.com/">
                  Free Terms and Conditions Generator
                </a>
                .
              </p>
              <p>
                Parts of this website offer an opportunity for users to post and
                exchange opinions and information in certain areas of the
                website. Sociallocket does not filter, edit, publish or review
                Comments prior to their presence on the website. Comments do not
                reflect the views and opinions of Sociallocket,its agents and/or
                affiliates. Comments reflect the views and opinions of the
                person who post their views and opinions. To the extent
                permitted by applicable laws, Sociallocket shall not be liable
                for the Comments or for any liability, damages or expenses
                caused and/or suffered as a result of any use of and/or posting
                of and/or appearance of the Comments on this website.
              </p>
              <p>
                Sociallocket reserves the right to monitor all Comments and to
                remove any Comments which can be considered inappropriate,
                offensive or causes breach of these Terms and Conditions.
              </p>
              <p>You warrant and represent that:</p>
              <ul>
                <li>
                  You are entitled to post the Comments on our website and have
                  all necessary licenses and consents to do so;
                </li>
                <li>
                  The Comments do not invade any intellectual property right,
                  including without limitation copyright, patent or trademark of
                  any third party;
                </li>
                <li>
                  The Comments do not contain any defamatory, libelous,
                  offensive, indecent or otherwise unlawful material which is an
                  invasion of privacy
                </li>
                <li>
                  The Comments will not be used to solicit or promote business
                  or custom or present commercial activities or unlawful
                  activity.
                </li>
              </ul>
              <p>
                You hereby grant Sociallocket a non-exclusive license to use,
                reproduce, edit and authorize others to use, reproduce and edit
                any of your Comments in any and all forms, formats or media.
              </p>
              <h3>
                <strong>Hyperlinking to our Content</strong>
              </h3>
              <p>
                The following organizations may link to our Website without
                prior written approval:
              </p>
              <ul>
                <li>Government agencies;</li>
                <li>Search engines;</li>
                <li>News organizations;</li>
                <li>
                  Online directory distributors may link to our Website in the
                  same manner as they hyperlink to the Websites of other listed
                  businesses; and
                </li>
                <li>
                  System wide Accredited Businesses except soliciting non-profit
                  organizations, charity shopping malls, and charity fundraising
                  groups which may not hyperlink to our Web site.
                </li>
              </ul>
              <p>
                These organizations may link to our home page, to publications
                or to other Website information so long as the link: (a) is not
                in any way deceptive; (b) does not falsely imply sponsorship,
                endorsement or approval of the linking party and its products
                and/or services; and (c) fits within the context of the linking
                party's site.
              </p>
              <p>
                We may consider and approve other link requests from the
                following types of organizations:
              </p>
              <ul>
                <li>
                  commonly-known consumer and/or business information sources;
                </li>
                <li>dot.com community sites;</li>
                <li>associations or other groups representing charities;</li>
                <li>online directory distributors;</li>
                <li>internet portals;</li>
                <li>accounting, law and consulting firms; and</li>
                <li>educational institutions and trade associations.</li>
              </ul>
              <p>
                We will approve link requests from these organizations if we
                decide that: (a) the link would not make us look unfavorably to
                ourselves or to our accredited businesses; (b) the organization
                does not have any negative records with us; (c) the benefit to
                us from the visibility of the hyperlink compensates the absence
                of Sociallocket; and (d) the link is in the context of general
                resource information.
              </p>
              <p>
                These organizations may link to our home page so long as the
                link: (a) is not in any way deceptive; (b) does not falsely
                imply sponsorship, endorsement or approval of the linking party
                and its products or services; and (c) fits within the context of
                the linking party's site.
              </p>
              <p>
                If you are one of the organizations listed in paragraph 2 above
                and are interested in linking to our website, you must inform us
                by sending an e-mail to Sociallocket. Please include your name,
                your organization name, contact information as well as the URL
                of your site, a list of any URLs from which you intend to link
                to our Website, and a list of the URLs on our site to which you
                would like to link. Wait 2-3 weeks for a response.
              </p>
              <p>
                Approved organizations may hyperlink to our Website as follows:
              </p>
              <ul>
                <li>By use of our corporate name; or</li>
                <li>
                  By use of the uniform resource locator being linked to; or
                </li>
                <li>
                  By use of any other description of our Website being linked to
                  that makes sense within the context and format of content on
                  the linking party's site.
                </li>
              </ul>
              <p>
                No use of Sociallocket's logo or other artwork will be allowed
                for linking absent a trademark license agreement.
              </p>
              <h3>
                <strong>iFrames</strong>
              </h3>
              <p>
                Without prior approval and written permission, you may not
                create frames around our Webpages that alter in any way the
                visual presentation or appearance of our Website.
              </p>
              <h3>
                <strong>Content Liability</strong>
              </h3>
              <p>
                We shall not be hold responsible for any content that appears on
                your Website. You agree to protect and defend us against all
                claims that is rising on your Website. No link(s) should appear
                on any Website that may be interpreted as libelous, obscene or
                criminal, or which infringes, otherwise violates, or advocates
                the infringement or other violation of, any third party rights.
              </p>
              <h3>
                <strong>Reservation of Rights</strong>
              </h3>
              <p>
                We reserve the right to request that you remove all links or any
                particular link to our Website. You approve to immediately
                remove all links to our Website upon request. We also reserve
                the right to amen these terms and conditions and it's linking
                policy at any time. By continuously linking to our Website, you
                agree to be bound to and follow these linking terms and
                conditions.
              </p>
              <h3>
                <strong>Removal of links from our website</strong>
              </h3>
              <p>
                If you find any link on our Website that is offensive for any
                reason, you are free to contact and inform us any moment. We
                will consider requests to remove links but we are not obligated
                to or so or to respond to you directly.
              </p>
              <p>
                We do not ensure that the information on this website is
                correct, we do not warrant its completeness or accuracy; nor do
                we promise to ensure that the website remains available or that
                the material on the website is kept up to date.
              </p>
              <h3>
                <strong>Disclaimer</strong>
              </h3>
              <p>
                To the maximum extent permitted by applicable law, we exclude
                all representations, warranties and conditions relating to our
                website and the use of this website. Nothing in this disclaimer
                will:
              </p>
              <ul>
                <li>
                  limit or exclude our or your liability for death or personal
                  injury;
                </li>
                <li>
                  limit or exclude our or your liability for fraud or fraudulent
                  misrepresentation;
                </li>
                <li>
                  limit any of our or your liabilities in any way that is not
                  permitted under applicable law; or
                </li>
                <li>
                  exclude any of our or your liabilities that may not be
                  excluded under applicable law.
                </li>
              </ul>
              <p>
                The limitations and prohibitions of liability set in this
                Section and elsewhere in this disclaimer: (a) are subject to the
                preceding paragraph; and (b) govern all liabilities arising
                under the disclaimer, including liabilities arising in contract,
                in tort and for breach of statutory duty.
              </p>
              <p>
                As long as the website and the information and services on the
                website are provided free of charge, we will not be liable for
                any loss or damage of any nature.
              </p>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </section>
  );
};

export default Register;
