import { in200s } from "helpers";
import { API } from "./ApiClient";

export const updateSetting = async (data, id) => {
  return API.put(`settings/${id}`, data)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => error.response);
};

export const getSetting = async (id) => {
  return API.get(`settings/${id}`)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => error.response);
};

