import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Select from "react-select";
import ProfileBannerImage from "../../assets/images/profile-banner.jpg";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "../../components/messageModal/MessageModal";
import { AuthContext } from "../../context/authContext";
import {
  getInitials,
  isNonEmptyString,
  removeWhitespaces,
} from "../../helpers";
import useSelectedTypesBy from "../../hooks/query/AllUserProfile/useSelectedUserTypes";
import useUsersById from "../../hooks/query/AllUserProfile/useUserById";
import { authService } from "../../services/AuthApi";
import {
  getUserBio,
  getUserFullName,
  getUserProfileImage,
} from "../../utils/Storage";
import DetailsForm from "./components/DetailsForm";
import VerifiedIcon from "assets/svg/verificationTick.png";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { useMutation, useQueryClient } from "react-query";
import { API } from "services/ApiClient";
import Swal from "sweetalert2";
import useGetIntegration, {
  useDeleteIntegration,
} from "hooks/query/integrations/useGetIntegration";
import { set } from "ramda";
import ModalComponent from "components/modalComponent/ModalComponent";
import SettingParent from "./SettingParent";

const IntegrationSetting = () => {
  const [refetch, setRefetch] = useState(false);
  const [isVerified, setIsVerified] = useState(true);
  const [isApexConnected, setIsApexConnected] = useState(false);
  const [isTenNintyConnected, setIsTenNintyConnected] = useState(false);
  const [isJupixConnected, setIsJupixConnected] = useState(false);
  const [isReapitConnected, setIsReapitConnected] = useState(false);
  const [isAltoConnected, setIsAltoConnected] = useState(false);
  const [isSignableConnected, setIsSignableConnected] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [integrationToDelete, setIntegrationToDelete] = useState(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userProfilePic = getUserProfileImage();
  const userBIO = getUserBio();
  const userFullName = getUserFullName();
  const userProfileText = getInitials(userFullName);
  const value = useContext(AuthContext);
  const [tenNintyApiKey, setTenNintyApiKey] = useState("");
  const [apex, setApex] = useState("");
  const [jupix, setJupix] = useState("");
  const [reapit, setReapit] = useState({
    username: "",
    password: "",
  });
  const [alto, setAlto] = useState({
    username: "",
    password: "",
    branchId: "",
  });
  const [signable, setSignable] = useState({
    username: "",
    password: "",
  });

  const [apexObj, setApexObj] = useState({});
  const [tenNintyObj, setTenNintyObj] = useState({});
  const [jupixObj, setJupixObj] = useState({});
  const [reapitObj, setReapitObj] = useState({
    username: "",
    password: "",
  });
  const [altobj, setAltobj] = useState({
    username: "",
    password: "",
    branchId: "",
  });
  const [signableObj, setSignableObj] = useState({
    username: "",
    password: "",
  });

  const [isEditing, setIsEditing] = useState({
    apex: false,
    tenNinty: false,
    jupix: false,
    reapit: false,
    alto: false,
    signable: false,
  });
  // const Id = value?.auth?.userId;
  const userId = value?.auth?.userId ? value?.auth?.userId.toString() : "";
  const userProfileUrl = userFullName ? userFullName.concat("_", userId) : "";

  const {
    isLoading: isIntegrationLoading,
    error: allIntegrationError,
    data: allIntegrationData,
  } = useGetIntegration(userId);

  const { mutate: deleteIntegration, isLoading: isDeleteIntegrationLoading } =
    useDeleteIntegration(userId);
  const logOut = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      showLoadingSpinner({});
      await authService.logout(userId);
    } catch (error) {
    } finally {
      await value?.logout();
      navigate("/login");
      hideLoadingSpinner();
    }
  };
  // console.log(value);
  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(value?.auth?.userId);
  const { data: userSelectedTypesData } = useSelectedTypesBy(
    value?.auth?.userId
  );
  const { mutate: updateIntegrations } = useMutation(
    async (payload) => {
      const res = await API.put(`integrations/${userId}`, payload);

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
        }).then((result) => {
          if (result.isConfirmed) {
            setRefetch(!refetch);
            setIsEditing({ apex: false, tenNinty: false, jupix: false });
            queryClient.invalidateQueries(["integrations"]);
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );

  const { mutate: integrations } = useMutation(
    async (payload) => {
      const res = await API.post("/integrations", payload);

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );

  const { mutate: apexIntegration } = useMutation(
    async (payload) => {
      const res = await API.post("integrations-apex27-listings", payload);

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            setRefetch(!refetch);

            // navigate("/");
            updateIntegrations(payload);
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );

  const { mutate: tenNintyIntegration } = useMutation(
    async (payload) => {
      console.log("payload", payload);

      const res = await API.post("integrations-tenninety-listings", payload);

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          setRefetch(!refetch);

          if (result.isConfirmed) {
            // navigate("/");
            updateIntegrations(payload);
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );

  const { mutate: jupixIntegration } = useMutation(
    async (payload) => {
      const res = await API.post("integrations-jupix-listings", payload);

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            // navigate("/");
            setRefetch(!refetch);

            updateIntegrations(payload);
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );

  const { mutate: reapitIntegration } = useMutation(
    async (payload) => {
      const res = await API.post("integrations-reapit-listings-rent", payload);

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            // navigate("/");
            setRefetch(!refetch);

            updateIntegrations(payload);
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );
  const { mutate: signableIntegration } = useMutation(
    async (payload) => {
      const res = await API.post(
        "integrations-signable-envelopes",
        payload
      );

      if (isNonEmptyString(res?.data?.message)) {
        // setShowModal(!showModal);
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            // navigate("/");
            setRefetch(!refetch);

            updateIntegrations(payload);
          }
        });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["integrations"]);
        }
      },
    }
  );

  const reapitOnChangeHandler = (e) => {
    const { name, value } = e.target;

    setReapit((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const altoOnChangeHandler = (e) => {
    const { name, value } = e.target;
    setAlto((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const signableChangeHandler = (e) => {
    const { name, value } = e.target;
    setSignable((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const apex27Handler = async () => {
    const obj = {
      id: apexObj?.id,
      user_id: userId,
      type: "apex27",
      integration_api_key: apex,
      active: 1,
    };

    if (Number(userDetailsData?.user_verification) !== 1) {
      Swal.fire({
        title: "Info",
        text: "Please verify your account by completing the verification process before integration",
        icon: "info",
      });
      return;
    }
    if (apex.trim(" ") === "") {
      Swal.fire({
        title: "Error",
        text: "Please enter the integration api key",
        icon: "error",
      });
      return;
    }

    if (isApexConnected && isEditing.apex) {
      updateIntegrations(obj);
    } else {
      apexIntegration(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    }
  };

  const tenNintyHandler = async () => {
    const obj = {
      id: tenNintyObj?.id,
      user_id: userId,
      type: "tenninety",
      integration_api_key: tenNintyApiKey,
      active: 1,
    };
    if (Number(userDetailsData?.user_verification) !== 1) {
      Swal.fire({
        title: "Info",
        text: "Please verify your account by completing the verification process before integration",
        icon: "info",
      });
      return;
    }

    if (tenNintyApiKey.trim(" ") === "") {
      Swal.fire({
        title: "Error",
        text: "Please enter the integration api key",
        icon: "error",
      });
      return;
    }

    if (isTenNintyConnected && isEditing.tenNinty) {
      updateIntegrations(obj);
    } else {
      tenNintyIntegration(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    }
  };

  const jupixHandler = async () => {
    const obj = {
      id: jupixObj?.id,
      user_id: userId,
      type: "jupix",
      integration_api_key: jupix,
      active: 1,
    };
    if (Number(userDetailsData?.user_verification) !== 1) {
      Swal.fire({
        title: "Info",
        text: "Please verify your account by completing the verification process before integration",
        icon: "info",
      });
      return;
    }

    if (jupix.trim(" ") === "") {
      Swal.fire({
        title: "Error",

        text: "Please enter the integration api key",
        icon: "error",
      });

      return;
    }

    if (isJupixConnected && isEditing.jupix) {
      // updateIntegrations(obj);
      jupixIntegration(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    } else {
      jupixIntegration(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    }
  };

  const reapitHandler = async () => {
    const obj = {
      user_id: userId,
      type: "reapit",
      connect_client_id: reapit.username,
      connect_client_secret: reapit.password,
    };
    const obj2 = {
      id: reapitObj?.id,

      user_id: userId,
      type: "reapit",
      integration_api_key: reapit.username,
      integration_api_client_id: reapit.password,
      active: reapitObj?.active,
    };

    if (Number(userDetailsData?.user_verification) !== 1) {
      Swal.fire({
        title: "Info",
        text: "Please verify your account by completing the verification process before integration",
        icon: "info",
      });
      return;
    }
    if (reapit.username.trim(" ") === "" || reapit.password.trim(" ") === "") {
      Swal.fire({
        title: "Error",

        text: "Please enter the integration api key",
        icon: "error",
      });

      return;
    }

    if (isReapitConnected && isEditing.reapit) {
      updateIntegrations(obj2);
    } else {
      reapitIntegration(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    }
  };

  const sigableHandler = async () => {
    const obj = {
      user_id: userId,
      type: "signable",
      connect_client_id: signable.username,
      connect_client_secret: signable.password,
    };
    const obj2 = {
      id: signableObj?.id,

      user_id: userId,
      type: "signable",
      integration_api_key: signable.username,
      integration_api_client_id: signable.password,
      active: signableObj?.active,
    };

    if (Number(userDetailsData?.user_verification) !== 1) {
      Swal.fire({
        title: "Info",
        text: "Please verify your account by completing the verification process before integration",
        icon: "info",
      });
      return;
    }
    if (
      signable.username.trim(" ") === "" ||
      signable.password.trim(" ") === ""
    ) {
      Swal.fire({
        title: "Error",

        text: "Please enter the integration api key",
        icon: "error",
      });

      return;
    }

    if (isSignableConnected && isEditing.signable) {
      updateIntegrations(obj2);
    } else {
      signableIntegration(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    }
  };

  useEffect(() => {
    const checkIntegrations = async () => {
      if (allIntegrationData && allIntegrationData?.length > 0) {
        setIsApexConnected(
          allIntegrationData?.some((item) => item?.type === "apex27")
        );
        setIsTenNintyConnected(
          allIntegrationData?.some((item) => item?.type === "tenninety")
        );
        setIsJupixConnected(
          allIntegrationData?.some((item) => item?.type === "jupix")
        );
        setIsReapitConnected(
          allIntegrationData?.some((item) => item?.type === "reapit")
        );
        setIsAltoConnected(
          allIntegrationData?.some((item) => item?.type === "alto")
        );
        setIsSignableConnected(
          allIntegrationData?.some((item) => item?.type === "signable")
        );

        const apexIntegration = allIntegrationData?.find(
          (item) => item?.type === "apex27"
        );
        if (apexIntegration) {
          setApex(apexIntegration?.integration_api_key);
          setApexObj(apexIntegration);
        }

        const tenNintyIntegration = allIntegrationData?.find(
          (item) => item?.type === "tenninety"
        );
        if (tenNintyIntegration) {
          setTenNintyApiKey(tenNintyIntegration?.integration_api_key);
          setTenNintyObj(tenNintyIntegration);
        }

        const jupixIntegration = allIntegrationData?.find(
          (item) => item?.type === "jupix"
        );
        if (jupixIntegration) {
          setJupix(jupixIntegration?.integration_api_key);
          setJupixObj(jupixIntegration);
        }

        const reapitIntegration = allIntegrationData?.find(
          (item) => item?.type === "reapit"
        );
        if (reapitIntegration) {
          setReapit({
            username: reapitIntegration?.integration_api_key,
            password: isNonEmptyString(
              reapitIntegration?.integration_api_client_id
            )
              ? reapitIntegration?.integration_api_client_id
              : "",
          });
          setReapitObj(reapitIntegration);
        }

        const altoIntegration = allIntegrationData?.find(
          (item) => item?.type === "alto"
        );
        if (altoIntegration) {
          setAlto({
            username: altoIntegration?.integration_api_key,
            password: altoIntegration?.integration_api_client_id,
            branchId: altoIntegration?.integration_api_secret,
          });
          setAltobj(altoIntegration);
        }

        const signableIntegration = allIntegrationData?.find(
          (item) => item?.type === "signable"
        );
        if (signableIntegration) {
          setSignable({
            username: signableIntegration?.integration_api_key,
            password: signableIntegration?.integration_api_client_id,
          });
          setSignableObj(signableIntegration);
        }
      } else if (allIntegrationData?.length === 0) {
        // alert("No integrations found");
        const apexIntegration = allIntegrationData?.find(
          (item) => item?.type === "apex27"
        );
        if (!apexIntegration) {
          setApex("");
          setApexObj({});
          setIsApexConnected(false);
        }

        const tenNintyIntegration = allIntegrationData?.find(
          (item) => item?.type === "tenninety"
        );
        if (!tenNintyIntegration) {
          setTenNintyApiKey("");
          setTenNintyObj({});
          setIsTenNintyConnected(false);
        }

        const jupixIntegration = allIntegrationData?.find(
          (item) => item?.type === "jupix"
        );
        if (!jupixIntegration) {
          setJupix("");
          setJupixObj({});

          setIsJupixConnected(false);
        }
        const reapitIntegration = allIntegrationData?.find(
          (item) => item?.type === "reapit"
        );
        if (!reapitIntegration) {
          setReapit({
            username: "",
            password: "",
          });
          setReapitObj({});
          setIsReapitConnected(false);
        }

        const altoIntegration = allIntegrationData?.find(
          (item) => item?.type === "alto"
        );
        if (!altoIntegration) {
          setAlto({
            username: "",
            password: "",
            branchId: "",
          });
          setAltobj({});
          setIsAltoConnected(false);
        }

        const signableIntegration = allIntegrationData?.find(
          (item) => item?.type === "signable"
        );
        if (!signableIntegration) {
          setSignable({
            username: "",
            password: "",
          });
          setSignableObj({});
          setIsSignableConnected(false);
        }
      }
    };
    checkIntegrations();
  }, [allIntegrationData, refetch]);
  // useEffect(() => {
  // const verify =
  //   Number(userDetailsData?.user_verification) === NaN
  //     ? true
  //     : Number(userDetailsData?.user_verification) === 1
  //     ? true
  //     : Number(userDetailsData?.user_verification) === 0
  //     ? false
  //     : true;
  //   const verify = isNaN(Number(userDetailsData?.user_verification))
  //     ? true
  //     : Number(userDetailsData?.user_verification) === 1;
  //     if (verify === false) {
  //       console.log("isVerified", verify);
  //       alert("alert")
  //     console.log(Number(userDetailsData?.user_verification));
  //     setIsVerified(verify);

  //     Swal.fire({
  //       title: "Info",
  //       text: "Please verify your account by completing the verification process before creating any listings.",
  //       icon: "info",
  //     });
  //   }
  // }, [userDetailsData]);

  const handleDisconnectClick = (integration) => {
    setIntegrationToDelete(integration);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {
    if (integrationToDelete) {
      deleteIntegration(integrationToDelete);
      setIsOpen(false);
      setIntegrationToDelete(null);
    }
  };
  // console.log("allIntegrationData", allIntegrationData);
  // console.log("tenNintyObj", tenNintyObj);
  // console.log("tenNintyApiKey", tenNintyApiKey);

  return (
    <>
      <SettingParent>
        <div className="integration_container">
          {/* apex27 integration */}
          <div className="integration_comp">
            <span className="icon apex-bg">apex27</span>

            <input
              className="form-control"
              type="text"
              name="apex"
              value={apex}
              onChange={(e) => setApex(e.target.value)}
              disabled={isApexConnected && !isEditing.apex}
              placeholder="API Key"
            />
            {isVerified &&
              (isApexConnected && !isEditing.apex ? (
                <div className="itg_btn-container">
                  <button
                    className="btn btn-common"
                    // onClick={() =>
                    //   deleteIntegration({
                    //     id: apexObj?.id,
                    //     user_id: userId,
                    //     type: "apex27",
                    //     integration_api_key: apex,
                    //   })
                    // }
                    onClick={() =>
                      handleDisconnectClick({
                        id: apexObj?.id,
                        user_id: userId,
                        type: "apex27",
                        integration_api_key: apex,
                      })
                    }
                  >
                    Disconnect
                  </button>
                  {/* <button className="btn btn-common">Import</button> */}
                  <button
                    className="btn btn-common"
                    onClick={() => setIsEditing({ ...isEditing, apex: true })}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <button className="btn btn-common" onClick={apex27Handler}>
                  Connect
                </button>
              ))}
          </div>
          {/* apex27 integration */}

          {/* 10ninty integration */}
          <div className="integration_comp">
            {/* <div className="integration_icon"> */}
            <span className="icon tenninty-bg">10ninty</span>
            {/* </div> */}
            <input
              className="form-control"
              type="text"
              name="tenNinty"
              value={tenNintyApiKey}
              onChange={(e) => setTenNintyApiKey(e.target.value)}
              disabled={isTenNintyConnected && !isEditing.tenNinty}
              placeholder="API Key"
            />
            {isVerified &&
              (isTenNintyConnected && !isEditing.tenNinty ? (
                <div className="itg_btn-container">
                  <button
                    className="btn btn-common"
                    // onClick={() => {
                    //   deleteIntegration({
                    //     id: tenNintyObj?.id,
                    //     user_id: userId,
                    //     type: "tenninety",
                    //     integration_api_key: tenNintyApiKey,
                    //   });
                    // }}
                    onClick={() =>
                      handleDisconnectClick({
                        id: tenNintyObj?.id,
                        user_id: userId,
                        type: "tenninety",
                        integration_api_key: tenNintyApiKey,
                      })
                    }
                  >
                    Disconnect
                  </button>
                  {/* <button className="btn btn-common">Import</button> */}
                  <button
                    className="btn btn-common"
                    onClick={() =>
                      setIsEditing({
                        ...isEditing,
                        tenNinty: true,
                      })
                    }
                  >
                    Update
                  </button>
                </div>
              ) : (
                <button className="btn btn-common" onClick={tenNintyHandler}>
                  Connect
                </button>
              ))}
          </div>
          {/* 10ninty integration */}

          {/* jupix integration */}
          <div className="integration_comp">
            {/* <div className="integration_icon"> */}
            <span className="icon jupix-bg">jupix</span>
            {/* </div> */}
            <input
              className="form-control"
              type="text"
              name="jupix"
              value={jupix}
              onChange={(e) => setJupix(e.target.value)}
              disabled={isJupixConnected && !isEditing.jupix}
              placeholder="API Key"
            />
            {isVerified &&
              (isJupixConnected && !isEditing.jupix ? (
                <div className="itg_btn-container">
                  <button
                    className="btn btn-common"
                    // onClick={() => {
                    //   deleteIntegration({
                    //     id: jupixObj?.id,
                    //     user_id: userId,
                    //     type: "jupix",
                    //     integration_api_key: jupix,
                    //   });
                    // }}

                    onClick={() =>
                      handleDisconnectClick({
                        id: jupixObj?.id,
                        user_id: userId,
                        type: "jupix",
                        integration_api_key: jupix,
                      })
                    }
                  >
                    Disconnect
                  </button>
                  {/* <button className="btn btn-common">Import</button> */}
                  <button
                    className="btn btn-common"
                    onClick={() => setIsEditing({ ...isEditing, jupix: true })}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <button className="btn btn-common" onClick={jupixHandler}>
                  Connect
                </button>
              ))}
          </div>
          {/* jupix integration */}

          {/* Reapit integration */}
          <div className="integration_comp">
            {/* <div className="integration_icon"> */}
            <span className="icon reapit-bg">Reapit</span>
            {/* </div> */}
            <div className="itg_input_con">
              <input
                className="form-control"
                type="text"
                name="username"
                value={reapit.username}
                onChange={reapitOnChangeHandler}
                disabled={isReapitConnected && !isEditing.reapit}
                placeholder="Username"
              />
              <input
                className="form-control"
                type="text"
                name="password"
                value={reapit.password}
                onChange={reapitOnChangeHandler}
                disabled={isReapitConnected && !isEditing.reapit}
                placeholder="Password"
              />
            </div>
            {isVerified &&
              (isReapitConnected && !isEditing.reapit ? (
                <div className="itg_btn-container">
                  <button
                    className="btn btn-common"
                    // onClick={() => {
                    //   deleteIntegration({
                    //     id: jupixObj?.id,
                    //     user_id: userId,
                    //     type: "jupix",
                    //     integration_api_key: jupix,
                    //   });
                    // }}

                    onClick={() =>
                      handleDisconnectClick({
                        id: reapitObj?.id,
                        user_id: userId,
                        type: "reapit",
                        integration_api_key: jupix,
                      })
                    }
                  >
                    Disconnect
                  </button>
                  {/* <button className="btn btn-common">Import</button> */}
                  <button
                    className="btn btn-common"
                    onClick={() => setIsEditing({ ...isEditing, reapit: true })}
                  >
                    Update
                  </button>
                </div>
              ) : (
                <button className="btn btn-common" onClick={reapitHandler}>
                  Connect
                </button>
              ))}
          </div>
          {/* Reapit integration */}

          {/* Alto integration*/}

          {/* <div className="integration_comp">
                          <span className="icon alto-bg">Alto</span>

                          <div className="itg_input_con">
                            <input
                              className="form-control"
                              type="text"
                              name="username"
                              value={alto.username}
                              onChange={altoOnChangeHandler}
                              disabled={isAltoConnected && !isEditing.alto}
                              placeholder="Username"
                            />
                            <input
                              className="form-control"
                              type="text"
                              name="password"
                              value={alto.password}
                              onChange={altoOnChangeHandler}
                              disabled={isAltoConnected && !isEditing.alto}
                              placeholder="Password"
                            />
                            <input
                              className="form-control"
                              type="text"
                              name="branchId"
                              value={alto.branchId}
                              onChange={altoOnChangeHandler}
                              disabled={isAltoConnected && !isEditing.alto}
                              placeholder="Branch ID"
                            />
                          </div>
                          {!isVerified &&
                            (isAltoConnected && !isEditing.alto ? (
                              <div className="itg_btn-container">
                                <button
                                  className="btn btn-common"
                                  onClick={() =>
                                    handleDisconnectClick({
                                      id: jupixObj?.id,
                                      user_id: userId,
                                      type: "jupix",
                                      integration_api_key: jupix,
                                    })
                                  }
                                >
                                  Disconnect
                                </button>

                                <button
                                  className="btn btn-common"
                                  onClick={() =>
                                    setIsEditing({ ...isEditing, jupix: true })
                                  }
                                >
                                  Update
                                </button>
                              </div>
                            ) : (
                              <button
                                className="btn btn-common"
                                onClick={jupixHandler}
                              >
                                Connect
                              </button>
                            ))}
                        </div> */}

          {/* Alto integration*/}

          {/* Signable  integration*/}
          <div className="integration_comp">
            {/* <div className="integration_icon"> */}
            <span className="icon signable-bg">Signable</span>
            {/* </div> */}
            <div className="itg_input_con">
              <input
                className="form-control"
                type="text"
                name="username"
                value={signable.username}
                onChange={signableChangeHandler}
                disabled={isSignableConnected && !isEditing.signable}
                placeholder="Username"
              />
              <input
                className="form-control"
                type="text"
                name="password"
                value={signable.password}
                onChange={signableChangeHandler}
                disabled={isSignableConnected && !isEditing.signable}
                placeholder="Password"
              />
            </div>
            {isVerified &&
              (isSignableConnected && !isEditing.signable ? (
                <div className="itg_btn-container">
                  <button
                    className="btn btn-common"
                    // onClick={() => {
                    //   deleteIntegration({
                    //     id: jupixObj?.id,
                    //     user_id: userId,
                    //     type: "jupix",
                    //     integration_api_key: jupix,
                    //   });
                    // }}

                    onClick={() =>
                      handleDisconnectClick({
                        id: signableObj?.id,
                        user_id: userId,
                        type: "signable",
                        integration_api_key: jupix,
                      })
                    }
                  >
                    Disconnect
                  </button>
                  {/* <button className="btn btn-common">Import</button> */}
                  <button
                    className="btn btn-common"
                    onClick={() =>
                      setIsEditing({
                        ...isEditing,
                        signable: true,
                      })
                    }
                  >
                    Update
                  </button>
                </div>
              ) : (
                <button className="btn btn-common" onClick={sigableHandler}>
                  Connect
                </button>
              ))}
          </div>
          {/* Signable  integration*/}
        </div>
      </SettingParent>

      <ModalComponent
        show={isOpen}
        onHide={() => setIsOpen(false)}
        size="xs"
        heading="Confirm Disconnect"
      >
        <p>Are you sure you want to disconnect?</p>
        <div className="d-flex align-items-center justify-content-center gap-2">
          <button className="btn btn-common" onClick={handleConfirmDelete}>
            Yes
          </button>
          <button className="btn btn-common" onClick={() => setIsOpen(false)}>
            No
          </button>
        </div>
      </ModalComponent>
    </>
  );
};

export default IntegrationSetting;
