import { useContext, useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Select from "react-select";
import ProfileBannerImage from "../../assets/images/profile-banner.jpg";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "../../components/messageModal/MessageModal";
import { AuthContext } from "../../context/authContext";
import {
  getInitials,
  isNonEmptyString,
  removeWhitespaces,
} from "../../helpers";
import useSelectedTypesBy from "../../hooks/query/AllUserProfile/useSelectedUserTypes";
import useUsersById from "../../hooks/query/AllUserProfile/useUserById";
import { authService } from "../../services/AuthApi";
import {
  getUserBio,
  getUserEmail,
  getUserFullName,
  getUserProfileImage,
} from "../../utils/Storage";
import DetailsForm from "./components/DetailsForm";
import VerifiedIcon from "assets/svg/verificationTick.png";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { BsInstagram } from "react-icons/bs";
import { useMutation, useQueryClient } from "react-query";
import { API } from "services/ApiClient";
import Swal from "sweetalert2";
import useGetIntegration, {
  useDeleteIntegration,
} from "hooks/query/integrations/useGetIntegration";
import { set } from "ramda";
import ModalComponent from "components/modalComponent/ModalComponent";
import { updateSetting } from "services/settings";
import useGetSettings from "hooks/query/settings/useSettings";
import SettingParent from "./SettingParent";

const EmailSetting = () => {
  const userEmail = getUserEmail();
  const [isEmailOption, setIsEmailOption] = useState(false);
  const [isuser_posts_like_notifications, setIsuser_posts_like_notifications] =
    useState(false);
  const [
    isuser_posts_comments_notifications,
    setIsuser_posts_comments_notifications,
  ] = useState(false);
  const [
    isuser_posts_comments_like_notifications,
    setIsuser_posts_comments_like_notifications,
  ] = useState(false);
  const [
    isuser_posts_groups_notifications,
    setIsuser_posts_groups_notifications,
  ] = useState(false);
  const [
    isuser_posts_groups_add_member_notifications,
    setIsuser_posts_groups_add_member_notifications,
  ] = useState(false);
  const [
    isuser_posts_groups_add_admin_notifications,
    setIsuser_posts_groups_add_admin_notifications,
  ] = useState(false);
  const [
    isuser_message_member_notifications,
    setIsuser_message_member_notifications,
  ] = useState(false);
  const [isuser_request_notifications, setIsuser_request_notifications] =
    useState(false);
  const [isuser_offers_notifications, setIsuser_offers_notifications] =
    useState(false);
  const [isuser_events_notifications, setIsuser_events_notifications] =
    useState(false);
  const [isproperty_wanted_notifications, setIsproperty_wanted_notifications] =
    useState(false);
  const [
    isproperty_wanted_like_notifications,
    setIsproperty_wanted_like_notifications,
  ] = useState(false);

  const [
    isproperty_wanted_comments_notifications,
    setIsproperty_wanted_comments_notifications,
  ] = useState(false);
  const [
    isproperty_wanted_comments_like_notifications,
    setIsproperty_wanted_comments_like_notifications,
  ] = useState(false);
  const [ismissing_images_notifications, setIsmissing_images_notifications] =
    useState(false);
  const [isdelete_posts_notifications, setIsdelete_posts_notifications] =
    useState(false);
  const [ismatched_notifications, Ismatched_notifications] = useState(false);

  const [
    isposts_group_removed_member_notifications,
    setIsposts_group_removed_member_notifications,
  ] = useState(false);
  const [
    isposts_group_removed_admin_notifications,
    setIsposts_group_removed_admin_notifications,
  ] = useState(false);

  const [
    isenvelope_notifications,
    setenvelope_notifications,
  ] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const userProfilePic = getUserProfileImage();
  const userBIO = getUserBio();
  const userFullName = getUserFullName();
  const userProfileText = getInitials(userFullName);
  const value = useContext(AuthContext);
  const userId = value?.auth?.userId ? value?.auth?.userId.toString() : "";
  const {
    isLoading: isSettingLoading,
    error: isSettingError,
    data: allSettingData,
  } = useGetSettings(userId);

  console.log("allSetting Data", allSettingData?.result[0]);

  // const Id = value?.auth?.userId;
  const userProfileUrl = userFullName ? userFullName.concat("_", userId) : "";

  useEffect(() => {
    if (allSettingData) {
      setIsEmailOption(
        allSettingData?.result[0]?.email_options &&
          (Number(allSettingData?.result[0]?.email_options) === 0
            ? true
            : false)
      );
      setIsuser_posts_like_notifications(
        allSettingData?.result[0]?.user_posts_like_notifications &&
          (Number(allSettingData?.result[0]?.user_posts_like_notifications) ===
          0
            ? true
            : false)
      );
      setIsuser_posts_comments_notifications(
        allSettingData?.result[0]?.user_posts_comments_notifications &&
          (Number(
            allSettingData?.result[0]?.user_posts_comments_notifications
          ) === 0
            ? true
            : false)
      );
      setIsuser_posts_comments_like_notifications(
        allSettingData?.result[0]?.user_posts_comments_like_notifications &&
          (Number(
            allSettingData?.result[0]?.user_posts_comments_like_notifications
          ) === 0
            ? true
            : false)
      );
      setIsuser_posts_groups_notifications(
        allSettingData?.result[0]?.user_posts_groups_notifications &&
          (Number(
            allSettingData?.result[0]?.user_posts_groups_notifications
          ) === 0
            ? true
            : false)
      );
      setIsuser_posts_groups_add_member_notifications(
        allSettingData?.result[0]?.user_posts_groups_add_member_notifications &&
          (Number(
            allSettingData?.result[0]
              ?.user_posts_groups_add_member_notifications
          ) === 0
            ? true
            : false)
      );
      setIsuser_posts_groups_add_admin_notifications(
        allSettingData?.result[0]?.user_posts_groups_add_admin_notifications &&
          (Number(
            allSettingData?.result[0]?.user_posts_groups_add_admin_notifications
          ) === 0
            ? true
            : false)
      );
      setIsuser_message_member_notifications(
        allSettingData?.result[0]?.user_message_member_notifications &&
          (Number(
            allSettingData?.result[0]?.user_message_member_notifications
          ) === 0
            ? true
            : false)
      );
      setIsuser_request_notifications(
        allSettingData?.result[0]?.user_request_notifications &&
          (Number(allSettingData?.result[0]?.user_request_notifications) === 0
            ? true
            : false)
      );
      setIsuser_offers_notifications(
        allSettingData?.result[0]?.user_offers_notifications &&
          (Number(allSettingData?.result[0]?.user_offers_notifications) === 0
            ? true
            : false)
      );
      setIsuser_events_notifications(
        allSettingData?.result[0]?.user_events_notifications &&
          (Number(allSettingData?.result[0]?.user_events_notifications) === 0
            ? true
            : false)
      );
      setIsproperty_wanted_notifications(
        allSettingData?.result[0]?.property_wanted_notifications &&
          (Number(allSettingData?.result[0]?.property_wanted_notifications) ===
          0
            ? true
            : false)
      );
      setIsproperty_wanted_like_notifications(
        allSettingData?.result[0]?.property_wanted_like_notifications &&
          (Number(
            allSettingData?.result[0]?.property_wanted_like_notifications
          ) === 0
            ? true
            : false)
      );

      setIsproperty_wanted_comments_notifications(
        allSettingData?.result[0]?.property_wanted_comments_notifications &&
          (Number(
            allSettingData?.result[0]?.property_wanted_comments_notifications
          ) === 0
            ? true
            : false)
      );
      setIsproperty_wanted_comments_like_notifications(
        allSettingData?.result[0]
          ?.property_wanted_comments_like_notifications &&
          (Number(
            allSettingData?.result[0]
              ?.property_wanted_comments_like_notifications
          ) === 0
            ? true
            : false)
      );
      setIsmissing_images_notifications(
        allSettingData?.result[0]?.missing_images_notifications &&
          (Number(allSettingData?.result[0]?.missing_images_notifications) === 0
            ? true
            : false)
      );
      setIsdelete_posts_notifications(
        allSettingData?.result[0]?.delete_posts_notifications &&
          (Number(allSettingData?.result[0]?.delete_posts_notifications) === 0
            ? true
            : false)
      );
      Ismatched_notifications(
        allSettingData?.result[0]?.matched_notifications &&
          (Number(allSettingData?.result[0]?.matched_notifications) === 0
            ? true
            : false)
      );

      setenvelope_notifications(
        allSettingData?.result[0]?.envelope_notifications &&
          (Number(allSettingData?.result[0]?.envelope_notifications) === 0
            ? true
            : false)
      );

      setIsposts_group_removed_member_notifications(
        allSettingData?.result[0]?.posts_group_removed_member_notifications &&
          (Number(
            allSettingData?.result[0]?.posts_group_removed_member_notifications
          ) === 0
            ? true
            : false)
      );
      setIsposts_group_removed_admin_notifications(
        allSettingData?.result[0]?.posts_group_removed_admin_notifications &&
          (Number(
            allSettingData?.result[0]?.posts_group_removed_admin_notifications
          ) === 0
            ? true
            : false)
      );
    }
  }, [allSettingData]);

  const logOut = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      showLoadingSpinner({});
      await authService.logout(userId);
    } catch (error) {
    } finally {
      await value?.logout();
      navigate("/login");
      hideLoadingSpinner();
    }
  };
  // console.log(value);
  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(value?.auth?.userId);
  const { data: userSelectedTypesData } = useSelectedTypesBy(
    value?.auth?.userId
  );
  const [isToggled, setIsToggled] = useState(false);

  const handleChange = () => {
    setIsToggled(!isToggled);
  };

  const { mutate: updateMutate } = useMutation(updateSetting, {
    onMutate: () => showLoadingSpinner({}),
    onSuccess: async (res) => {
      Swal.fire({
        title: "Success",
        text: res?.data?.message,
        icon: "success",
        confirmButtonText: "Ok",
      });
      queryClient.invalidateQueries(["settings", userId]);
      // setShowModal(!showModal);
    },
    onError: (err, data) => {
      Swal.fire({
        title: "Update Listing",
        text: err?.response?.data?.message,
        icon: "error",
      });
    },
    onSettled: () => hideLoadingSpinner(),
  });

  const handleupdateSetting = async () => {
    const data = {
      user_id: value?.auth?.userId,
      email: userEmail,
      email_options: isEmailOption ? 1 : null,
      user_posts_like_notifications: isuser_posts_like_notifications ? 1 : null,
      user_posts_comments_notifications: isuser_posts_comments_notifications
        ? 1
        : null,
      user_posts_comments_like_notifications:
        isuser_posts_comments_like_notifications ? 1 : null,
      user_posts_groups_notifications: isuser_posts_groups_notifications
        ? 1
        : null,
      user_posts_groups_add_member_notifications:
        isuser_posts_groups_add_member_notifications ? 1 : null,
      user_posts_groups_add_admin_notifications:
        isuser_posts_groups_add_admin_notifications ? 1 : null,
      user_message_member_notifications: isuser_message_member_notifications
        ? 1
        : null,
      user_request_notifications: isuser_request_notifications ? 1 : null,
      user_offers_notifications: isuser_offers_notifications ? 1 : null,
      user_events_notifications: isuser_events_notifications ? 1 : null,
      property_wanted_notifications: isproperty_wanted_notifications ? 1 : null,
      property_wanted_like_notifications: isproperty_wanted_like_notifications
        ? 1
        : null,
      property_wanted_comments_notifications:
        isproperty_wanted_comments_notifications ? 1 : null,
      property_wanted_comments_like_notifications:
        isproperty_wanted_comments_like_notifications ? 1 : null,
      missing_images_notifications: ismissing_images_notifications ? 1 : null,
      delete_posts_notifications: isdelete_posts_notifications ? 1 : null,
      matched_notifications: ismatched_notifications ? 1 : null,
      posts_group_removed_member_notifications:
        isposts_group_removed_member_notifications ? 1 : null,
      posts_group_removed_admin_notifications:
        isposts_group_removed_admin_notifications ? 1 : null,
      envelope_notifications: isenvelope_notifications ? 1 : null,
    };

    console.log(data);

    updateMutate(data, value?.auth?.userId);
  };
  // console.log(isdelete_posts_notifications);

  return (
    <>
      <SettingParent>
        <div className="email-setting-container">
          {/* email_options */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>Email notifications</h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isEmailOption ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isEmailOption ? "pdf-right" : ""
                  }`}
                  onClick={() => setIsEmailOption(!isEmailOption)}
                ></div>
              </div>
              <span>{isEmailOption ? "Enabled" : "Disabled"}</span>
            </div>
            <p style={{ flex: 1 }}>Receive email notifications.</p>
          </div>
          {/* email_options */}

          {/* user_posts_like_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone likes your listing
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_posts_like_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_posts_like_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_posts_like_notifications(
                      !isuser_posts_like_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_posts_like_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Likes are a way for people to show interest in properties. Likes
              are also used to save properties to your history. If someone likes
              your listing, don't ignore them.
            </p>
          </div>
          {/* user_posts_like_notifications */}

          {/* user_posts_comments_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone comments on your listing
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_posts_comments_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_posts_comments_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_posts_comments_notifications(
                      !isuser_posts_comments_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_posts_comments_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Comments are used as a way to communicate and or enquire about
              properties. When this notifcation is left on, listings stand a
              higher chance at being sold and or rented.
            </p>
          </div>
          {/* user_posts_comments_notifications */}

          {/* user_posts_comments_like_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone likes a comment on your listing
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_posts_comments_like_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_posts_comments_like_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_posts_comments_like_notifications(
                      !isuser_posts_comments_like_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_posts_comments_like_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Don't miss out on discussions being held about your property.
              People often leave comments that lead to successful sales and
              rentals.
            </p>
          </div>
          {/* user_posts_comments_like_notifications */}

          {/* user_posts_groups_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone creates a group for your listings
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_posts_groups_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_posts_groups_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_posts_groups_notifications(
                      !isuser_posts_groups_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_posts_groups_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Stay up to date on the people being added to your property deals
              and portfolios. Access levels control who can view, edit and
              delete documents, tenancy agreements, contracts, etc.
            </p>
          </div>
          {/* user_posts_groups_notifications */}

          {/* user_posts_groups_add_member_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone adds a member to a group
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_posts_groups_add_member_notifications
                    ? "pdf-right"
                    : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_posts_groups_add_member_notifications
                      ? "pdf-right"
                      : ""
                  }`}
                  onClick={() =>
                    setIsuser_posts_groups_add_member_notifications(
                      !isuser_posts_groups_add_member_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_posts_groups_add_member_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Stay up to date on the people being added to your property deals
              and portfolios. Access levels control who can view, edit and
              delete documents, tenancy agreements, contracts, etc.
            </p>
          </div>
          {/* user_posts_groups_add_member_notifications */}

          {/* user_posts_groups_add_admin_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone adds an admin to a group
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_posts_groups_add_admin_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_posts_groups_add_admin_notifications
                      ? "pdf-right"
                      : ""
                  }`}
                  onClick={() =>
                    setIsuser_posts_groups_add_admin_notifications(
                      !isuser_posts_groups_add_admin_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_posts_groups_add_admin_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Stay up to date with the permissions and people involve in your
              property deals and portfolios. Access levels control who can view,
              edit and delete documents, tenancy agreements, contracts, etc.
            </p>
          </div>
          {/* user_posts_groups_add_admin_notifications */}

          {/* user_message_member_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Property enquiries
              {/* Get notified when someone sends you a message about a property */}
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_message_member_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_message_member_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_message_member_notifications(
                      !isuser_message_member_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_message_member_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Never miss an enquiry again. Receive notifcations whilst on the
              go.
            </p>
          </div>
          {/* user_message_member_notifications */}

          {/* user_request_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone has added you to their connections
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_request_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_request_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_request_notifications(
                      !isuser_request_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isuser_request_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Connections allow you to build your network. The bigger your
              network, the more chance you have at completing a succesful sale
              and or rental.
            </p>
          </div>
          {/* user_request_notifications */}

          {/* user_offers_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>Know when someone has sent you an offer</h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_offers_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_offers_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_offers_notifications(!isuser_offers_notifications)
                  }
                ></div>
              </div>
              <span>
                {isuser_offers_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Never miss out on an offer again! Always received notifcations of
              all offers.
            </p>
          </div>
          {/* user_offers_notifications */}

          {/* user_events_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when someone has added an viewing (event, tasks,
              request) to your calendar
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isuser_events_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isuser_events_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsuser_events_notifications(!isuser_events_notifications)
                  }
                ></div>
              </div>
              <span>
                {isuser_events_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Allow viewings, bookings, events and other important meetings to
              be booked directly into your calendar.
            </p>
          </div>
          {/* user_events_notifications */}

          {/* property_wanted_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Receive notifications for properties others are interested in
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isproperty_wanted_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isproperty_wanted_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsproperty_wanted_notifications(
                      !isproperty_wanted_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isproperty_wanted_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Are you able to help someone looking for a property? If so, then
              this is a notifcation you want to leave on!
            </p>
          </div>
          {/* property_wanted_notifications */}

          {/* property_wanted_like_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Receive notifications when someone likes adverts of properties you
              are searching for
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isproperty_wanted_like_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isproperty_wanted_like_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsproperty_wanted_like_notifications(
                      !isproperty_wanted_like_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isproperty_wanted_like_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              If someone likes an advert then this means something right? It's
              important to follow up on this.
            </p>
          </div>
          {/* property_wanted_like_notifications */}

          {/* property_wanted_comments_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Receive notifications when someone comments on an advert of
              properties you are searching for
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isproperty_wanted_comments_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isproperty_wanted_comments_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsproperty_wanted_comments_notifications(
                      !isproperty_wanted_comments_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isproperty_wanted_comments_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Don't miss out on discussions being held about properties you
              want. People often leave comments that lead to successful sales
              and rentals.
            </p>
          </div>
          {/* property_wanted_comments_notifications */}

          {/* property_wanted_comments_like_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Receive notifications when people are talking/discussing
              properties you are searching for
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isproperty_wanted_comments_like_notifications
                    ? "pdf-right"
                    : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isproperty_wanted_comments_like_notifications
                      ? "pdf-right"
                      : ""
                  }`}
                  onClick={() =>
                    setIsproperty_wanted_comments_like_notifications(
                      !isproperty_wanted_comments_like_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isproperty_wanted_comments_like_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Don't miss out on discussions being held about properties you
              want. This is one of the quickest ways to to buy, sell and rent
              properties.
            </p>
          </div>
          {/* property_wanted_comments_like_notifications */}

          {/* missing_images_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when a listing is missing images
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  ismissing_images_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    ismissing_images_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsmissing_images_notifications(
                      !ismissing_images_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {ismissing_images_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              All listings should have images (photos of the property
              advertised). All listings with image missing risk being deleted as
              this violates our terms & conditions. If delete, your listings can
              be re-activated within 30 days of being de-listed.
            </p>
          </div>
          {/* missing_images_notifications */}

          {/* delete_posts_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Get notified when a listing is going to be deleted
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isdelete_posts_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isdelete_posts_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsdelete_posts_notifications(
                      !isdelete_posts_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isdelete_posts_notifications ? "Enabled" : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Listings are deleted when they violate our terms & conditions.
              Your listings can be re-activated within 30 days of being
              de-listed.
            </p>
          </div>
          {/* delete_posts_notifications */}

          {/* matched_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Recieved notifications when someone has qualified for your
              property
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  ismatched_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    ismatched_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    Ismatched_notifications(!ismatched_notifications)
                  }
                ></div>
              </div>
              <span>{ismatched_notifications ? "Enabled" : "Disabled"}</span>
            </div>
            <p style={{ flex: 1 }}>
              Grow your portfolio but taking advantage of automated lead
              generation. Others will be qualified and recommended to you based
              on your portfolio. The more properties you have listed, the more
              leads you generate!
            </p>
          </div>
          {/* matched_notifications */}

          {/* posts_group_removed_member_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Recieved notifications when a member has been removed from a group
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isposts_group_removed_member_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isposts_group_removed_member_notifications
                      ? "pdf-right"
                      : ""
                  }`}
                  onClick={() =>
                    setIsposts_group_removed_member_notifications(
                      !isposts_group_removed_member_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isposts_group_removed_member_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Stay up to date on the people being added to your property deals
              and portfolios. Access levels control who can view, edit and
              delete documents, tenancy agreements, contracts, etc.
            </p>
          </div>
          {/* posts_group_removed_member_notifications */}

          {/* posts_group_removed_admin_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Recieved notifications when an admin has been removed from a group
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isposts_group_removed_admin_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isposts_group_removed_admin_notifications ? "pdf-right" : ""
                  }`}
                  onClick={() =>
                    setIsposts_group_removed_admin_notifications(
                      !isposts_group_removed_admin_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isposts_group_removed_admin_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Stay up to date with the permissions and people involve in your
              property deals and portfolios. Access levels control who can view,
              edit and delete documents, tenancy agreements, contracts, etc.
            </p>
          </div>
          {/* posts_group_removed_admin_notifications */}

          {/* envelope_notifications */}
          <div className="email-setting">
            <h5 style={{ flex: 1 }}>
              Recieved notifications when a document, contract or tenanct agreement is signed
            </h5>
            <div style={{ flex: 1 }} className="flex align-items-center gap-2">
              <div
                className={`toggle-container ${
                  isenvelope_notifications ? "pdf-right" : ""
                }`}
              >
                <div
                  className={`toggle-circle ${
                    isenvelope_notifications
                      ? "pdf-right"
                      : ""
                  }`}
                  onClick={() =>
                    setenvelope_notifications(
                      !isenvelope_notifications
                    )
                  }
                ></div>
              </div>
              <span>
                {isenvelope_notifications
                  ? "Enabled"
                  : "Disabled"}
              </span>
            </div>
            <p style={{ flex: 1 }}>
              Stay up to date with all documents, contracts & tenancy agreements being signed.
            </p>
          </div>
          {/* envelope_notifications */}

        </div>
        <div className="flex justify-content-end">
          <button className="btn btn-common" onClick={handleupdateSetting}>
            Update
          </button>
        </div>
      </SettingParent>
    </>
  );
};

export default EmailSetting;