// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_logo_container {
  /* width: 80px;
  height: 38px;
  /* width: 70px;
  height: 70px; */ 
  width: 100% !important;
  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--brand-color);
  border-radius: 3px;
  padding: 0px 5px;
  word-wrap: break-word;
  word-break: break-all;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out; 
  animation: scalling 0.8s ease-in-out alternate infinite;
}
.payment_logo_container:hover {
  background-color: var(--brand-hover);
}

.payment_logo_container > img {
  /* width: 100%;
  height: 100%; */
  width: 50px;
  height: 30px;

  object-fit: contain;
}

@media screen and (max-width: 768px) {
  .payment_logo_container > img {
    /* width: 100%;
    height: 100%; */
    width: 40px;
    height: 25px;
  
    object-fit: contain;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/paymentLogo/index.css"],"names":[],"mappings":"AAAA;EACE;;;iBAGe;EACf,sBAAsB;;EAEtB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;EACf,kBAAkB;EAClB,gCAAgC;EAChC,uDAAuD;AACzD;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE;iBACe;EACf,WAAW;EACX,YAAY;;EAEZ,mBAAmB;AACrB;;AAEA;EACE;IACE;mBACe;IACf,WAAW;IACX,YAAY;;IAEZ,mBAAmB;EACrB;AACF","sourcesContent":[".payment_logo_container {\n  /* width: 80px;\n  height: 38px;\n  /* width: 70px;\n  height: 70px; */ \n  width: 100% !important;\n  \n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--brand-color);\n  border-radius: 3px;\n  padding: 0px 5px;\n  word-wrap: break-word;\n  word-break: break-all;\n  cursor: pointer;\n  position: relative;\n  transition: all 0.3s ease-in-out; \n  animation: scalling 0.8s ease-in-out alternate infinite;\n}\n.payment_logo_container:hover {\n  background-color: var(--brand-hover);\n}\n\n.payment_logo_container > img {\n  /* width: 100%;\n  height: 100%; */\n  width: 50px;\n  height: 30px;\n\n  object-fit: contain;\n}\n\n@media screen and (max-width: 768px) {\n  .payment_logo_container > img {\n    /* width: 100%;\n    height: 100%; */\n    width: 40px;\n    height: 25px;\n  \n    object-fit: contain;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
