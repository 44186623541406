import { useQuery } from "react-query";

const { userService } = require("services/UserService");

const getUserPersonabyId = async (id) => {
    const res = await userService.getUserPersonalDetails(id);
    if (res) {
        return res;
    }
    return null;
}


export default function useUserPersonaById(id) {
    return useQuery(["user-persona", id], () => getUserPersonabyId(id), {
        enabled: !!id
    });
}

