import { useQuery } from "react-query";
import { postsService } from "../../../services/PostApi";
import { getPropertyWanted } from "services/notificationApi";

const getPostsBy = async (postId) => {
  const res = await postsService.getPostsById(postId);

  if (res) {
    return res;
  }
  return null;
};

// export default function usePostsById(id,pathname) {
//   const result = useQuery(['posts-id', id], () => pathname.startsWith==="/post"?getPostsBy(id):);
//   return result;
// }

const getMatchedListingBy = async (postId) => {
  const res = await postsService.getMatchedListing(postId);
 

  if (res) {
    return res;
  }
  return null;
}

export default function usePostsById(id, pathname,isMatched=false) {


  
  // const result = useQuery(["posts-id", id], () =>
  //   pathname.startsWith("/post") ? getPostsBy(id) : pathname.startsWith("/post-detail") ? getPostsBy(id) : pathname.startsWith("/property-matched") ? getMatchedListingBy(id) : pathname.startsWith("/property-wanted") ? getPropertyWanted(id) : getPostsBy(id)
  // );
  const result = useQuery(["posts-id", id], () =>(pathname.startsWith("/property-matched") && isMatched) ? getPropertyWanted(id) : /^\/property-wanted\/[^/]+$/.test(location.pathname) ? getPropertyWanted(id):  getPostsBy(id)
);
  return result;
}
