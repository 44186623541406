import React from "react";
import AddressForm from "./AddressForm";

function UpdateAddress() {
  return (
    <>
      <h3 className="mb-4">Update Address</h3>
      <AddressForm />
    </>
  );
}

export default UpdateAddress;
