import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { useQuery } from "react-query";
import {
  getCommentsList,
  getLikesList,
  getMatchPropertyWantedListing,
  getPropertyWanted,
  getPropertyWantedTabData,
  getUserListing,
} from "../../../services/notificationApi";
import { Pagelimit } from "constants/data";

function useMyActivity( {type, currentPage} ) {

  const { auth } = useContext(AuthContext);
  const user_id = auth?.userId ? auth?.userId.toString() : "";
  return useQuery(
    [type, user_id, currentPage],
    () =>
      // type === "myLikes" ? getUserListing(1) : getCommentsList(user_id),
      type === "myLikes"
        ? getLikesList(user_id,currentPage,Pagelimit)
        : type === "myComments"
        ? getCommentsList(user_id,currentPage,Pagelimit)
        : type === "myPropertWanted"
        ? getPropertyWantedTabData(user_id,currentPage,Pagelimit)
        : type==="Chain" ? getUserListing(user_id,currentPage,Pagelimit) : type==="matchedPropertyWanted" ? getMatchPropertyWantedListing(user_id,currentPage,Pagelimit) : null,
    {
      enabled: !!type,
    }
  );
}

export default useMyActivity;
