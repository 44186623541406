import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../context/authContext";
import { getAfterUnderScoreValue, isNonEmptyArray } from "../../helpers";
import useUsersById from "../../hooks/query/AllUserProfile/useUserById";
import useUserPosts from "../../hooks/query/AllUserProfile/useUserPostById";
import CoverProfileDetails from "./components/CoverProfileDetails";
import Tabs from "../../components/tabs/Tabs";
import UserBio from "./components/UserDetailBio";
import UserPosts from "./components/UserPosts";
import Events from "pages/setting/details/events/Events";
import useConnectedUsers from "hooks/query/AllUserProfile/useAllConnectedUsersList";

const Profile = () => {
  const params = useParams();
  const id = getAfterUnderScoreValue(params);
  const { auth } = useContext(AuthContext);

  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(id);
  const { data: usersPost } = useUserPosts(id);
// console.log("usersPost", usersPost);
  const currentUserId = auth?.userId ? auth?.userId : null;
  const [isConnected, setIsConnected] = useState(false);
  const {
    isLoading: isConnectedUserListLoading,
    error: connectedUserListError,
    data: connectedUserListData,
  } = useConnectedUsers(currentUserId);
  const seeOtherUserProfile =
    connectedUserListData &&
    connectedUserListData?.some(
      (user) => Number(user?.users_members_id) === Number(id)
    );

  let tabs = [
    {
      title: "Bio",
      content: (
      
        <UserBio
          userDetailsData={userDetailsData}
          isUserDetailsLoading={isUserDetailsLoading}
          userDetailsError={userDetailsError}
          isBlur={
            Number(id) === Number(currentUserId)
              ? true
              : seeOtherUserProfile
              ? true
              : false
          }
        />
      ),
    },
    {
      title: "Listings",
      content: (
        <UserPosts usersPost={usersPost} userId={Number(auth?.userId)} />
      ),
    },
    {
      title: "Events",
      content: <Events usersPost={usersPost} userId={Number(auth?.userId)} />,
    },
  ];

  

  return (
    <div>
      <div className="box-shadow">
        <div className="profile">
          <div className="cover-profile">
            <CoverProfileDetails
              currentUserId={currentUserId ? currentUserId : null}
              userDetailsData={userDetailsData}
            />
          </div>
          <Tabs tabs={tabs} />

         
         
        </div>
      </div>
    </div>
  );
};

export default Profile;
