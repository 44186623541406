import React from "react";
import ActivityCard from "./ActivityCard";

const ActivityContainer = 
  ({ isLoading, data, activity, setActivity, isBlur, tabIndex }) => {
    return (
      <div className="notification">
        <ul>
          {isLoading ? (
            <div>Loading...</div>
          ) : !data?.length ? (
            <div>No data found</div>
          ) : (
            <>
              {data?.map((item) => (
                <ActivityCard
                  item={item}
                  key={item?.id + ""}
                  type={activity}
                  setActivity={setActivity}
                  isBlur={isBlur}
                  tabIndex={tabIndex}
                />
              ))}
            </>
          )}
        </ul>
      </div>
    );
  }

export default ActivityContainer;
