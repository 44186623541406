export const features = [
  {
    id: 1,
    value: "laundry room",
    label: "Laundry Room",
  },
  {
    id: 2,
    value: "storage",
    label: "Storage",
  },
  {
    id: 3,
    value: "air conditioning",
    label: "Air Conditioning",
  },
  {
    id: 4,
    value: "heating",

    label: "Heating",
  },
  {
    id: 5,
    value: "balcony",
    label: "Balcony",
  },
  {
    id: 6,
    value: "double glazing",
    label: "Double Glazing",
  },
  {
    id: 7,
    value: "garage",
    label: "Garage",
  },
  {
    id: 8,
    value: "patio",
    label: "Patio",
  },
  {
    id: 9,
    value: "smart home",
    label: "Smart Home",
  },
];

export const amenities = [
  {
    id: 1,
    value: "gym or fitness center",
    label: "Gym or Fitness Center",
  },
  {
    id: 2,
    value: "pool",
    label: "Pool",
  },
  {
    id: 3,
    value: "garden/roof terrace",
    label: "Garden/Roof Terrace",

  },
  {
    id: 4,
    value: "broadband",
    label: "Broadband",
  },
  {
    id: 5,
    value: "dishwasher",
    label: "Dishwasher",
  },
  {
    id: 6,
    value: "disabled access",
    label: "Disabled Access",
  },
 
];


export const Pagelimit=20 ;