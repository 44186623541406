import { Link, useNavigate } from "react-router-dom";
import ErrorImage from "../../assets/images/verified.png";
import { AuthContext } from "context/authContext";
import { paymentConfirmation } from "services/paymentConfirmation";
import { in200s } from "helpers";
import { useContext, useEffect } from "react";

const PaymentConfirmation = () => {
  const { auth } = useContext(AuthContext);
  // const navigate = useNavigate();
  // const confirmationHandler = async () => {
  //   const userId = auth?.userId ? auth?.userId.toString() : "";
  //   const response = await paymentConfirmation(userId);

  //   if (in200s(response.status)) {
  //     navigate("/", {
  //       replace: true,
  //     });
  //   } else {
  //     console.error(response);
  //   }
  // };

  useEffect(() => {
    const confirmationHandler = async () => {
      const userId = auth?.userId ? auth?.userId.toString() : "";
      await paymentConfirmation(userId);
    };
    confirmationHandler();
  }, []);
  return (
    <section className="page-error page--error">
      <div className="stars stars1" />
      <div className="stars stars2" />
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center ">
            <div className="position-relative">
              <h2 className="mb-4">Payment successful</h2>
              <img
                src={ErrorImage}
                alt="error"
                width="400"
                height="333"
                className="error-image mb-4"
              />
              {/* <h5 className="mt-4">
                The page you are trying to access doesn’t exist or has been
                moved.
              </h5> */}
              {/* <h5 className="mb-4">Try going back to our homepage.</h5> */}
              <div>
                <Link to={"/"} className="btn btn-common">
                  Thank you
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PaymentConfirmation;
