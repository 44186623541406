import { hideLoadingSpinner, showLoadingSpinner } from "components/messageModal/MessageModal";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteSingleIntegration, getMeIntegration } from "services/integrationApi";
import Swal from "sweetalert2";

const getIntegration = async (userId) => {
  const res = await getMeIntegration(userId);

 
  if (res) {
    return res;
  }
  return null;
};

export default function useGetIntegration(userId) {
  const result = useQuery(
    ["integrations", userId],
    () => getIntegration(userId),
    {
      enabled: !!userId,
    }
  );


  return result;
}


export function useDeleteIntegration (userId){
  const queryClient = useQueryClient();
  return useMutation(deleteSingleIntegration, {
    onMutate: () => showLoadingSpinner({}),
    onSuccess: async (res) => {
      await queryClient.invalidateQueries(["integrations", userId]);
      Swal.fire({
        title: "Delete Integration",
        text: res?.data?.message,
        icon: "success",
      });
    },
    onSettled: () => hideLoadingSpinner(),
    onError: (err, data) => {
      Swal.fire({
        title: "Delete Integration",
        text: err?.response?.data?.message,
        icon: "error",
      });
    },
  });

}