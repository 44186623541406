/* eslint-disable no-nested-ternary */
// import React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserImage from "../../assets/images/empty-box.png";
import { getInitials, scrollTop } from "../../helpers";
import usePostComments from "../../hooks/query/Posts/usePostComments";
import usePosts from "../../hooks/query/Posts/usePosts";
import "../../styles/globalStyles.css";
import {
  getUserFullName,
  getUserProfileImage,
  hasUserDetails,
} from "../../utils/Storage";
import Posts from "./components/Posts";
import { AuthContext } from "../../context/authContext";
import useUsersById from "../../hooks/query/AllUserProfile/useUserById";
import Swal from "sweetalert2";
import { showMessage } from "components/messageModal/MessageModal";
import useGetVerification from "hooks/query/AllUserProfile/useGetVerification";
import AddsModal from "components/AddsModal";
import PaginationComp from "components/pagination";

// import useAllUserProfile from "../../hooks/query/AllUserProfile/useAllUserProfile";

const Home = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);

  const {
    isLoading: postsIsLoading,
    // isRefetching,
    // isFetching,
    error: postsError,
    data: postsData,
  } = usePosts(currentPage);

  const {
    isLoading: isallCommentsLLoading,
    error: allCommentsError,
    data: allCommentsData,
  } = usePostComments();
  const { auth } = useContext(AuthContext);

  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(auth?.userId);
  const [postTitle, setPostTitle] = useState("");
  const userProfilePic = getUserProfileImage();
  const UserFullName = getUserFullName();
  const userProfileText = getInitials(UserFullName);
  const hasUserData = hasUserDetails();
  const navigate = useNavigate();
  const verificationBadge = useGetVerification();

  // const startIndex = (currentPage - 1) * postsPerPage;
  // const endIndex = startIndex + postsPerPage;
  // const currentData = postsData && postsData?.results?.slice(startIndex, endIndex);

  // const totalPages = Math.ceil(postsData?.length / postsPerPage);

  const nextPage = useCallback(() => {
    scrollTop();
    if (postsData?.next) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, postsData]);

  const previousPage = useCallback(() => {
    scrollTop();
    if (postsData?.previous) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, postsData]);

  // const nextPage = () => {
  //   scrollTop();
  //   if (postsData?.next) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // }

  // const previousPage = () => {
  //   scrollTop();
  //   if (postsData?.previous) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }

  return (
    <>
      <div>
        {/* {hasUserData ? <Progressbar /> : null} */}
        {Number(userDetailsData?.user_verification) === 1 && (
          <div className="post-message" hidden={!hasUserData}>
            <div>
              <h5>
                <strong>List your next property</strong>
              </h5>
            </div>
            <div className="post-something">
              <figure>
                {verificationBadge}
                <span className="text-uppercase" hidden={userProfilePic}>
                  {userProfileText}
                </span>
                <picture hidden={!userProfilePic}>
                  <source srcSet={userProfilePic} type="image/webp" />
                  <source srcSet={userProfilePic} type="image/png" />
                  <img
                    loading="lazy"
                    src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
                    data-src={userProfilePic}
                    alt="user-img"
                    className="img-fluid"
                    width={50}
                    height={50}
                  />
                </picture>
              </figure>
              <div>
                <input
                  type="text"
                  name="title"
                  placeholder="Create a new listing"
                  value={postTitle}
                  onChange={(e) => setPostTitle(e.target.value)}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      navigate("/create-post", {
                        state: {
                          title: event?.target?.value,
                        },
                      });
                    }
                  }}
                />
                <Link to="/create-post" state={{ title: postTitle }}>
                  <i className="fa-solid fa-pen" />
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* <div className="">
        {postsError
          ? "Something went wrong!"
          : postsIsLoading
          ? "loading"
          : postsData
          ? postsData.map((post) => <Posts post={post} key={post.id} />)
          : null}
      </div> */}

        <div
          className={
            postsError || (postsData && postsData?.results?.length === 0)
              ? "no-data-found"
              : ""
          }
        >
          {postsError ? (
            "Something went wrong!"
          ) : postsIsLoading ? (
            <div className="no-data-found">
              <div className="">
                <img
                  loading="lazy"
                  src={UserImage}
                  alt=""
                  width={200}
                  height={200}
                />
                <h6 className="mt-3">Loading...</h6>
              </div>
            </div>
          ) : Array.isArray(postsData?.results) ? (
            postsData?.results?.map((post) => (
              <Posts post={post} key={post.id} />
            ))
          ) : (
            <p>No posts available</p>
          )}
          {postsData?.results && postsData?.results !== 0 && (
            // <div className="pagination">
            //   {/* Custom pagination links */}
            //   <button
            //     onClick={previousPage}
            //     disabled={!postsData?.previous}
            //     className={`pagination_btn ${
            //       !postsData?.previous ? "disabled" : ""
            //     }`}
            //   >
            //     &lt; Prev
            //   </button>
            //   <span>Page {currentPage}</span>
            //   <button
            //     onClick={nextPage}
            //     disabled={!postsData?.next}
            //     className={`pagination_btn ${
            //       !postsData?.next ? "disabled" : ""
            //     }`}
            //   >
            //     Next &gt;
            //   </button>
            // </div>
            <PaginationComp
              currentPage={currentPage}
              data={postsData}
              previous={postsData?.previous}
              next={postsData?.next}
              scroll={scrollTop}
              setCurrentPage={setCurrentPage}
            />
          )}
        </div>

        {/* {hasUserData && ( */}
        {auth?.isAuthenticated && (
          <div className="bottom-right">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                // if (!auth?.isAuthenticated) {
                //   navigate("/login");
                // } else if (Number(userDetailsData?.user_verification) !== 1) {
                //   event.preventDefault();
                //   event.stopPropagation();
                //   Swal.fire({
                //     title: "Info",
                //     text: "Please verify your account by completing the verification process before creating any listings.",
                //     icon: "info",
                //   });
                // } else if (
                //   auth.isAuthenticated &&
                //   Number(userDetailsData?.user_verification) === 1
                // ) {
                setModal(!modal);
                // }
              }}
            >
              <i className="fa-sharp fa-solid fa-plus" />
            </span>
          </div>
        )}

        {/* )} */}
      </div>
      {modal && <AddsModal modal={modal} setModal={setModal} />}
    </>
  );
};

export default Home;
