// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show {
	display: flex;
	flex-wrap: wrap;
	margin: 20px auto;
	padding: 20px;
}

.show .showImage {
	height: 200px;
	width: 200px;
	margin-right: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/showImages/showImagesStyles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,eAAe;CACf,iBAAiB;CACjB,aAAa;AACd;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,kBAAkB;AACnB","sourcesContent":[".show {\n\tdisplay: flex;\n\tflex-wrap: wrap;\n\tmargin: 20px auto;\n\tpadding: 20px;\n}\n\n.show .showImage {\n\theight: 200px;\n\twidth: 200px;\n\tmargin-right: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
