import { useContext, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Select from "react-select";
import ProfileBannerImage from "../../assets/images/profile-banner.jpg";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "../../components/messageModal/MessageModal";
import { AuthContext } from "../../context/authContext";
import {
  getInitials,
  isNonEmptyString,
  removeWhitespaces,
} from "../../helpers";
import useSelectedTypesBy from "../../hooks/query/AllUserProfile/useSelectedUserTypes";
import useUsersById from "../../hooks/query/AllUserProfile/useUserById";
import { authService } from "../../services/AuthApi";
import {
  getUserBio,
  getUserFullName,
  getUserProfileImage,
} from "../../utils/Storage";
import DetailsForm from "./components/DetailsForm";
import VerifiedIcon from "assets/svg/verificationTick.png";
import useUserPersonaById from "hooks/query/AllUserProfile/useUserPersonaById";
import SettingParent from "./SettingParent";

const Setting = () => {
  const userProfilePic = getUserProfileImage();
  const userBIO = getUserBio();
  const userFullName = getUserFullName();
  const userProfileText = getInitials(userFullName);
  const value = useContext(AuthContext);

  const userId = value?.auth?.userId ? value?.auth?.userId.toString() : "";
  const userProfileUrl = userFullName ? userFullName.concat("_", userId) : "";
  const navigate = useNavigate();
  const logOut = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      showLoadingSpinner({});
      await authService.logout(userId);
    } catch (error) {
    } finally {
      await value?.logout();
      navigate("/login");
      hideLoadingSpinner();
    }
  };

  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(value?.auth?.userId);
  const { data: userSelectedTypesData } = useSelectedTypesBy(
    value?.auth?.userId
  );

  const {
    isLoading: isUserPersonaLoading,
    error: userPersonaError,
    data: userPersonaData,
  } = useUserPersonaById(value?.auth?.userId);

  // useEffect(() => {
  //   if (userDetailsData && !isUserDetailsLoading) {
  //     save("userDetails", userDetailsData);
  //   }
  // }, [userDetailsData, isUserDetailsLoading]);

  const [isModalOpen, setIsModal] = useState(false);
  // console.log({
  //   userDetailsError,
  //   userDetailsData,
  //   isUserDetailsLoading,
  //   userSelectedTypesData,
  // });

  // console.log("userPersonaData", userPersonaData);

  return (
    <SettingParent>
      <DetailsForm
        preloadedValues={userDetailsData}
        userSelectedTypesData={userSelectedTypesData}
        userPersonaData={userPersonaData}
        isUserPersonaLoading={isUserPersonaLoading}
      />
    </SettingParent>
  );
};

export default Setting;
