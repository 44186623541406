import React from "react";
import checkMark from "../../assets/images/check-mark.png";
import rocket from "../../assets/images/rocket.png";
const PricingCardMonthly = () => {
  return (
    
    <div className="pricing-card">
      <button className="btn btn-runner">
        <a className="nav-link" href="https://buy.stripe.com/28o174g9n2Dt6yc145">
          Great Price        
        </a>
      </button>
      <div className="price">
        <h2>
          <a className="nav-link" href="https://buy.stripe.com/28o174g9n2Dt6yc145">
            £235        
          </a>
        </h2>
        <p>monthly</p>
      </div>
      <ul>
        <li>
          <img src={checkMark} alt="checkMark" className="card-icon" />
          <span>1 branch</span>
        </li>
        <li>
          <img src={checkMark} alt="checkMark" className="card-icon" />
          <span>Unlimited users</span>
        </li>
        <li>
          <img src={checkMark} alt="checkMark" className="card-icon" />
          <span>Unlimited property Ads</span>
        </li>
        <li>
          <img src={checkMark} alt="checkMark" className="card-icon" />
          <span>Unlimited leads</span>
        </li>
        <li>
          <img src={checkMark} alt="checkMark" className="card-icon" />
          <span>Unlimited Email Campaigns</span>
        </li>
        <li>
          <img src={checkMark} alt="checkMark" className="card-icon" />
          <span>all features</span>
        </li>
      </ul>

      <button className="btn subscribe-btn">
        <a className="nav-link" href="https://buy.stripe.com/28o174g9n2Dt6yc145">
          Claim Your Leads!
        </a>
        <span className="last">Quality, Tailord</span>
      </button>
      <div className="picing-card-footer">
        <img src={rocket} alt="rocket" className="card-icon" />
        <a className="nav-link" href="https://buy.stripe.com/28o174g9n2Dt6yc145">
          No contract. Cancel Anytime.
        </a>
      </div>
    </div>
  );
};

export default PricingCardMonthly;
