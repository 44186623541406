import React, { useContext, useEffect } from "react";
import "./index.css";
import { RxCross2 } from "react-icons/rx";

import adsPhoto from "../../assets/images/ads.png";
import frameHouse from "../../assets/images/frame-house.png";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "context/authContext";
import useUsersById from "../../hooks/query/AllUserProfile/useUserById";

import Swal from "sweetalert2";
const AddsModal = ({ modal, setModal }) => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext);
  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(auth?.userId);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="box-modal">
      <div className="box">
        <div className="box-header">
          <span className="close-btn" onClick={() => setModal(!modal)}>
            <RxCross2 />
          </span>
        </div>

        <div className="modal-body">
          <h3>Please select the type of Ad</h3>
          <div className="card-container">
            <div className="ad-card">
              <img src={adsPhoto} alt="ads-photo" />
              <div className="ad-card-content">
                <h5>Offer property / room</h5>
                <p>A free service for agents and landlords</p>
              </div>
              <button
                onClick={(event) => {
                  if (!auth?.isAuthenticated) {
                    navigate("/login");
                  } else if (Number(userDetailsData?.user_verification) !== 1) {
                    event.preventDefault();
                    event.stopPropagation();
                    Swal.fire({
                      title: "Info",
                      text: "Please verify your account by completing the verification process before creating any listings.",
                      icon: "info",
                    });
                  } else if (
                    auth.isAuthenticated &&
                    Number(userDetailsData?.user_verification) === 1
                  ) {
                    navigate("/create-post");
                  }
                }}
                className="ad-card-btn"
              >
                Continue
              </button>
            </div>
            <div className="ad-card">
              <img src={frameHouse} alt="ads-photo" />
              <div className="ad-card-content">
                <h5>Want property / room</h5>
                <p>A free service for renters and tenants</p>
              </div>
              <Link
                // to={`${process.env.REACT_APP_URL}property-wanted-listing`}
                to={`/property-wanted-listing`}
                // target="_blank"
                className="ad-card-btn"
              >
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddsModal;
