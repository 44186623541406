import { Pagelimit } from "constants/data";
import { in200s } from "../helpers";
import { API } from "./ApiClient";

export const createAddress = (data) => API.post("address", data);

export function getTotalUserList() {
  return API.get(`users`)
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getCommentsList(user_id, currentPage, Pagelimit) {
  return API.get(`/comments/${user_id}?page=${currentPage}&limit=${Pagelimit}`)
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getLikesList(user_id, currentPage, Pagelimit) {
  return API.get(
    `/likes/tab/posts/${user_id}?page=${currentPage}&limit=${Pagelimit}`
  )
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getNotificationList() {
  return API.get(`/notifications`)
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getNotificationById({ userId, currentPage }) {
  // return API.get(`notifications/${userId}`)
  return API.get(
    `notifications/${userId}?page=${currentPage}&limit=${Pagelimit}`
  )
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getGeneralNotificationById({ userId, currentPage }) {
  return API.get(
    `general-notifications/?page=${currentPage}&limit=${Pagelimit}`
  )
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}
export function getPropertyWantedTabData(userId, currentPage, Pagelimit) {
  return API.get(
    `property-wanted/tab/${userId}?page=${currentPage}&limit=${Pagelimit}`
  )
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}
export function getPropertyWanted(userId) {
  return API.get(`property-wanted/${userId}`)
    .then((response) => {
      if (in200s(response.status)) {
        // console.log(response.data);
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getUserListing(userId, page, limit) {
  return API.get(`matched/property/${userId}?page=${page}&limit=${limit}`)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}
export function getMatchPropertyWantedListing(userId, page, limit) {
  return API.get(
    `matched/property-wanted/${userId}?page=${page}&limit=${limit}`
  )
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function manageMatchedListing({ user_id, data }) {
  return API.put(`matched/${user_id}`, data)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function createMatchMembers(data) {
  return API.post(`users-members`, data)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}
export function checkUserAccountType(user_id) {
  return API.get(`user-account-types/${user_id} `)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}


export function getTotalPropertyMatchesById(userId) {
  return API.get(`matched/property-total-matches/${userId}`)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

export function getTotalPropertyWantedMatchesById(userId) {
  return API.get(`matched/property-wanted-total-matches/${userId}`)
    .then((response) => {
      if (in200s(response.status)) {
        return response.data?.result;
      }

      return null;
    })
    .catch((error) => error.response);
}

