import {
  formatDate,
  getInitials,
  isNonEmptyString,
  parseStringArray,
  removeWhitespaces,
} from "helpers";
import PostStatus from "pages/profile/components/postStatus/PostStatus";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import parse from "html-react-parser";
import Comments from "pages/home/components/Comments";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { API } from "services/ApiClient";
import useGetVerification from "hooks/query/AllUserProfile/useGetVerification";
import { PostDetailContext } from "./PostDetailContext";
import usePostDetails from "./usePostDetails";
import PostDetailPostWanted from "./PostDetailPostWanted";
import Draggable from "react-draggable";

const PostDetail = ({ item, isMatched, idx }) => {
  const { isGroupMember } = useContext(PostDetailContext);
  const navgiate = useNavigate();

  const url = useLocation();
  const { postsDetailsData, navigate, isOpenForBuy, postId, auth } =
    usePostDetails(isMatched);
  const queryClient = useQueryClient();
  const params = useParams();
  const { mutate: likePost, isLoading: isLikePostLoading } = useMutation(
    async (payload) => {
      // if (payload.newIsLiked)
      return API.post("post-likes", payload);
    },
    {
      onSuccess: async (data) => {
        if (data) {
          await queryClient.invalidateQueries(["posts-id", postId]);
          queryClient.invalidateQueries(["posts"]);
          queryClient.invalidateQueries(["post-likes"]);
        }
      },
    }
  );

  const verificationBadge = useGetVerification(
    postsDetailsData?.[0]?.user_verification == "1"
  );

  const postVerificationBadge = useGetVerification(
    postsDetailsData?.[0]?.post_verification == "1",
    {
      // top: -10,
      // right: -10,
      // height: 30,
      // width: 30,
    }
  );

  const { mutate: disLikePost } = useMutation(
    async (payload) => {
      return API.delete(`post-likes/${payload?.post_id}`, {
        data: payload,
      });
    },
    {
      onSuccess: async (data) => {
        if (data) {
          await queryClient.invalidateQueries(["posts-id", postId]);
          queryClient.invalidateQueries(["posts"]);
          queryClient.invalidateQueries(["post-likes"]);
        }
      },
    }
  );

  const { data } = useQuery(["post-likes"], () =>
    API.get(`post-likes`).then((res) => {
      return res.data.result;
    })
  );

  useEffect(() => {
    const userLiked =
      data &&
      data.some(
        (like) =>
          like.user_id === Number(auth?.userId) &&
          like.post_id === Number(postId)
      );

    setIsLiked(userLiked);
  }, [data, auth.userId, postId]);

  const [isLiked, setIsLiked] = useState(false);
  const handleLike = () => {
    if (!auth?.userId) {
      return navigate("/login");
    }
    const newIsLiked = !isLiked;
    setIsLiked(newIsLiked);
    const payloadData = {
      user_who_triggered_notify_id: auth?.userId,
      post_id: postId,
      likes: newIsLiked ? 1 : 0,
      user_id: auth?.userId,
      opened: 0,
      user_to_notify_id: postsDetailsData?.[0]?.user_id,
    };
    if (newIsLiked) {
      likePost(payloadData);
    } else if (newIsLiked === false) {
      disLikePost(payloadData);
    }
  };
  // const [commentOpen, setCommentOpen] = useState(true);
  // const id = getIdValue(params);
  // const {
  //   isLoading: postsDetailsLoading,
  //   error: postsDetailsError,
  //   data: postsDetailsData,
  // } = usePostsById(id);

  const FullName =
    isNonEmptyString(postsDetailsData?.[0]?.forename) &&
    isNonEmptyString(postsDetailsData?.[0]?.surname)
      ? `${postsDetailsData?.[0]?.forename}  ${postsDetailsData?.[0]?.surname}`
      : "";
  const userProfileUrl = FullName.concat("_", postsDetailsData?.[0]?.user_id);
  return (
    <main id="layoutSidenav_content" key={item?.id}>
      {/* <div className="">
      <div>
        <div className="progressbar mb-3">
          <div className="d-flex justify-content-between">
            <h5>Payment Process</h5> <span>4 Steps to Complete</span>
          </div>
          <div className="progress-flex">
            <div className="arrow-steps clearfix">
              <div className="step current">
                {" "}
                <span>
                  <a href="#">Document Uploaded</a>
                </span>{" "}
              </div>
              <div className="step current">
                {" "}
                <span>
                  <a href="#">Initial Payment Made</a>
                </span>{" "}
              </div>
              <div className="step current">
                {" "}
                <span>
                  {" "}
                  <a href="#">Final Payment</a>
                </span>{" "}
              </div>
              <div className="step ">
                {" "}
                <span>Complete</span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}

      <div key={idx} className="post position-relative">
        <ul>
          <li>
            <div className="post-section post-section-inner-page">
              <div className="user-post">
                <Link
                  to={`/profile/${removeWhitespaces(userProfileUrl)}`}
                  className="post-profile"
                >
                  <figure>
                    {verificationBadge}

                    {isNonEmptyString(item?.profile_image) ? (
                      <picture>
                        <source
                          srcSet={item?.profile_image}
                          type="image/webp"
                        />
                        <source srcSet={item?.profile_image} type="image/png" />
                        <img
                          loading="lazy"
                          src={item?.profile_image}
                          data-src={item?.profile_image}
                          alt="user-img"
                          className="img-fluid"
                          width={50}
                          height={50}
                        />
                      </picture>
                    ) : (
                      <span>
                        {isNonEmptyString(item?.forename) &&
                        isNonEmptyString(item?.surname)
                          ? getInitials(`${item?.forename}  ${item?.surname}`)
                          : ""}
                      </span>
                    )}
                  </figure>
                  <figcaption>
                    <h5 className="mb-0">
                      {isNonEmptyString(item?.forename) &&
                      isNonEmptyString(item?.surname)
                        ? `${item?.forename}  ${item?.surname}`
                        : ""}
                    </h5>
                    <span>
                      {isNonEmptyString(item?.created)
                        ? formatDate(item?.created)
                        : ""}
                    </span>
                  </figcaption>
                </Link>
                {/* <div className="price-btn">
                <button
                  type="button"
                  className="btn btn-outline-success"
                  onClick={() =>
                    navigate(
                      `/post-detail/${removeQuestionAndForwardSlash(
                        params?.title
                      )}`
                    )
                  }
                  disabled={isOpenForBuy}
                >
                  {item?.price ? `${item.price}` : ""}
                </button>
              </div> */}

                <PostStatus item={item} />
              </div>
              <div className="post-image">
                {postVerificationBadge}
                <div className="post-title">
                  <h2>{item?.title}</h2>
                </div>
                {item?.images !== null ? (
                  <OwlCarousel
                    items={1}
                    margin={2}
                    autoplay={true}
                    autoplayTimeout={5000}
                    className="owl-carousel owl-theme post-slider"
                    dots={true}
                    nav={true}
                    loop
                    // items={1}
                    // margin={8}
                    // autoplay
                    // className="owl-carousel owl-theme post-slider"
                    // loop
                  >
                    {parseStringArray(item.images ?? "")?.map(
                      (imgItem, idx) => (
                        <div key={idx} className="item">
                          <picture>
                            <source srcSet={imgItem} type="image/webp" />
                            <source srcSet={imgItem} type="image/png" />
                            <img
                              loading="lazy"
                              srcSet={imgItem}
                              alt="post"
                              className="post-img"
                              // width="670"
                              // height="440"
                            />
                          </picture>
                        </div>
                      )
                    )}
                  </OwlCarousel>
                ) : (
                  ""
                )}
                <div className="inner-page-content">
                  {parse(item?.description)}
                </div>
              </div>
              <div className="like-comment-count">
                <button type="button" onClick={() => handleLike()}>
                  <span className={`like-count ${isLiked ? "unlike" : ""}`}>
                    {/* <a href="/" className="like-button"> */}
                    <i className="fa-solid fa-thumbs-up" /> {/* </a> */}
                    {/* <span>36k Likes</span> */}
                    <span>
                      {item?.total_likes == null ? 0 : item?.total_likes}{" "}
                      {isLiked ? "Unlike" : "Like"}
                    </span>
                  </span>
                </button>
                <button
                  type="button"
                  // onClick={() => setCommentOpen(!commentOpen)}
                >
                  <span className="comment-count">
                    <i className="fa fa-message" />

                    {item?.total_comments == null ? (
                      "Be First to Comment"
                    ) : (
                      <span>{item?.total_comments} Comments</span>
                    )}
                  </span>
                </button>
              </div>
            </div>
            {isOpenForBuy && isGroupMember && (
              <Comments post={item} postId={postId} />
            )}

            <div className="fixed-pos">
              <Draggable
                axis="both"
                bounds={"parent"}
              >
                <div className="sticky-contact">
                  <Link
                    to={`/profile/${removeWhitespaces(userProfileUrl)}`}
                    className="post-profile"
                  >
                    <figure style={{ marginRight: 0 }}>
                      {verificationBadge}

                      {isNonEmptyString(item?.profile_image) ? (
                        <picture>
                          <source
                            srcSet={item?.profile_image}
                            type="image/webp"
                          />
                          <source
                            srcSet={item?.profile_image}
                            type="image/png"
                          />
                          <img
                            loading="lazy"
                            src={item?.profile_image}
                            data-src={item?.profile_image}
                            alt="user-img"
                            className="img-fluid"
                            width={50}
                            height={50}
                          />
                        </picture>
                      ) : (
                        <span>
                          {isNonEmptyString(item?.forename) &&
                          isNonEmptyString(item?.surname)
                            ? getInitials(`${item?.forename}  ${item?.surname}`)
                            : ""}
                        </span>
                      )}
                    </figure>
                  </Link>
                  <Link
                    to={`/profile/${removeWhitespaces(userProfileUrl)}`}
                    className="post-profile"
                  >
                    <figcaption>
                      <h5 className="mb-0">
                        {isNonEmptyString(item?.forename) &&
                        isNonEmptyString(item?.surname)
                          ? `${item?.forename}  ${item?.surname}`
                          : ""}
                      </h5>
                    </figcaption>
                  </Link>

                  <div className="d-flex flex-column gap-1 w-100">
                    {/* <Link className="btn btn-common" to={`tel:${item.mobil}`} target="_blank">
                {item.mobile}
              </Link> */}
                    <Link
                      className="btn btn-common"
                      to={`tel:${item.mobil}`}
                      target="_blank"
                    >
                      call
                    </Link>
                    <button
                      className="btn btn-common"
                      onClick={() =>
                        navigate(
                          `/profile/${item?.forename}${item?.surname}_${item.user_id}`
                        )
                      }
                    >
                      Chat
                    </button>
                  </div>
                </div>
              </Draggable>
            </div>
          </li>
        </ul>
      </div>

      {isMatched && (
        <>
          <PostDetailPostWanted isMatched={isMatched} />
        </>
      )}
    </main>
  );
};

export default PostDetail;
