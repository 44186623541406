/* eslint-disable no-unused-vars */
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useCallback, useContext, useEffect, useState } from "react";
// import parse from "html-react-parser";
import { Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import { RxCross2 } from "react-icons/rx";

import { Dropdown } from "primereact/dropdown";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { postsService } from "../../services/ImageUploadApi";
import { getUserFullName, getUserProfileImage } from "../../utils/Storage";
import {
  formatDateMMDDYYYY,
  getInitials,
  isNonEmptyString,
  parseStringArray,
} from "../../helpers";
import Dropzone from "../../components/dropzone/Dropzone";
import ShowImage from "../../components/showImages/ShowImages";
import TagInputField from "../../components/tagInputField/TagInputField";
import { API } from "../../services/ApiClient";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { updatePost, updateWantedProperty } from "../../services/PostApi";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "../../components/messageModal/MessageModal";
import Avatar from "../../components/image/Avatar";
import AddressAutoComplete from "../setting/details/updateAddress/AddressAutoComplete";
import CustomCropper from "../../components/customCropper/CustomCropper";
import {
  getPostImageByUserId,
  uploadPostImage,
} from "../../services/fileUploadApi";
import useGetVerification from "hooks/query/AllUserProfile/useGetVerification";
import useGetInventory from "hooks/query/commonData/useGetInventory";
import useGetCurrency from "hooks/query/commonData/useGetCurrency";
import useGetBedrooms from "hooks/query/commonData/useGetBedroom";
import LoadingSpinner from "components/messageModal/LoadingSpinner";
import imageCompression from "browser-image-compression";
import MailboxModal from "components/mailboxModal";
import { amenities as Amenities, features as Features } from "constants/data";
import useUsersById from "hooks/query/AllUserProfile/useSelectedUserTypes";
import useUserPersonaById from "hooks/query/AllUserProfile/useUserPersonaById";
import moment from "moment";
import { max } from "ramda";

const CLOUDINARY_UPLOAD_PRESET = "social_locket";
const CLOUDINARY_UPLOAD_URL =
  "https://api.cloudinary.com/v1_1/dzs0eyrnl/image/upload";
const schema = yup.object().shape({
  // title: yup.string().required(),
  title: yup
    .string()

    .required("Title is required"),
  type: yup.string().nullable().required("Please select type"),
  // pages: yup.string().required(),
  bedrooms: yup
    .mixed()
    .test(
      "positiveOrNaN",
      "nnumber of bedrooms must be greater than zero",
      (value) => {
        return Number(value) > 0 || Number.isNaN(value);
      }
    )
    .required("nnumber of bedrooms must be greater than zero"),

  payment_style_type: yup.string().required("payment_style_type is required"),
  number_of_children: yup
    .number()
    .typeError("number_of_children must be a number")
    .required("number_of_children is required")

    .moreThan(-1, "number_of_children must be positive"),
  bathrooms: yup
    .number()
    .typeError("number_of_children must be a number")
    .required("number_of_children is required")

    .moreThan(-1, "number_of_children must be positive"),
  reception_rooms: yup
    .number()
    .typeError("number_of_children must be a number")
    .required("number_of_children is required")

    .moreThan(-1, "number_of_children must be positive"),
  min_available_date: yup
    .date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .required("Minimum available date is required")
    .typeError("Minimum available date must be a valid date"),
  max_available_date: yup
    .date()
    .nullable()
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .min(
      yup.ref("min_available_date"),
      "Maximum available date must be after or equal to minimum available date"
    )
    .required("Maximum available date is required")
    .typeError("Maximum available date must be a valid date"),
  min_price: yup
    .number()
    .typeError("price must be a number")
    .required("min-price is required")
    .positive("please enter the valid number")
    .moreThan(0, "min-price must be positive"),
  max_price: yup
    .number()
    .typeError("price must be a number")
    .required("max-price is required")
    .positive("please enter the valid number")
    .moreThan(0, "max-price must be positive")
    .moreThan(yup.ref("min_price"), "Max price must be greater than min price"),
  nationality: yup.string().required("Nationality is required"),
  occupation: yup.string().required("occupation is required"),
  smoker: yup.string().required("smoker is required"),
  pets: yup.string().required("pets is required"),
  sell_option: yup.string().required("sell_option is required"),
  buy_option: yup.string().required("buy_option is required"),
  rent_option: yup.string().required("rent_option is required"),
  vegetarian: yup.string().required("vegetarian is required"),
  pescatarian: yup.string().required("pescatarian is required"),
  amenities: yup
    .array()
    .min(1, "Select at least 1 amenitie")
    .required("amenitie is required")
    .typeError("Amenities must be an array"),
  features: yup
    .array()
    .min(1, "Select at least 1 amenitie")
    .required("amenitie is required")
    .typeError("Amenities must be an array"),

  furnished: yup.string().required("furnished is required"),
});
const PropertyWantedListing = () => {
  const queryClient = useQueryClient();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const getInventory = useGetInventory();
  const getCurrency = useGetCurrency();
  const getBedroom = useGetBedrooms();
  const [selectedCity, setSelectedCity] = useState(null);

  const [images, setImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const userProfilePic = getUserProfileImage();
  const userFullName = getUserFullName();
  const { state } = useLocation();
  // console.log("state", state);

  const [tags, setTags] = useState([]);
  const [features, setFeatures] = useState([]);
  const [amenities, setAmenities] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("$");
  const [miniPirce, setMiniPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [saleOption, setSaleOption] = useState(null);

  const [vegetarian, setVegetarian] = useState(null);

  const [address, setAddress] = useState(null);
  const preloadedValues = {
    title: state?.title,
  };
  const {
    isLoading: isUserDetailsLoading,
    error: userDetailsError,
    data: userDetailsData,
  } = useUsersById(auth?.userId);
  const {
    isLoading: isUsrPersoanLoading,
    error: userPersonaError,
    data: userPersonaData,
  } = useUserPersonaById(auth?.userId);

  const [showModal, setShowModal] = useState(false);
  const postUpdateValue = {
    description: state?.post?.description,
    title: state?.title,

    bedrooms: state?.post?.bedrooms,
    location: state?.post?.location,
    type: state?.post?.type ? state?.post?.type?.toLowerCase() : "",
    bathrooms: state?.post?.bathrooms ?? "",
    reception_rooms: state?.post?.reception_rooms ?? "",
    min_available_date: state?.post?.min_available_date
      ? moment(state?.post?.min_available_date).format("YYYY-MM-DD")
      : "",
    max_available_date:
      moment(state?.post?.max_available_date).format("YYYY-MM-DD") ?? "",
    features: parseStringArray(state?.post?.features) ?? [],
    amenities: parseStringArray(state?.post?.amenities) ?? [],
    min_price: state?.post?.min_price?.split(" ")[1] || "",
    max_price: state?.post?.max_price?.split(" ")[1] || "",
    sell_option: state?.post?.for_sale ? "1" : "0",
    furnished: state?.post?.furnished ?? "",
    payment_style_type: state?.post?.payment_style_type ?? "",
  };

  useEffect(() => {
    if (!auth?.isAuthenticated) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (state?.post) {
      const post = state?.post;
      setImages(parseStringArray(post.images ?? "") ?? []);
      setTags(parseStringArray(post?.keywords) ?? []);

      setAddress({
        latitude: Number(post?.latitude || 0),
        longitude: Number(post?.longitude || 0),
      });
    }
  }, []);

  useEffect(() => {
    if (state?.post) {
      const post = state?.post;
      const currencySymbol = post?.min_price.split(" ")[0];

      const tempCurrency = getCurrency?.data?.find(
        (item) => item?.currency_symbol === currencySymbol
      );

      setSelectedCurrency(tempCurrency?.currency_symbol ?? "$");
    }
  }, []);

  useEffect(() => {
    if (state?.post?.min_price) {
      setMiniPrice(state?.post?.min_price?.split(" ")[1]);
    }
    if (state?.post?.max_price) {
      setMaxPrice(state?.post?.max_price?.split(" ")[1] || "");
    }
  }, []);
  useEffect(() => {
    if (state?.post?.for_sale === "1") {
      setSaleOption(true);
      setValue("sell_option", 1);
      setValue("buy_option", 1);
      setValue("rent_option", 0);
    } else if (state?.post?.to_rent === "1") {
      setSaleOption(false);
      setValue("sell_option", 0);
      setValue("buy_option", 0);
      setValue("rent_option", 1);
    }
  }, []);
  useEffect(() => {
    register("description");
  });

  useEffect(() => {
    if (userPersonaData) {
      setValue("number_of_children", userPersonaData?.number_of_children);
      setValue("nationality", userPersonaData?.nationality);
      setValue("occupation", userPersonaData?.occupation);
      setValue("smoker", userPersonaData?.smoker);
      setValue("pets", userPersonaData?.pets);
      setValue("vegetarian", userPersonaData?.vegetarian);
      setValue("pescatarian", userPersonaData?.pescatarian);
    }
  }, [userPersonaData]);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: state?.post ? postUpdateValue : preloadedValues,
    resolver: yupResolver(schema),
  });

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", "post_image");
    formData.append("user_id", auth?.userId);
    console.log("formData", formData);

    // formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    // formData.append("folder", "my_folder");

    try {
      const result = await uploadPostImage(formData);
      console.log("result", result);
      const response = await getPostImageByUserId(auth?.userId);
      console.log("response", response);
      let url = response?.[response?.length - 1]?.images;
      console.log("url", url);
      return url;
      // return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary: ", error);
      throw new Error("Error uploading to Cloudinary");
    }
  };

  const { mutateAsync, isLoading } = useMutation(uploadToCloudinary);
  // const onDrop = async (acceptedFiles) => {
  //   setUploadStatus("uploading");

  //   try {
  //     showLoadingSpinner({
  //       loadingText: "Uploading",
  //     });
  //     const uploadedImages = await Promise.all(
  //       acceptedFiles?.map((file) => mutateAsync(file))
  //     );
  //     setImages([...images, ...uploadedImages]);
  //     setUploadStatus("success");
  //   } catch (error) {
  //     console.error("Error uploading images: ", error);
  //     setUploadStatus("error");
  //   } finally {
  //     hideLoadingSpinner({});
  //   }
  // };

  const compressImage = async (file) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1, // Set your desired maximum file size
      });
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image: ", error);
      throw new Error("Error compressing image");
    }
  };

  const onDrop = async (acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    console.log("acceptedFiles", acceptedFiles[0].size);
    setUploadStatus("uploading");

    try {
      showLoadingSpinner({
        loadingText: "Uploading",
      });
      const compressedFiles = await Promise.all(
        acceptedFiles.map((file) => compressImage(file))
      );
      console.log("compressedFiles", compressedFiles);
      console.log("compressedFiles", compressedFiles[0].size);
      const uploadedImages = await Promise.all(
        compressedFiles.map((file) => mutateAsync(file))
      );

      setImages([...images, ...uploadedImages]);
      setUploadStatus("success");
    } catch (error) {
      console.error("Error uploading images: ", error);
      setUploadStatus("error");
    } finally {
      hideLoadingSpinner({});
    }
  };
  // const { mutate: savePost, isLoading: isPostLoading } = useMutation(
  //   async (payload) => {
  //     const res = await API.post("/posts", payload);

  //     if (isNonEmptyString(res?.data?.message)) {
  //       Swal.fire({
  //         title: "Success",
  //         text: res?.data?.message,
  //         icon: "success",
  //         confirmButtonText: "Ok",
  //       }).then((result) => {
  //         if (result.isConfirmed) {
  //           navigate("/");
  //         }
  //       });
  //     }
  //     return null;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       if (data) {
  //         queryClient.invalidateQueries(["posts"]);
  //       }
  //     },
  //   }
  // );

  function convertToISO(dateString) {
    // Parse the date string
    let date = new Date(dateString);

    // Adjust to UTC
    let utcDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    // Format to ISO 8601 string
    let isoString = utcDate.toISOString();

    return isoString;
  }
  function convertTimestampToISO(timestamp) {
    // Create a Date object from the timestamp
    let date = new Date(timestamp);

    // Convert the Date object to an ISO 8601 string
    let isoString = date.toISOString();

    return isoString;
  }

  // let isoDate = convertToISO(originalDate);
  const { mutate: savePropertyWanted, isLoading: isPropertyWantedLoading } =
    useMutation(async (payload) => {
      const res = await API.post("/property-wanted", payload);
      return res.data;
    });

  const { mutate: saveUserPersona, isLoading: isUserPersonaLoading } =
    useMutation(async (payload) => {
      const res = await API.put("/user-personas/:id", payload);
      return res.data;
    });
  const { mutate: updateMutate } = useMutation(updateWantedProperty, {
    onMutate: () => showLoadingSpinner({}),
    onSuccess: async (res) => {
      Swal.fire({
        title: "Success",
        text: res?.data?.message,
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
    },
    onError: (err, data) => {
      Swal.fire({
        title: "Update Listing",
        text: err?.response?.data?.message,
        icon: "error",
      });
    },
    onSettled: () => hideLoadingSpinner(),
  });

  const onSubmit = async (data) => {
    const tagsString = tags?.length ? JSON.stringify(tags) : "";

    let obj = {
      title: data?.title ?? "",
      description: data?.description ?? "",
      type: data?.type ?? "",
      location: "",
      latitude: address?.latitude ? String(address.latitude) : "",
      longitude: address?.longitude ? String(address.longitude) : "",
      post_verification: null,
      created: convertTimestampToISO(Date.now()),
      payment_style_type: data?.payment_style_type ?? "",
      min_available_date: data?.min_available_date
        ? convertToISO(data.min_available_date)
        : "",
      max_available_date: data?.max_available_date
        ? convertToISO(data.max_available_date)
        : "",

      min_price: data?.min_price
        ?`${selectedCurrency} ${Number(data.min_price)}`
        : "",

      max_price: data?.max_price
        ? `${selectedCurrency} ${Number(data.max_price)}`
        : "",

      user_id: auth?.userId ? Number(auth.userId.toString()) : "",

      bedrooms: data?.bedrooms ? data.bedrooms.toString() : "",
      bathrooms: data?.bathrooms ? data.bathrooms.toString() : "",
      reception_rooms: data?.reception_rooms
        ? data.reception_rooms.toString()
        : "",
      for_sale: data?.buy_option ? data.buy_option.toString() : "",
      to_rent: data?.rent_option ? data.rent_option.toString() : "",
      features: data?.features?.length ? JSON.stringify(data?.features) : "",
      amenities: data?.amenities?.length ? JSON.stringify(data?.amenities) : "",
      images: images ? JSON.stringify(images) : JSON.stringify([]),
      keywords: tagsString?.length ? JSON.stringify(tagsString) : "",
      offer_price: "",
      furnished: data.furnished ? data?.furnished : "",
      currency: selectedCurrency ?? "",
    };

    const obj2 = {
      user_id: auth?.userId ? auth.userId.toString() : "",
      id: auth?.userId ? auth.userId.toString() : "",
      number_of_children: data?.number_of_children ?? "",
      nationality: data?.nationality ?? "",
      occupation: data?.occupation ?? "",
      smoker: data?.smoker ?? "",
      pets: data?.pets ?? "",
      vegetarian: data?.vegetarian ?? "",
      pescatarian: data?.pescatarian ?? "",
    };
    console.log("obj", obj, "obj2", obj2);

    if (!auth.isAuthenticated) {
      navigate("/login");
    }
    if (state?.post) {
      obj = {
        ...obj,
        id: state?.post?.id,
      };
      updateMutate(obj);
    } else {
      savePropertyWanted(obj, {
        onSuccess: (propertyResponse) => {
          // Swal.fire({
          //   title: "Success",
          //   text: "Data submitted successfully!",
          //   icon: "success",
          //   confirmButtonText: "Ok",
          // }).then((result) => {
          //   if (result.isConfirmed) {
          //     queryClient.invalidateQueries(["posts"]);
          //     // navigate("/");
          //   }
          // });
          // console.log("propertyResponse", propertyResponse);
          saveUserPersona(obj2, {
            onSuccess: (userPersonaResponse) => {
              // console.log("userPersonaResponse", userPersonaResponse);
              // Swal.fire({
              //   title: "Success",
              //   text: "Data submitted successfully!",
              //   icon: "success",
              //   confirmButtonText: "Ok",
              // }).then((result) => {
              //   if (result.isConfirmed) {
              //     queryClient.invalidateQueries(["posts"]);
              //     navigate("/");
              //   }
              // });
              setShowModal(!showModal);
            },
            onError: (userPersonaError) => {
              Swal.fire({
                title: "Error",
                text:
                  userPersonaError.response?.data?.message ||
                  "Error submitting user persona",
                icon: "error",
              });
            },
          });
        },
        onError: (propertyError) => {
          console.log(propertyError);
          Swal.fire({
            title: "Error",
            text:
              propertyError.response?.data?.message ||
              "Error submitting property wanted",
            icon: "error",
          });
        },
      });
    }
  };

  const onRemoveImage = (index) => {
    const temp = images;
    temp.splice(index, 1);
    setImages([...temp]);
  };

  const verificationBadge = useGetVerification();

  const handleVegetarianChange = (e) => {
    // const value = e.target.value === "1";
    // setVegetarian(value);
    // let pescatarian = value === 1 ? 0 : 1;
    // setValue("pescatarian", pescatarian);
    const value = e.target.value === "1";
    setValue("vegetarian", value ? "1" : "0");
    setValue("pescatarian", value ? "0" : "1");
  };

  const handlePescatarianChange = (e) => {
    // const value = e.target.value === "1";
    // setVegetarian(!value);
    // let vegetarian = value === 1 ? 0 : 1;
    // setValue("vegetarian", vegetarian);
    const value = e.target.value === "1";
    setValue("vegetarian", value ? "0" : "1");
    setValue("pescatarian", value ? "1" : "0");
  };

  const sellChangeHandler = (e) => {
    if (e.target.value === "1") {
      setSaleOption(true);
      setValue("sell_option", 1);
      setValue("buy_option", 1);
      setValue("rent_option", 0);
    } else if (e.target.value === "0") {
      setSaleOption(false);
      setValue("sell_option", 0);
      setValue("buy_option", 0);
      setValue("rent_option", 1);
    }
    setValue("payment_style_type", "");
  };

  return (
    <>
      <div>
        {getBedroom?.isLoading ||
        getCurrency?.isLoading ||
        getInventory?.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="box-shadow">
            <div className="create-post">
              <h3 className="text-center">Want a Property / Room</h3>
              <hr />
              <div className="user-post">
                <div className="post-profile">
                  
                  <Avatar
                    isVerified={!!verificationBadge}
                    src={userProfilePic}
                    // imageStyle={}
                    className="img-fluid me-2"
                    imageStyle={{
                      height: 50,
                      width: 50,
                    }}
                    firstName={getInitials(userFullName)}
                    lastName={""}
                  />
                  <figcaption>
                    <h5 className="mb-0 ms-1">{userFullName}</h5>
                  </figcaption>
                </div>
              </div>
              <div className="new-post">
                <form>
                  {/* title */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>Add a Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write Something..."
                        name="title"
                        {...register("title")}
                      />
                      {errors?.title?.message ? (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.title?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* title */}

                  {/* description */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Description <small>(Content Required 150 words*)</small>
                      </label>
                      <CKEditor
                        id="full-featured-non-premium"
                        editor={ClassicEditor}
                        //   data={text}
                        // onChange={(event, editor) => {
                        //   const data = editor.getData();
                        //   setText(data);
                        // }}
                        //   onChange={(event, editor) => {
                        //     setValue("input", editor.getData());
                        //     trigger("input");
                        //   }}
                        data={postUpdateValue?.description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log(data);
                          setValue("description", data);
                        }}
                      />
                      {/* <p>{parse(text)}</p> */}
                    </div>
                  </div>
                  {/* description */}

                  {/* images */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Upload Images{" "}
                        <small>(you can upload multiple images here*)</small>
                      </label>
                      {/* <Dropzone onDrop={onDrop} /> */}
                      <CustomCropper
                        mutate={mutateAsync}
                        onSuccess={(res) => {
                          setImages([...images, res]);
                        }}
                        post={true}
                      />
                      <ShowImage
                        onRemoveImage={onRemoveImage}
                        images={images}
                        uploadStatus={uploadStatus}
                      />
                    </div>
                  </div>
                  {/* images */}

                  {/* property type */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Property Type{" "}
                        <small>
                          (what type of property are you looking for?*)
                        </small>
                      </label>
                      <select
                        type="select"
                        name="type"
                        {...register("type")}
                        className="form-select"
                      >
                        <option value={""} disabled>
                          Select type
                        </option>
                        {getInventory &&
                          getInventory?.data?.map((item) => (
                            <option
                              value={item?.type?.toLowerCase()}
                              key={item?.id}
                            >
                              {item?.type}
                            </option>
                          ))}
                      </select>
                      {errors?.type && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.type.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* property type */}

                  {/* address */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Add Property Address{" "}
                        <small>
                          (where would you like the property? we'll find it for
                          you*)
                        </small>
                      </label>

                      <AddressAutoComplete
                        onChange={(value) => {
                          setAddress(value);
                          setValue("location", value?.completeAddress);
                        }}
                        latitude={state?.post?.latitude}
                        longitude={state?.post?.longitude}
                      />

                      {errors?.location?.message ? (
                        <div style={{ color: "red" }}>
                          {errors?.location?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* address */}

                  {/* number of bed rooms */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Number of Bedrooms{" "}
                        <small>(how many bedrooms would you like?*)</small>
                      </label>
                      <select
                        type="select"
                        name="bedrooms"
                        {...register("bedrooms")}
                        className="form-select"
                      >
                        <option value={""} disabled>
                          Select type
                        </option>
                        {getBedroom?.data?.map((item) => (
                          <option value={item?.bedrooms} key={item?.id}>
                            {item?.bedrooms}
                          </option>
                        ))}
                      </select>
                      {errors?.bedrooms && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.bedrooms.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* number of bed rooms */}

                  {/* post property No of bathrooms container */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Number of Bathrooms{" "}
                        <small>(how many bathrooms would you like?*)</small>
                      </label>
                      <select
                        type="select"
                        name="bathrooms"
                        {...register("bathrooms")}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select a value
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      {errors?.bathrooms && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.bathrooms.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* post property No of bathrooms container */}

                  {/* post property No of reception_rooms container */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Number of Reception Rooms{" "}
                        <small>
                          (how many reception rooms would you like?*)
                        </small>
                      </label>
                      <select
                        type="select"
                        name="reception_rooms"
                        {...register("reception_rooms")}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select a value
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      {errors?.reception_rooms && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.reception_rooms.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* post property No of reception_rooms container */}

                  {/* key features */}
                  <div className="features">
                    <label>Select Features</label>
                    <div className="post-input" style={{ paddingBottom: 0 }}>
                      <div className="feature_container">
                        {Features.map((item) => (
                          <div className="check_container" key={item.id}>
                            <input
                              type="checkbox"
                              value={item.value}
                              name="features"
                              className="radio-control"
                              {...register("features", {
                                required: "Select at least 1 feature",
                              })}
                              // onChange={sellChangeHandler}
                            />
                            <label className="check-lable">{item.label}</label>
                          </div>
                        ))}
                      </div>
                      {/* <TagInputField
                        setTags={setFeatures}
                        tags={features}
                        placeholder={"Enter a feature"}
                      /> */}
                      {errors?.features && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.features.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* key features */}

                  {/* key amenities */}

                  <div className="amenities ">
                    <label>Select Amenities</label>
                    <div className="post-input" style={{ paddingBottom: 0 }}>
                      <div className="feature_container">
                        {Amenities.map((item) => (
                          <div className="check_container" key={item.id}>
                            <input
                              type="checkbox"
                              value={item.value}
                              name="amenities"
                              className="radio-control"
                              {...register("amenities", {
                                required: "Select at least 1 amenitie",
                              })}
                              // onChange={sellChangeHandler}
                            />
                            <label className="check-lable">{item.label}</label>
                          </div>
                        ))}
                      </div>
                      {errors?.amenities && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.amenities.message}
                        </div>
                      )}
                      {/* <TagInputField
                        setTags={setFeatures}
                        tags={features}
                        placeholder={"Enter a feature"}
                      /> */}
                    </div>
                  </div>
                  {/* key amenities */}

                  {/* key words */}
                  <div>
                    <div className="post-input">
                      <label>
                        Add Some Tags{" "}
                        <small>
                          (keywords will help you find the property you want
                          faster!)
                        </small>
                      </label>
                      <TagInputField
                        setTags={setTags}
                        tags={tags}
                        placeholder={"Enter a tag"}
                      />
                    </div>
                  </div>
                  {/* key words */}

                  {/* property price*/}

                  {/* <div className="post-input">
                    <label>Add Property Price</label> */}
                  {/* <InputGroup className="mb-3">
                    
                      <Form.Select
                        style={{ flex: 0.1 }}
                        id="basic-addon1"
                        aria-label="Default select example"
                        value={selectedCurrency}
                        onChange={(event) => {
                          const { value } = event.target;
                          setSelectedCurrency(value);
                        }}
                      >
                        <option disabled>Select Currency</option>
                        {getCurrency?.data?.map((item) => (
                          <option value={item?.currency_symbol} key={item?.id}>
                            {item?.currency_code} {item?.currency_symbol}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control
                        placeholder="Price"
                        aria-label="Price"
                        aria-describedby="basic-addon1"
                        type="number"
                        name="price"
                        min={1}
                        {...register("price", {
                          valueAsNumber: true,
                        })}
                      />
                    </InputGroup> */}
                  {/* <input
                      placeholder="Price"
                      type="number"
                      name="price"
                      className="form-control"
                      min={1}
                      {...register("price", {
                        valueAsNumber: true,
                      })}
                    />
                    {errors?.price?.message ? (
                      <div className="post-validation" style={{ color: "red" }}>
                        {errors?.price?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div> */}

                  {/* property price*/}

                  {/* payment type */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Payment Type{" "}
                        <small>(are you planning to buy or rent?*)</small>
                      </label>
                      <div className="radio_control_container">
                        <div className="radio_container">
                          <input
                            type="radio"
                            value="1"
                            name="sell_option"
                            className="radio-control"
                            checked={saleOption === true}
                            onChange={sellChangeHandler}
                          />
                          <label className="radio-lable">Buy</label>
                        </div>
                        <div className="radio_container">
                          <input
                            type="radio"
                            value="0"
                            name="sell_option"
                            className="radio-control"
                            checked={saleOption === false}
                            onChange={sellChangeHandler}
                          />
                          <label className="radio-lable">Rent</label>
                        </div>
                      </div>

                      {errors?.sell_option?.message ? (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.sell_option?.message}
                        </div>
                      ) : (
                        ""
                      )}

                      {saleOption === true ? (
                        <>
                          <div className="radio_control_container pt-2">
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Payment Plan"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">
                                Payment Plan
                              </label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Morgage"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Morgage</label>
                            </div>

                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Cash"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Cash</label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Other"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Other</label>
                            </div>
                          </div>
                        </>
                      ) : saleOption === false ? (
                        <>
                          <div className="radio_control_container pt-2">
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="weekly"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Weekly</label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="quaterly"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Quaterly</label>
                            </div>

                            <div className="radio_container">
                              <input
                                type="radio"
                                value="monthly"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Monthly</label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="yearly"
                                name="payment_style_type"
                                className="radio-control"
                                {...register("payment_style_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Yearly</label>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* payment type */}

                  {/* post property price container */}
                  <div className="post_container mb-3">
                    {/* min_price */}
                    <div className="post_price">
                      <div className="post-input">
                        <label>
                          Minimum Budget{" "}
                          <small>(what is your minimum spend?*)</small>
                        </label>
                        {/* <input
                          placeholder="minimum proporty price"
                          type="number"
                          name="min_price"
                          className="form-control"
                          {...register("min_price")}
                        /> */}

                        <InputGroup>
                          {/* <InputGroup.Text id="basic-addon1">$</InputGroup.Text> */}
                          <Form.Select
                            style={{ flex: 0.1 }}
                            id="min-price"
                            aria-label="Default select example"
                            value={selectedCurrency}
                            onChange={(event) => {
                              const { value } = event.target;
                              setSelectedCurrency(value);
                            }}
                          >
                            <option disabled>Select Currency</option>
                            {getCurrency?.data?.map((item) => (
                              <option
                                value={item?.currency_symbol}
                                key={item?.id}
                              >
                                {item?.currency_code} {item?.currency_symbol}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control
                            placeholder="min price"
                            aria-label="min_price"
                            aria-describedby="basic-addon1"
                            type="number"
                            name="min_price"
                            min={1}
                            // {...register("min_price", {
                            //   valueAsNumber: true,
                            // })}
                            value={miniPirce}
                            onChange={(e) => {
                              const value = parseFloat(e.target.value);
                              if (value >= 0) {
                                setMiniPrice(value);
                                setValue("min_price", value);
                              }
                            }}
                          />
                        </InputGroup>

                        {errors?.min_price?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.min_price?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* min_price */}

                    {/* max_price */}
                    <div className="post_price">
                      <div className="post-input">
                        <label>
                          Maximum Budget{" "}
                          <small>(what is your maximum spend?*)</small>
                        </label>
                        <input
                          placeholder="minimum proporty price"
                          type="number"
                          name="max_price"
                          className="form-control"
                          min={1}
                          // {...register("max_price")}
                          value={maxPrice}
                          onChange={(e) => {
                            const value = parseFloat(e.target.value);
                            if (value >= 0) {
                              setMaxPrice(value);
                              setValue("max_price", value);
                            }
                          }}
                        />
                        {errors?.max_price?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.max_price?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* max_price */}
                  </div>
                  {/* post property price container */}

                  {/* post date container */}
                  <div className="post_container mb-3">
                    {/* minimum availabel date */}
                    <div className="mb-3 me-md-3 me-3 post_date">
                      <div className="post-input">
                        <label>
                          Move-in/Handover Date{" "}
                          <small>
                            (what is the earliest date you would like to move in
                            or are able to purchase this property?*)
                          </small>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="min_available_date"
                          {...register("min_available_date")}
                        />
                        {errors?.min_available_date?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.min_available_date?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* minimum availabel data */}

                    {/* maximum availabel date */}
                    <div className="mb-3 post_date">
                      <div className="post-input">
                        <label>
                          Move-out/Handover Date{" "}
                          <small>
                            (what is the latest date you would like to move in
                            or are able to purchase this property?*)
                          </small>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="max_available_date"
                          {...register("max_available_date")}
                        />
                        {errors?.max_available_date?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.max_available_date?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* maximum availabel data */}
                  </div>
                  {/* post date container */}

                  {/* finishing */}

                  <div className="mb-3">
                    <div className="post-input">
                      <label>Touchings</label>
                      <div className="radio_control_container">
                        <div className="radio_container">
                          <input
                            type="radio"
                            value="1"
                            name="furnished"
                            className="radio-control"
                            {...register("furnished", {
                              required: "Please select a furnished",
                            })}
                          />
                          <label className="radio-lable">Furnished</label>
                        </div>

                        <div className="radio_container">
                          <input
                            type="radio"
                            value="0"
                            name="furnished"
                            className="radio-control"
                            {...register("furnished", {
                              required: "Please select a furnished",
                            })}
                          />
                          <label className="radio-lable">Unfinished</label>
                        </div>
                      </div>
                      {errors?.furnished?.message ? (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.furnished?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* finishing */}

                  <div className="interest_container">
                    <h3 className="text-center">Interest</h3>

                    {/* <hr /> */}

                    {/* post property No of Children container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>No of Children</label>
                        <select
                          type="select"
                          name="number_of_children"
                          {...register("number_of_children")}
                          className="form-select"
                        >
                          <option value="" disabled>
                            Select a value
                          </option>
                          <option value="0">0</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                        </select>
                        {errors?.number_of_children && (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.number_of_children.message}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* post property No of Children container */}

                    {/* post property Nationality container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>Nationality</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter nationality"
                          name="nationality"
                          {...register("nationality")}
                        />
                        {errors?.nationality?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.nationality?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* post property Nationality container */}

                    {/* post property occupation container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>Occupation</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter occupation..."
                          name="occupation"
                          {...register("occupation")}
                        />
                        {errors?.occupation?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.occupation?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* post property occupation container */}

                    {/* post property smoker container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>Smoker</label>
                        <div className="radio_control_container">
                          <div className="radio_container">
                            <input
                              type="radio"
                              value="1"
                              name="smoker"
                              className="radio-control"
                              {...register("smoker", {
                                required: "Please select an option",
                              })}
                            />
                            <label className="radio-lable">Yes</label>
                          </div>

                          <div className="radio_container">
                            <input
                              type="radio"
                              value="0"
                              name="smoker"
                              className="radio-control"
                              {...register("smoker", {
                                required: "Please select an option",
                              })}
                            />
                            <label className="radio-lable">No</label>
                          </div>
                        </div>
                        {errors?.smoker?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.smoker?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* post property smoker container */}

                    {/* post property pets container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>Pets</label>

                        <div className="radio_control_container">
                          <div className="radio_container">
                            <input
                              type="radio"
                              value="1"
                              name="pets"
                              className="radio-control"
                              {...register("pets", {
                                required: "Please select an option",
                              })}
                            />
                            <label className="radio-lable">Yes</label>
                          </div>

                          <div className="radio_container">
                            <input
                              type="radio"
                              value="0"
                              name="pets"
                              className="radio-control"
                              {...register("pets", {
                                required: "Please select an option",
                              })}
                            />
                            <label className="radio-lable">No</label>
                          </div>
                        </div>

                        {errors?.pets?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.pets?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    {/* post property pets container */}

                    {/* post property vegetarian container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>Vegetarian</label>

                        <div className="radio_control_container">
                          <div className="radio_container">
                            <input
                              type="radio"
                              value="1"
                              name="vegetarian"
                              className="radio-control"
                              {...register("vegetarian", {
                                required: "Please select an option",
                              })}
                              onChange={handleVegetarianChange}
                            />
                            <label className="radio-lable">Yes</label>
                          </div>

                          <div className="radio_container">
                            <input
                              type="radio"
                              value="0"
                              name="vegetarian"
                              className="radio-control"
                              {...register("vegetarian", {
                                required: "Please select an option",
                              })}
                              onChange={handleVegetarianChange}
                            />
                            <label className="radio-lable">No</label>
                          </div>
                        </div>

                        {errors?.vegetarian?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.vegetarian?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* post property vegetarian container */}

                    {/* post property pescatarian container */}
                    <div className="mb-3">
                      <div className="post-input">
                        <label>Pescatarian</label>

                        <div className="radio_control_container">
                          <div className="radio_container">
                            <input
                              type="radio"
                              value="1"
                              name="pescatarian"
                              className="radio-control"
                              {...register("pescatarian", {
                                required: "Please select an option",
                              })}
                              onChange={handlePescatarianChange}
                            />
                            <label className="radio-lable">Yes</label>
                          </div>

                          <div className="radio_container">
                            <input
                              type="radio"
                              value="0"
                              name="pescatarian"
                              className="radio-control"
                              {...register("pescatarian", {
                                required: "Please select an option",
                              })}
                              onChange={handlePescatarianChange}
                            />
                            <label className="radio-lable">No</label>
                          </div>
                        </div>

                        {errors?.pescatarian?.message ? (
                          <div
                            className="post-validation"
                            style={{ color: "red" }}
                          >
                            {errors?.pescatarian?.message}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* post property pescatarian container */}
                  </div>

                  <div className="mb-3 text-end">
                    <div
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-common"
                      disabled={isPropertyWantedLoading || isUserPersonaLoading}
                      // onClick={() => {
                      //   setShowModal(!showModal);
                      // }}
                    >
                      {state?.post ? "Update" : "Create"}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>

      {showModal && (
        <MailboxModal
          setShowModal={setShowModal}
          showModal={showModal}
          userId={auth?.userId.toString()}
          postType={"post_wanted_listing"}
        />
      )}
    </>
  );
};

export default PropertyWantedListing;
