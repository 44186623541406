import React, { useEffect } from "react";
import { RxCross2 } from "react-icons/rx";

import "./index.css";
import TagInputField from "components/tagInputField/TagInputField";
import TagEmailField from "components/tagEmails";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { API } from "services/ApiClient";
import { isNonEmptyString } from "helpers";
import Swal from "sweetalert2";
const MailboxModal = ({ setShowModal, showModal, userId, postType }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [tags, setTags] = React.useState([]);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const { mutate: emailServices, isLoading: isEmailServicesLoading } =
    useMutation(
      async (payload) => {
        const res = await API.post("/email-service", payload);
        console.log("res", res);

        if (isNonEmptyString(res?.data?.message)) {
          // setShowModal(!showModal);
          Swal.fire({
            title: "Success",
            text: res?.data?.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/");
            }
          });
        }
        return null;
      },
      {
        onSuccess: (data) => {
          if (data) {
            queryClient.invalidateQueries(["email-services"]);
          }
        },
      }
    );

  const submitHandler = async () => {
    // const emailString = tags?.length ? tags.join(",") : "";
    console.log("tags", tags);
    if (tags.length < 1) {
      return Swal.fire({
        title: "Error",
        text: "Please add some emails",

        icon: "error",
      });
      
    }
    const emailString = tags?.length ? JSON.stringify(tags) : "";

    if (emailString.length < 1) {
      return;
    }
    const obj = {
      user_id: userId,
      emails: emailString,
    };

    emailServices(obj, {
      onError: (err) => {
        Swal.fire({
          title: "Error",
          text: err.response?.data?.message,
          icon: "error",
        });
      },
    });
  };

  return (
    <div className="box-modal">
      <div className="box">
        <div className="box-header">
          <span className="close-btn" onClick={() => setShowModal(!showModal)}>
            <RxCross2 />
          </span>
        </div>
        <div className="modal-body">
          <div>
            <div className="post-input">
              <label>Share With Others
              <small> (share with as many people you want)</small>
              </label>
              <TagEmailField setTags={setTags} tags={tags} />
              {/* <TagInputField setTags={setTags} tags={tags} /> */}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn btn-common" onClick={submitHandler} disabled={isEmailServicesLoading}>
            Send
          </button>
          <button className="btn btn-common" onClick={() => navigate("/")}>
            Skip
          </button>
        </div>
      </div>
    </div>
  );
};

export default MailboxModal;
