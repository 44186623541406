import { useJsApiLoader } from "@react-google-maps/api";
import React, { useEffect, useState } from "react";
import { getAddress, getInitials } from "../../../helpers";
import { searchService } from "../../../services/SearchService";
import { Pagelimit } from "constants/data";

function useMaps({ type,page }) {
  const [markers, setMarkers] = useState([]);
  const [users, setUsers] = useState([]);
  const [userPageNumber, setUserPageNumber] = useState({});
  const [postPageNumber, setPostPageNumber] = useState({});
  const [userAdrress, setUserAddress] = useState([]);
  const [isDataSet, setIsDataSet] = useState(false);
  const [usersObject, setUsersObject] = useState({});
  const [posts, setPosts] = useState([]);
  const [postLoading,setPostLoading]  = useState(false);
  const [userLoading,setUserLoading]  = useState(false);

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyD3OC1Q1D4Yg1Y37z2l3VXGGENkhWhEsWQ",
    libraries: ["places"],
  });


  

  useEffect(() => {
    if (type === "post") {
      // fetchInitialsPost(page, limit);
      fetchInitialsPost();
    } else {
      // fetchInitials(page, limit);
      fetchInitials();
    }
  }, [page]);

  const fetchInitialsPost = async () => {
    setPostLoading(true)
    const response1 = searchService.GetUserAddress();
    const response2 = searchService.GetUsers(page,Pagelimit);
    const getPosts = searchService.GetPosts(page,Pagelimit);
    const [result1, result2, postsData] = await Promise.all([
      response1,
      response2,
      getPosts,
    ]);

    setUserAddress(result1);

    let usersAddress = {};
    result1.length > 0&&result1 && result1?.map((item) => {
      const {
        user_id,
        longitude,
        latitude,
        forename,
        surname,
        profile_image,
        address_line_1,
        id,
      } = item;

      usersAddress = {
        ...usersAddress,
        [id]: {
          id: id,
          user_id: id,
          position: {
            lat: Number(latitude),
            lng: Number(longitude),
            s: "SS",
          },
          title: `${forename ?? ""} ${surname ?? ""}`,
          location: address_line_1,
          profile_image: profile_image,
        },
      };
    });

    // const uniqueArr = response2?.filter(
    //   (obj, index, self) =>
    //     index === self.findIndex((t) => t.user_id === obj.user_id)
    // );

    let tempUserObj = {};
    result2?.results?.map((item) => {
      tempUserObj = {
        ...tempUserObj,
        [item?.id]: item,
      };
    });
    let temp = postsData?.results?.map((item) => ({
      ...item,
      user: tempUserObj?.[item?.user_id],
      address: {
        ...usersAddress?.[item?.user_id],
        id: item?.id,
      },
    }));
    setPosts(temp);
    setPostPageNumber(postsData);

    let mergedArr = result2?.results?.map((obj1) => {
      const obj2 = postsData?.results?.find((obj2) => obj2.user_id === obj1.id);
      return { ...obj1, ...obj2 };
    });
    // mergedArr = mergedArr.filter(Boolean);

    // const uniqueArr = response2?.filter(
    //   (obj, index, self) =>
    //     index === self.findIndex((t) => t.user_id === obj.user_id)
    // );

    setUsersObject(tempUserObj);
    setUsers(mergedArr);
    // fetchData(mergedArr, result1);
    setPostLoading(false);
  };

  const fetchInitials = async () => {
    try {
      setUserLoading(true);
      const response1 = searchService.GetUserAddress();
      const response2 = searchService.GetUsers(page,Pagelimit);

      const [result1, result2] = await Promise.all([response1, response2]);
      let usersAddress = {};
      // console.log("result1", result1);
      // console.log("result2", result2);
      result1.length > 0&&result1 && result1?.map((item) => {
        const {
          user_id,
          longitude,
          latitude,
          forename,
          surname,
          profile_image,
          address_line_1,
          id,
        } = item;

        usersAddress = {
          ...usersAddress,
          [id]: {
            id: id,
            position: {
              lat: Number(latitude),
              lng: Number(longitude),
              s: "SS",
            },
            title: `${forename ?? ""} ${surname ?? ""}`,
            location: address_line_1,
            profile_image: profile_image,
          },
        };
      });
      const usersData = result2?.results?.map((item) => ({
        ...item,
        address: usersAddress[item?.id],
      }));
      setUsers(usersData);
      setUserPageNumber(result2);
      setUserAddress(result1);

      //   setFilteredUsers(result2);

      // fetchData(result2, result1);

      setIsDataSet(true);
      setUserLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  // const fetchData = async (users, addresses) => {
  //   setMarkers([]);
  //   const newMarkers = await Promise.all(
  //     users.map(async (user) => {
  //       const userAddresses = addresses.filter((x) =>
  //         type === "post" ? x.user_id === user.user_id : x.user_id === user.id
  //       );
  //       if (
  //         userAddresses &&
  //         typeof userAddresses !== "undefined" &&
  //         userAddresses.length > 0
  //       ) {
  //         const markers = await Promise.all(
  //           userAddresses.map(async (address) => {
  //             const addr = getAddress(
  //               address.street_number,
  //               address.address_line_1,
  //               address.address_line_2,
  //               address.city,
  //               address.nick_name,
  //               address.postal_code
  //             );
  //             const { lat, lng } = await fetchLatLng(addr);
  //             return {
  //               id: user.id,
  //               position: { lat, lng },
  //               title: `${user.forename ?? ""} ${user.surname ?? ""}`,
  //               location: addr,
  //               profile_image: user.profile_image,
  //             };
  //           })
  //         );
  //         return markers;
  //       } else {
  //         return [];
  //       }
  //     })
  //   );
  //   const flattenedMarkers = newMarkers.flat();
  //   setMarkers(flattenedMarkers);
  // };

  // async function fetchLatLng(address) {
  //   const encodedAddress = encodeURIComponent(`${address}`);
  //   const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=AIzaSyD3OC1Q1D4Yg1Y37z2l3VXGGENkhWhEsWQ`;
  //   const response = await fetch(url);
  //   const data = await response.json();
  //   if (data.status === "OK") {
  //     return data.results[0].geometry.location;
  //   } else {
  //     throw new Error(
  //       `Error fetching latitude and longitude for address: ${address}`
  //     );
  //   }
  // }

  // const getMarkerIcon = (data) => {
  //   const icon = document.createElement("div");
  //   const initials = getInitials(data?.title);
  //   const imgSrc = data?.profile_image;
  //   console.log(imgSrc);
  //   const imgTag = `<img loading="lazy" src="${imgSrc}" data-src="${imgSrc}" alt="${data?.title}" class="img-fluid" style="width:3rem; height:3rem;"/>`;
  
  //   icon.innerHTML = `<div class="post-profile" style="display: flex; width: 100%; height: 100%; align-items: center; justify-content: center;"><figure style="margin: 0px; display: flex; align-items: center;">${
  //     imgSrc
  //       ? `<picture><source srcset="${imgSrc}" type="image/webp"><source srcset="${imgSrc}" type="image/png">${imgTag}</picture>`
  //       : `<span style="font-size:100%;">${initials}</span>`
  //   }</figure></div>`;
  
  //   const svgString = encodeURIComponent(
  //     `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><foreignObject width="100%" height="100%"><div xmlns="http://www.w3.org/1999/xhtml" style="background-color:#00c8c8; color:#fff; font-family: Roboto; border-radius:100%; font-size:100px; border: 6px solid #fff; height:60%; width:60%;">${icon.innerHTML}</div></foreignObject></svg>`
  //   );
  
  //   const markerIcon = new window.google.maps.MarkerImage(
  //     `data:image/svg+xml;charset=UTF-8,${svgString}`,
  //     null,
  //     null,
  //     null,
  //     new window.google.maps.Size(40, 40)
  //   );
  
  //   return markerIcon;
  // };


  const getMarkerIcon = (data) => {
    const initials = getInitials(data?.title);
    const imgSrc = data?.profile_image;

    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
    svg.setAttribute("viewBox", "0 0 300 300");
    svg.setAttribute("width", "50"); 
    svg.setAttribute("height", "50");

    const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
    circle.setAttribute("cx", "150");
    circle.setAttribute("cy", "150");
    circle.setAttribute("r", "135");
    circle.setAttribute("fill", "#00c8c8");
    circle.setAttribute("stroke", "#fff");
    circle.setAttribute("stroke-width", "6"); 
    circle.setAttribute("style", "border-radius: 100%");

    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.textContent = initials;
    text.setAttribute("x", "50%");
    text.setAttribute("y", "50%");
    text.setAttribute("text-anchor", "middle");
    text.setAttribute("dominant-baseline", "central");
    text.setAttribute("fill", "#fff");
    text.setAttribute("font-size", "100px"); 
    text.setAttribute("font-weight", "bold");
    text.setAttribute("font-family", "Roboto");

    svg.appendChild(circle);
    svg.appendChild(text);

    const svgString = encodeURIComponent(new XMLSerializer().serializeToString(svg));

    const markerIcon = new window.google.maps.MarkerImage(
        `data:image/svg+xml;charset=utf-8,${svgString}`,
        null,
        null,
        null,
        new window.google.maps.Size(50, 50) 
    );

    return markerIcon;
};


  
  // Example usage:
  // const markerIcon = getMarkerIcon({ title: "Example", profile_image: "example.jpg" });
  // Use markerIcon with Google Maps API
  
  
  return {
    isLoaded,
    getMarkerIcon,
    markers,
    // filterMarkers: fetchData,
    users,
    userAdrress,
    usersObject,
    posts,
    fetchInitials,
    fetchInitialsPost,
    userPageNumber,
    postPageNumber,
    userLoading,
    postLoading,
  };
}

export default useMaps;
  