const { AuthContext } = require("context/authContext");
const { useContext } = require("react");
const { useQuery } = require("react-query");
const { getTotalPropertyMatchesById, getTotalPropertyWantedMatchesById } = require("services/notificationApi");

function useMyPropertyMatches({ type }) {
  const { auth } = useContext(AuthContext);
  const user_id = auth?.userId ? auth?.userId.toString() : "";
  return useQuery(
    [type, user_id],
    () =>
      type === "Chain"
        ? getTotalPropertyMatchesById(4)
        : type === "matchedPropertyWanted"
        ? getTotalPropertyWantedMatchesById(4)
        : null,
    {
      enabled: !!type,
    }
  );
}

export default useMyPropertyMatches;