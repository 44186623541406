/* eslint-disable no-unused-vars */
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useCallback, useContext, useEffect, useState } from "react";
// import parse from "html-react-parser";
import { Form, InputGroup } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import { yupResolver } from "@hookform/resolvers/yup";
import { RxCross2 } from "react-icons/rx";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { postsService } from "../../services/ImageUploadApi";
import { getUserFullName, getUserProfileImage } from "../../utils/Storage";
import { getInitials, isNonEmptyString, parseStringArray } from "../../helpers";
import Dropzone from "../../components/dropzone/Dropzone";
import ShowImage from "../../components/showImages/ShowImages";
import TagInputField from "../../components/tagInputField/TagInputField";
import { API } from "../../services/ApiClient";
import { AuthContext } from "../../context/authContext";
import axios from "axios";
import { updatePost } from "../../services/PostApi";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "../../components/messageModal/MessageModal";
import Avatar from "../../components/image/Avatar";
import AddressAutoComplete from "../setting/details/updateAddress/AddressAutoComplete";
import CustomCropper from "../../components/customCropper/CustomCropper";
import {
  getPostImageByUserId,
  uploadPostImage,
} from "../../services/fileUploadApi";
import useGetVerification from "hooks/query/AllUserProfile/useGetVerification";
import useGetInventory from "hooks/query/commonData/useGetInventory";
import useGetCurrency from "hooks/query/commonData/useGetCurrency";
import useGetBedrooms from "hooks/query/commonData/useGetBedroom";
import LoadingSpinner from "components/messageModal/LoadingSpinner";
import imageCompression from "browser-image-compression";
import MailboxModal from "components/mailboxModal";
import { amenities, features } from "constants/data";
const CLOUDINARY_UPLOAD_PRESET = "social_locket";
const CLOUDINARY_UPLOAD_URL =
  "https://api.cloudinary.com/v1_1/dzs0eyrnl/image/upload";
const schema = yup.object().shape({
  // title: yup.string().required(),
  title: yup
    .string()

    .required("Title is required"),
  type: yup.string().nullable().required("Please select type"),
  // pages: yup.string().required(),
  pages: yup
    .mixed()
    .test("positiveOrNaN", "Page must be greater than zero", (value) => {
      return Number(value) > 0 || Number.isNaN(value);
    })
    .required("Page must be greater than zero"),
  // price: yup.number().positive("Price must be greater than zero").typeError(""),
  // price: yup.number().positive().label("seats").required("pls enter").min(1),
  price: yup
    .mixed()
    .test("positiveOrNaN", "Price must be greater than zero", (value) => {
      return typeof value === "number" && (value > 0 || Number.isNaN(value));
    })
    .required("Price must be greater than zero"),

  bathrooms: yup
    .number()
    .typeError("number_of_children must be a number")
    .required("number_of_children is required")
    // .positive("please enter the valid number")
    .moreThan(-1, "number_of_children must be positive"),
  reception_rooms: yup
    .number()
    .typeError("number_of_children must be a number")
    .required("number_of_children is required")
    // .positive("please enter the valid number")
    .moreThan(-1, "number_of_children must be positive"),
  amenities: yup
    .array()
    .min(1, "Select at least 1 amenitie")
    .required("amenitie is required")
    .typeError("Amenities must be an array"),
  features: yup
    .array("")
    .min(1, "Select at least 1 amenitie")
    .required("amenitie is required")
    .typeError("Amenities must be an array"),
  sell_option: yup.string().required("sell_option is required"),
  buy_option: yup.string().required("buy_option is required"),
  rent_option: yup.string().required("rent_option is required"),
  furnished: yup.string().required("furnished is required"),
  payment_type: yup.string().required("payment_type is required"),
});
const CreatePost = () => {
  const getInventory = useGetInventory();
  const getCurrency = useGetCurrency();
  const getBedroom = useGetBedrooms();
  const [showModal, setShowModal] = useState(false);
  const [saleOption, setSaleOption] = useState(null);

  const [images, setImages] = useState([]);
  const [uploadStatus, setUploadStatus] = useState("idle");
  const userProfilePic = getUserProfileImage();
  const userFullName = getUserFullName();
  const { state } = useLocation();

  console.log("state", state);

  const [tags, setTags] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("$");
  const [address, setAddress] = useState(null);
  const preloadedValues = {
    title: state?.title,
  };
  const postUpdateValue = {
    description: state?.post?.description,
    title: state?.title,
    price: Number(state?.post?.price?.replace(/[^\d.]/g, "") || ""),
    pages: Number(state?.post?.pages),
    location: state?.post?.location,
    type: state?.post?.type ? state?.post?.type?.toLowerCase() : "",
    bathrooms: state?.post?.bathrooms ? Number(state?.post?.bathrooms) : "",
    reception_rooms: state?.post?.reception_rooms
      ? Number(state?.post?.reception_rooms)
      : "",
    features: parseStringArray(state?.post?.features) ?? [],
    amenities: parseStringArray(state?.post?.amenities) ?? [],
    furnished: state?.post?.furnished,
    sell_option: state?.post?.for_sale ? "1" : "0",
    payment_type : state?.post?.payment_type ? state?.post?.payment_type : "",
  };
  useEffect(() => {}, []);

  useEffect(() => {
    if (state?.post) {
      const post = state?.post;
      setImages(parseStringArray(post.images ?? "") ?? []);
      setTags(parseStringArray(post?.keywords) ?? []);

      setAddress({
        latitude: Number(post?.latitude || 0),
        longitude: Number(post?.longitude || 0),
      });

      if (post?.for_sale === "1") {
        setSaleOption(true);
        setValue("sell_option", 1);
        setValue("buy_option", 1);
        setValue("rent_option", 0);
      } else if (post?.to_rent === "1") {
        setSaleOption(false);
        setValue("sell_option", 0);
        setValue("buy_option", 0);
        setValue("rent_option", 1);
      }
    }
  }, []);

  useEffect(() => {
    if (state?.post) {
      const post = state?.post;
      const tempCurrency = getCurrency?.data?.find(
        (item) => item?.currency_symbol === post?.currency
      );

      
      
      setSelectedCurrency(tempCurrency.currency_symbol ?? "$");
    }
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: state?.post ? postUpdateValue : preloadedValues,
    resolver: yupResolver(schema),
  });
  const queryClient = useQueryClient();
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    register("description");
  });

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", "post_image");
    formData.append("user_id", auth?.userId);
    console.log("formData", formData);

    // formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
    // formData.append("folder", "my_folder");

    try {
      const result = await uploadPostImage(formData);
      console.log("result", result);
      const response = await getPostImageByUserId(auth?.userId);
      console.log("response", response);

      let url = response?.[response?.length - 1]?.images;
      console.log("url", url);
      return url;
      // return response.data.secure_url;
    } catch (error) {
      console.error("Error uploading to Cloudinary: ", error);
      throw new Error("Error uploading to Cloudinary");
    }
  };

  const { mutateAsync, isLoading } = useMutation(uploadToCloudinary);
  // const onDrop = async (acceptedFiles) => {
  //   setUploadStatus("uploading");

  //   try {
  //     showLoadingSpinner({
  //       loadingText: "Uploading",
  //     });
  //     const uploadedImages = await Promise.all(
  //       acceptedFiles?.map((file) => mutateAsync(file))
  //     );
  //     setImages([...images, ...uploadedImages]);
  //     setUploadStatus("success");
  //   } catch (error) {
  //     console.error("Error uploading images: ", error);
  //     setUploadStatus("error");
  //   } finally {
  //     hideLoadingSpinner({});
  //   }
  // };

  const compressImage = async (file) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1, // Set your desired maximum file size
      });
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image: ", error);
      throw new Error("Error compressing image");
    }
  };

  const onDrop = async (acceptedFiles) => {
    console.log("acceptedFiles", acceptedFiles);
    console.log("acceptedFiles", acceptedFiles[0].size);
    setUploadStatus("uploading");

    try {
      showLoadingSpinner({
        loadingText: "Uploading",
      });
      const compressedFiles = await Promise.all(
        acceptedFiles.map((file) => compressImage(file))
      );
      console.log("compressedFiles", compressedFiles);
      console.log("compressedFiles", compressedFiles[0].size);
      const uploadedImages = await Promise.all(
        compressedFiles.map((file) => mutateAsync(file))
      );

      setImages([...images, ...uploadedImages]);
      setUploadStatus("success");
    } catch (error) {
      console.error("Error uploading images: ", error);
      setUploadStatus("error");
    } finally {
      hideLoadingSpinner({});
    }
  };
  const { mutate: savePost, isLoading: isPostLoading } = useMutation(
    async (payload) => {
      const res = await API.post("/posts", payload);

      if (isNonEmptyString(res?.data?.message)) {
        setShowModal(!showModal);
        // Swal.fire({
        //   title: "Success",
        //   text: res?.data?.message,
        //   icon: "success",
        //   confirmButtonText: "Ok",
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     navigate("/");
        //   }
        // });
      }
      return null;
    },
    {
      onSuccess: (data) => {
        if (data) {
          queryClient.invalidateQueries(["posts"]);
        }
      },
    }
  );

  const { mutate: updateMutate } = useMutation(updatePost, {
    onMutate: () => showLoadingSpinner({}),
    onSuccess: async (res) => {
      Swal.fire({
        title: "Success",
        text: res?.data?.message,
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(-1);
        }
      });
      // setShowModal(!showModal);
    },
    onError: (err, data) => {
      Swal.fire({
        title: "Update Listing",
        text: err?.response?.data?.message,
        icon: "error",
      });
    },
    onSettled: () => hideLoadingSpinner(),
  });

  const onSubmit = async (data) => {
    // const tagsString = `['${tags.join("','")}']`;
    const tempCurrency = getCurrency?.data?.find(
      (item) => item?.currency_symbol === selectedCurrency
    );

    console.log("tempCurrency",tempCurrency);
    
    const tagsString = tags?.length ? JSON.stringify(tags) : "";

    let obj = {
      title: data?.title ?? "",
      description: data?.description ?? "",
      type: data?.type ?? "",
      location: data?.location ?? "",
      price: data?.price ?? "",
      currency: tempCurrency?.currency_symbol ?? "",
      currency_id: tempCurrency?.id ?? "",
      status: "AVAILABLE",
      user_id: auth?.userId ? auth.userId.toString() : "",
      pages: data?.pages ? data.pages.toString() : "",
      images: images ? JSON.stringify(images) : JSON.stringify([]),
      keywords: tagsString?.length ? JSON.stringify(tagsString) : "",
      // available: "24/04/2023",
      offer_price: null,
      purchased_price: "",
      customer_user_id: null,
      latitude: address?.latitude ?? "",
      longitude: address?.longitude ?? "",
      bathrooms: data?.bathrooms ?? "",
      reception_rooms: data?.reception_rooms ?? "",
      amenities: data?.amenities ?? [],
      features: data?.features ?? [],
      furnished: data?.furnished ? data.furnished.toString() : "",
      for_sale: data?.buy_option ? data.buy_option.toString() : "",
      to_rent: data?.rent_option ? data.rent_option.toString() : "",
      payment_type: data?.payment_type
        ? data?.payment_type.toString()
        : "",
    };

    if (!auth.isAuthenticated) {
      navigate("/login");
    }
    if (state?.post) {
      obj = {
        ...obj,
        id: state?.post?.id,
      };
      console.log("obj", obj);

      updateMutate(obj);
    } else {
      savePost(obj, {
        onError: (err) => {
          Swal.fire({
            title: "Error",
            text: err.response?.data?.message,
            icon: "error",
          });
        },
      });
    }
  };

  const onRemoveImage = (index) => {
    const temp = images;
    temp.splice(index, 1);
    setImages([...temp]);
  };

  const verificationBadge = useGetVerification();
  const sellChangeHandler = (e) => {
    if (e.target.value === "1") {
      setSaleOption(true);
      setValue("sell_option", 1);
      setValue("buy_option", 1);
      setValue("rent_option", 0);
    } else if (e.target.value === "0") {
      setSaleOption(false);
      setValue("sell_option", 0);
      setValue("buy_option", 0);
      setValue("rent_option", 1);
    }
    setValue("payment_type", "");
  };

  
  return (
    <>
      <div>
        {getBedroom?.isLoading ||
        getCurrency?.isLoading ||
        getInventory?.isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="box-shadow">
            <div className="create-post">
              <h3 className="text-center">Advertise a Property / Room</h3>
              <hr />
              <div className="user-post">
                <div className="post-profile">
                  {/* <figure>
          {userProfilePic !== null ? (
            // <picture>
            //   <source
            //     srcSet="assets/images/user-img.webp"
            //     type="image/webp"
            //   />
            //   <source srcSet={userProfilePic} type="image/png" />
            //   <img
            //     loading="lazy"
            //     src={userProfilePic}
            //     data-src={userProfilePic}
            //     alt="user-img"
            //     className="img-fluid"
            //     width={50}
            //     height={50}
            //   />
            // </picture>
            <Avatar
              src={userProfilePic}
              // imageStyle={}
              className="img-fluid"
              imageStyle={{
                height: 50,
                width: 50,
              }}
            />
          ) : (
            <span>{userFullName ? getInitials(userFullName) : ""}</span>
          )}
        </figure> */}
                  <Avatar
                    isVerified={!!verificationBadge}
                    src={userProfilePic}
                    // imageStyle={}
                    className="img-fluid me-2"
                    imageStyle={{
                      height: 50,
                      width: 50,
                    }}
                    firstName={getInitials(userFullName)}
                    lastName={""}
                  />
                  <figcaption>
                    <h5 className="mb-0 ms-1">{userFullName}</h5>
                  </figcaption>
                </div>
              </div>
              <div className="new-post">
                <form>
                  <div className="mb-3">
                    <div className="post-input">
                      <label>Add a Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write Something..."
                        name="title"
                        {...register("title")}
                      />
                      {errors?.title?.message ? (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.title?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Description <small>(Content Required 150 words*)</small>
                      </label>
                      <CKEditor
                        id="full-featured-non-premium"
                        editor={ClassicEditor}
                        //   data={text}
                        // onChange={(event, editor) => {
                        //   const data = editor.getData();
                        //   setText(data);
                        // }}
                        //   onChange={(event, editor) => {
                        //     setValue("input", editor.getData());
                        //     trigger("input");
                        //   }}
                        data={postUpdateValue?.description}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          console.log(data);
                          setValue("description", data);
                        }}
                      />
                      {/* <p>{parse(text)}</p> */}
                    </div>
                  </div>
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Upload Images{" "}
                        <small>(you can upload multiple images here*)</small>
                      </label>
                      {/* <Dropzone onDrop={onDrop} /> */}
                      <CustomCropper
                        mutate={mutateAsync}
                        onSuccess={(res) => {
                          setImages([...images, res]);
                        }}
                        post={true}
                      />
                      <ShowImage
                        onRemoveImage={onRemoveImage}
                        images={images}
                        uploadStatus={uploadStatus}
                      />
                    </div>
                  </div>
                  {/* <div className="mb-3 add-to-post">
          <h6 className="mb-0">Add to your post</h6>
          <ul>
          
            <li>
              <button type="button">
                <i className="fa-solid fa-location-dot" />
              </button>
            </li>
            <li>
              <button type="button">
                <i className="fa-solid fa-user-tag" />
              </button>
            </li>
          </ul>
        </div> */}
                  {/* <div className="mb-3">
                  <div className="post-input">
                    <label>Number of Rooms</label>
                    <input
                      className="form-control"
                      placeholder="Rooms"
                      name="pages"
                      type="number"
                      min={1}
                      {...register("pages", {
                        valueAsNumber: true,
                      })}
                    />
                    {errors?.pages?.message ? (
                      <div className="post-validation" style={{ color: "red" }}>
                        {errors?.pages?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div> */}

                  {/* property type */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Property Type{" "}
                        <small>
                          (is the property an apartment, house, or villa?*)
                        </small>
                      </label>
                      <select
                        type="select"
                        name="type"
                        {...register("type")}
                        className="form-select"
                      >
                        <option value={""} disabled>
                          Select type
                        </option>
                        {getInventory &&
                          getInventory?.data?.map((item) => (
                            <option
                              value={item?.type?.toLowerCase()}
                              key={item?.id}
                            >
                              {item?.type}
                            </option>
                          ))}
                      </select>
                      {errors?.type && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.type.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* property type */}

                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Add Property Address{" "}
                        <small>(where is the property located?*)</small>
                      </label>
                      {/* <input
              type="text"
              className="form-control"
              placeholder="Address"
              name="location"
              {...register("location", {
                required: "Please enter your address.",
              })}
            /> */}
                      <AddressAutoComplete
                        onChange={(value) => {
                          setAddress(value);
                          setValue("location", value?.completeAddress);
                        }}
                        latitude={state?.post?.latitude}
                        longitude={state?.post?.longitude}
                      />

                      {errors?.location?.message ? (
                        <div style={{ color: "red" }}>
                          {errors?.location?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Number of Bedrooms{" "}
                        <small>
                          (how many bedrooms does the property have?*)
                        </small>
                      </label>
                      <select
                        type="select"
                        name="pages"
                        {...register("pages")}
                        className="form-select"
                      >
                        <option value={""} disabled>
                          Select type
                        </option>
                        {getBedroom?.data?.map((item) => (
                          <option value={item?.bedrooms} key={item?.id}>
                            {item?.bedrooms}
                          </option>
                        ))}
                      </select>
                      {errors?.pages && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.pages.message}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* post property No of bathrooms container */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Number of Bathrooms{" "}
                        <small>
                          (how many bathrooms does the property have?*)
                        </small>
                      </label>
                      <select
                        type="select"
                        name="bathrooms"
                        {...register("bathrooms")}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select a value
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      {errors?.bathrooms && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.bathrooms.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* post property No of bathrooms container */}

                  {/* post property No of reception_rooms container */}

                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Number of Reception Rooms{" "}
                        <small>
                          (does the property have reception rooms?*)
                        </small>
                      </label>
                      <select
                        type="select"
                        name="reception_rooms"
                        {...register("reception_rooms")}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Select a value
                        </option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                      </select>
                      {errors?.reception_rooms && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.reception_rooms.message}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* post property No of reception_rooms container */}

                  {/* key features */}
                  <div className="features">
                    <label>Select Features</label>
                    <div className="post-input">
                      <div className="feature_container">
                        {features.map((item) => (
                          <div className="check_container" key={item.id}>
                            <input
                              type="checkbox"
                              value={item.value}
                              name="features"
                              className="radio-control"
                              {...register("features", {
                                required: "Select at least 1 feature",
                              })}
                              // onChange={sellChangeHandler}
                            />
                            <label className="check-lable">{item.label}</label>
                          </div>
                        ))}
                      </div>
                      {/* <TagInputField
                        setTags={setFeatures}
                        tags={features}
                        placeholder={"Enter a feature"}
                      /> */}
                      {errors?.features && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.features.message}
                        </div>
                      )}
                    </div>
                  </div>
                  {/* key features */}

                  {/* key amenities */}
                  <div className="amenities">
                    <label>Select Amenities</label>
                    <div className="post-input">
                      <div className="feature_container">
                        {amenities.map((item) => (
                          <div className="check_container" key={item.id}>
                            <input
                              type="checkbox"
                              value={item.value}
                              name="amenities"
                              className="radio-control"
                              {...register("amenities", {
                                required: "Select at least 1 amenitie",
                              })}
                              // onChange={sellChangeHandler}
                            />
                            <label className="check-lable">{item.label}</label>
                          </div>
                        ))}
                      </div>
                      {errors?.amenities && (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.amenities.message}
                        </div>
                      )}
                      {/* <TagInputField
                        setTags={setFeatures}
                        tags={features}
                        placeholder={"Enter a feature"}
                      /> */}
                    </div>
                  </div>
                  {/* key amenities */}

                  {/* keywords */}

                  <div>
                    <div className="post-input">
                      <label>
                        Add Some Tags{" "}
                        <small>
                          (tags will help people find the property they want
                          faster!)
                        </small>
                      </label>
                      <TagInputField setTags={setTags} tags={tags} />
                    </div>
                  </div>
                  {/* keywords */}

                  {/* payment type */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>
                        Payment Type <small>(is this for sale or rent?*)</small>
                      </label>
                      <div className="radio_control_container">
                        <div className="radio_container">
                          <input
                            type="radio"
                            value="1"
                            name="sell_option"
                            checked={saleOption === true}
                            className="radio-control"
                            onChange={sellChangeHandler}
                          />
                          <label className="radio-lable">Buy</label>
                        </div>
                        <div className="radio_container">
                          <input
                            type="radio"
                            value="0"
                            name="sell_option"
                            checked={saleOption === false}
                            className="radio-control"
                            onChange={sellChangeHandler}
                          />
                          <label className="radio-lable">Rent</label>
                        </div>
                      </div>

                      {errors?.sell_option?.message ? (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.sell_option?.message}
                        </div>
                      ) : (
                        ""
                      )}

                      {saleOption === true ? (
                        <>
                          <div className="radio_control_container pt-2">
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Payment Plan"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">
                                Payment Plan
                              </label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Morgage"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Morgage</label>
                            </div>

                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Cash"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Cash</label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Other"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Other</label>
                            </div>
                          </div>
                        </>
                      ) : saleOption === false ? (
                        <>
                          <div className="radio_control_container pt-2">
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Weekly"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Weekly</label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Quaterly"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Quaterly</label>
                            </div>

                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Monthly"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Monthly</label>
                            </div>
                            <div className="radio_container">
                              <input
                                type="radio"
                                value="Yearly"
                                name="payment_type"
                                className="radio-control"
                                {...register("payment_type", {
                                  required: "Please select an option",
                                })}
                              />
                              <label className="radio-lable">Yearly</label>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* payment type */}

                  {/* finishing */}
                  <div className="mb-3">
                    <div className="post-input">
                      <label>Touching</label>
                      <div className="radio_control_container">
                        <div className="radio_container">
                          <input
                            type="radio"
                            value="1"
                            name="furnished"
                            className="radio-control"
                            {...register("furnished", {
                              required: "Please select a furnished",
                            })}
                          />
                          <label className="radio-lable">Furnished</label>
                        </div>

                        <div className="radio_container">
                          <input
                            type="radio"
                            value="0"
                            name="furnished"
                            className="radio-control"
                            {...register("furnished", {
                              required: "Please select a furnished",
                            })}
                          />
                          <label className="radio-lable">Unfinished</label>
                        </div>
                      </div>
                      {errors?.furnished?.message ? (
                        <div
                          className="post-validation"
                          style={{ color: "red" }}
                        >
                          {errors?.furnished?.message}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* finishing */}

                  <div className="post-input mb-3">
                    <label>Add Property Price</label>
                    <InputGroup className="mb-3">
                      {/* <InputGroup.Text id="basic-addon1">$</InputGroup.Text> */}
                      <Form.Select
                        style={{ flex: 0.1 }}
                        id="basic-addon1"
                        aria-label="Default select example"
                        value={selectedCurrency}
                        onChange={(event) => {
                          const { value } = event.target;
                          setSelectedCurrency(value);
                        }}
                      >
                        <option disabled>Select Currency</option>
                        {getCurrency?.data?.map((item) => (
                          <option value={item?.currency_symbol} key={item?.id}>
                            {item?.currency_code} {item?.currency_symbol}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control
                        placeholder="Price"
                        aria-label="Price"
                        aria-describedby="basic-addon1"
                        type="number"
                        name="price"
                        min={1}
                        {...register("price", {
                          valueAsNumber: true,
                        })}
                      />
                    </InputGroup>
                    {errors?.price?.message ? (
                      <div className="post-validation" style={{ color: "red" }}>
                        {errors?.price?.message}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="mb-3 text-end">
                    <button
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      className="btn btn-common"
                      disabled={isPostLoading}
                    >
                      {state?.post ? "Update" : "Create"}
                    </button>
                    {/* <div
                      className="btn btn-common"
                      onClick={() => {
                        setShowModal(!showModal);
                      }}
                    >
                      Create
                    </div> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>

      {showModal && (
        <MailboxModal
          setShowModal={setShowModal}
          showModal={showModal}
          userId={auth?.userId.toString()}
          postType={"post_created"}
        />
      )}
    </>
  );
};

export default CreatePost;
