import { useCallback, useState } from "react";
import moment from "moment";
import useNotification from "./useNotification";
import UserImage from "../../assets/images/empty-box.png";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import LoadingSpinner from "../../components/messageModal/LoadingSpinner";
import { Link } from "react-router-dom";
import Avatar from "../../components/image/Avatar";
import NotificationCard from "./NotificationCard";
import { scrollTop } from "helpers";
import PaginationComp from "components/pagination";

const Notification = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, personalNotification, generalNotification } =
    useNotification({
      currentPage: currentPage,
    });

  // console.log( personalNotification, generalNotification );
  const [tabIndex, setTabIndex] = useState(1);
  const getUserUrl = ({ forename, surName, userId }) => {
    const fullName = forename + " " + surName;
    const userProfileUrl = fullName.concat("_", userId);
    return userProfileUrl;
  };

  // const nextPage = useCallback(() => {
  //   scrollTop();
  //   if (personalNotification?.data?.next) {
  //     setCurrentPage(currentPage + 1);
  //   }
  // }, [currentPage, personalNotification?.data]);

  // const previousPage = useCallback(() => {
  //   scrollTop();

  //   if (personalNotification?.data?.previous) {
  //     setCurrentPage(currentPage - 1);
  //   }
  // }, [currentPage, personalNotification?.data]);
  return (
    <div>
      <div>
        <ul className="breadcrumb">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/settings/details"> Settings</a>
          </li>
          <li>
            <a href="/" className="active">
              {" "}
              Notification
            </a>
          </li>
        </ul>
      </div>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
          setCurrentPage(1);
        }}
      >
        <TabList>
          <Tab>Global Notification</Tab>
          <Tab>Personal Notification</Tab>
        </TabList>
        <TabPanel>
          <div className="box-shadow p-0">
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <LoadingSpinner size={18} />
              </div>
            ) : generalNotification?.data?.results?.length === 0 ? (
              <div div className="box-shadow">
                No Data Found
              </div>
            ) : (
              <div className="notification notification-listview">
                <ul className="p-3">
                  {/* {console.log({ generalNotification })} */}
                  {generalNotification?.data?.results?.map((item, index) => (
                    <NotificationCard key={index + ""} item={item} />
                  ))}
                  {(generalNotification?.data?.previous ||
                    generalNotification?.data?.next) && (
                    <PaginationComp
                      currentPage={currentPage}
                      data={generalNotification?.data}
                      previous={generalNotification?.data?.previous}
                      next={generalNotification?.data?.next}
                      scroll={scrollTop}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </ul>
              </div>
            )}
          </div>
        </TabPanel>
        <TabPanel>
          <div className="box-shadow p-0">
            {isLoading ? (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "50vh" }}
              >
                <LoadingSpinner size={18} />
              </div>
            ) : personalNotification?.data?.results?.length === 0 ? (
              <div div className="box-shadow">
                No Data Found
              </div>
            ) : (
              <div className="notification notification-listview">
                <ul className="p-3">
                  {personalNotification?.data?.results?.map((item, index) => (
                    <NotificationCard key={index + ""} item={item} />
                  ))}
                </ul>

                {(personalNotification?.data?.previous ||
                  personalNotification?.data?.next) && (
                  <PaginationComp
                    currentPage={currentPage}
                    data={personalNotification?.data}
                    previous={personalNotification?.data?.previous}
                    next={personalNotification?.data?.next}
                    scroll={scrollTop}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </div>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Notification;
