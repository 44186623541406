import React from "react";
import PricingCard from "../pricingCard/PricingCard";
import PricingCardMonthly from "../pricingCard/PricingCardMonthly";
import success from "../../assets/images/check.png";
import rocket from "../../assets/images/rocket.png";
import whiteRocket from "../../assets/images/white-rocket.png";
const PricingPlan = ({data}) => {
  return (
    <div className="pricing-sec">
        <p>you</p>
      <h1 className="sec-heading">
        Connecting you to
        <br />
        The <span> right </span> home!
      </h1>

      <div className="pricing-card-container">
        <PricingCard />
        <PricingCardMonthly />
      </div>

      <table className="services-table">
        <thead>
          <tr className="table-row">
            <td>Features</td>
            <td></td>
            <td>Included (All Plans)</td>
          </tr>
        </thead>
        <tbody>
          <tr className="table-row">
            <td>
              CRM Integration (Alto, Reapit, Apex, 10 Ninety, Jupix & many more)
            </td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Advertise Rooms & Properties</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Lead Generation</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Email Campaigns</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Chat (WhatsApp style)</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Notifiations (In app & Email)</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Document Upload (Contracts, Tenancy Agreements & many more)</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Document Management (Sign Contracts, Tenancy Agreements & many more) (Cs)</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Portfolio Management</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Inventory Management</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Tenancy Management</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
          <tr className="table-row">
            <td>Real Time Dashboards & Metrics</td>
            <td>
              {/* <img src={success} alt="service" className="card-icon" /> */}
            </td>
            <td>
              <img src={success} alt="service" className="card-icon" />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="talk-sec">
        <h2><strong>Renters</strong> and <strong>Landlords</strong> love Sociallocket</h2>
        <p>
          Campanies like <strong>Binghatti</strong> and <strong>Vincitore</strong> are selling and renting more using <strong>Sociallocket</strong>
        </p>
        <div className="talk-btn-container">
          <button className="btn btn-talk">
            <img src={whiteRocket} alt="rocket" className="card-icon" />
            <span>
              <a className="nav-link" href="tel:+4402034881143">
                Enterprise Sociallocket              
              </a>
            </span>
          </button>
          <button className="btn btn-talk-outline">
            <a className="nav-link" href="tel:+4402034881143">
              Talk to sales
            </a>
          </button>
        </div>
      </div>
      <div className="discount-sec">
        <h2>
          <a className="nav-link" href="https://buy.stripe.com/9AQ9DAg9n1zpbSw5km">
            First month for <strong>£149?</strong>
          </a>
        </h2>
        <p>
          Get your first month for{" "}
          <b style={{ textTransform: "uppercase", color: "#00c8c8" }}>
          only £149 
          </b>{" "}
          with the following discount code:
        </p>
        <button className="btn disc-btn">
          <span>"BestLettingAgency"</span>
          <span>
            <a className="nav-link" href="https://buy.stripe.com/9AQ9DAg9n1zpbSw5km">
              First month for only £149  
            </a>
          </span>
        </button>
      </div>
    </div>
  );
};

export default PricingPlan;
