import { useState } from "react";
import "./index.css";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";
import Apex27 from "../../assets/images/apex27.png";
import TenNinty from "../../assets/images/10Ninety.png";
import ModalComponent from "components/modalComponent/ModalComponent";

const Integrations = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div>
        <div>
          <ul className="breadcrumb">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/settings/details"> Settings</a>
            </li>
            <li>
              <a href="/" className="active">
                {" "}
                Integrations
              </a>
            </li>
          </ul>
        </div>

        <div className="box-shadow">
          <div className="integration_container">
            {/* facebook integration */}
            <div className="integration_comp">
              {/* <div className="integration_icon"> */}
              <span className="icon facebook-bg">
                <FaFacebookF />
              </span>
              {/* </div> */}
              <input className="form-control" />
              <button className="btn btn-common" onClick={
                () => {
                  setIsOpen(true);
                }
              
              }>connect</button>
            </div>
            {/* facebook integration */}

            {/* linkedin integration */}
            <div className="integration_comp">
              {/* <div className="integration_icon"> */}
              <span className="icon linkedin-bg">
                <FaLinkedinIn />
              </span>
              {/* </div> */}
              <input className="form-control" />
              <button className="btn btn-common">connect</button>
            </div>
            {/* linkedin integration */}

            {/* instagram integration */}
            <div className="integration_comp">
              {/* <div className="integration_icon"> */}
              <span className="icon instagram-bg">
                <BsInstagram />
              </span>
              {/* </div> */}
              <input className="form-control" />
              <button className="btn btn-common">connect</button>
            </div>
            {/* instagram integration */}

            {/* apex27 integration */}
            <div className="integration_comp">
              {/* <div className="integration_icon"> */}
              <span className="icon apex-bg">apex27</span>
              {/* </div> */}
              <input className="form-control" />
              <button className="btn btn-common">connect</button>
            </div>
            {/* apex27 integration */}

            {/* 10ninty integration */}
            <div className="integration_comp">
              {/* <div className="integration_icon"> */}
              <span className="icon tenninty-bg">10ninty</span>
              {/* </div> */}
              <input className="form-control" />
              <button className="btn btn-common">connect</button>
            </div>
            {/* 10ninty integration */}
          </div>
        </div>
      </div>
      <ModalComponent
        show={isOpen}
        onHide={() => {
          setIsOpen(false);
        }}
        size="xs"
        heading="Connection status"
      ></ModalComponent>
    </>
  );
};

export default Integrations;
