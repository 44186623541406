import { yupResolver } from "@hookform/resolvers/yup";
import LoadingSpinner from "components/messageModal/LoadingSpinner";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "components/messageModal/MessageModal";
import { AuthContext } from "context/authContext";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { API } from "services/ApiClient";
import Swal from "sweetalert2";
import * as yup from "yup";
const schema = yup.object().shape({
  number_of_children: yup
    .number()
    .typeError("number_of_children must be a number")
    .required("number_of_children is required")
    .positive("please enter the valid number")
    .moreThan(-1, "number_of_children must be positive"),
  nationality: yup.string().required("Nationality is required"),
  occupation: yup.string().required("occupation is required"),
  smoker: yup.string().required("smoker is required"),
  pets: yup.string().required("pets is required"),
  vegetarian: yup.string().required("vegetarian is required"),
  pescatarian: yup.string().required("pescatarian is required"),
});
const Interest = ({ userPersonaData, isUserPersonaLoading }) => {
  const [vegetarian, setVegetarian] = useState(null);
  const { auth } = useContext(AuthContext);

  const {
    register,
    handleSubmit,

    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const handleVegetarianChange = (e) => {
    // const value = e.target.value === "1";
    // setVegetarian(value);
    // let pescatarian = value === 1 ? 0 : 1;
    // setValue("pescatarian", pescatarian);
    const value = e.target.value === "1";
    setValue("vegetarian", value ? "1" : "0");
    setValue("pescatarian", value ? "0" : "1");
  };

  const handlePescatarianChange = (e) => {
    // const value = e.target.value === "1";
    // setVegetarian(!value);
    // let vegetarian = value === 1 ? 0 : 1;
    // setValue("vegetarian", vegetarian);
    const value = e.target.value === "1";
    setValue("vegetarian", value ? "0" : "1");
    setValue("pescatarian", value ? "1" : "0");
  };

  useEffect(() => {
    setValue("number_of_children", userPersonaData?.number_of_children);
    setValue("nationality", userPersonaData?.nationality);
    setValue("occupation", userPersonaData?.occupation);
    setValue("smoker", userPersonaData?.smoker);
    setValue("pets", userPersonaData?.pets);
    setValue("vegetarian", userPersonaData?.vegetarian);
    setValue("pescatarian", userPersonaData?.pescatarian);
 
  }, [userPersonaData, setValue]);
  const queryClient = useQueryClient();

  const { mutate: saveUserPersona, isLoading: isupdatePersonaLoading } =
    useMutation(
      async (payload) => {
        const res = await API.put("/user-personas/:id", payload);
        return res.data;
      },
      {
        onSuccess: (data) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Interest updated successfully",
            showConfirmButton: false,
            timer: 1500,
          });
          if (data) {
            queryClient.invalidateQueries(["user-persona", auth.userId]);
          }
        },
        onError: (error) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error?.response?.data?.message,
            showConfirmButton: false,
            timer: 1500,
          });
        },
      }
    );

  const onSubmit = async (data) => {
    try {
      showLoadingSpinner({});
      const obj2 = {
        // id: 1,
        // user_id: 1,

        user_id: auth?.userId ? auth.userId.toString() : "",
        id: auth?.userId ? auth.userId.toString() : "",
        number_of_children: data?.number_of_children ?? "",
        nationality: data?.nationality ?? "",
        occupation: data?.occupation ?? "",
        smoker: data?.smoker ?? "",
        pets: data?.pets ?? "",
        vegetarian: data?.vegetarian ?? "",
        pescatarian: data?.pescatarian ?? "",
      };

      console.log("obj2", obj2);

      saveUserPersona(obj2);
      hideLoadingSpinner({});
    } catch (error) {
      console.log("error", error);
      hideLoadingSpinner({});
    }
  };

  return isUserPersonaLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <h3 className="mb-4">Update Your Interest</h3>
      <form className="row g-3">
        <div className="col-md-6">
          <div className="interest-input">
            <label htmlFor="children" className="form-label">
              Please enter number of children
            </label>
          

          <select
            type="select"
            name="number_of_children"
            {...register("number_of_children")}
            className="form-select"
          >
            <option value="" disabled>Select a value</option>
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
          </select>
          {errors?.number_of_children && (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.number_of_children.message}
            </div>
          )}
          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="nationality" className="form-label">
            Please enter nationality
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter nationality"
            name="nationality"
            {...register("nationality")}
          />
          {errors?.nationality?.message ? (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.nationality?.message}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-6">
          <label htmlFor="occupation" className="form-label">
            Please enter occupation
          </label>
          <input
            type="text"
            className="form-control"
            placeholder="Enter occupation..."
            name="occupation"
            {...register("occupation")}
          />
          {errors?.occupation?.message ? (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.occupation?.message}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-12">
          <label htmlFor="smoker" className="form-label">
            Please enter smoker
          </label>
          <div className="radio_control_container">
            <div className="radio_container">
              <input
                type="radio"
                value="1"
                name="smoker"
                className="radio-control"
                {...register("smoker", {
                  required: "Please select an option",
                })}
              />
              <label className="radio-lable">Yes</label>
            </div>

            <div className="radio_container">
              <input
                type="radio"
                value="0"
                name="smoker"
                className="radio-control"
                {...register("smoker", {
                  required: "Please select an option",
                })}
              />
              <label className="radio-lable">No</label>
            </div>
          </div>
          {errors?.smoker?.message ? (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.smoker?.message}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-md-12">
          <label htmlFor="pets" className="form-label">
            Please enter pets
          </label>
          <div className="radio_control_container">
            <div className="radio_container">
              <input
                type="radio"
                value="1"
                name="pets"
                className="radio-control"
                {...register("pets", {
                  required: "Please select an option",
                })}
              />
              <label className="radio-lable">Yes</label>
            </div>

            <div className="radio_container">
              <input
                type="radio"
                value="0"
                name="pets"
                className="radio-control"
                {...register("pets", {
                  required: "Please select an option",
                })}
              />
              <label className="radio-lable">No</label>
            </div>
          </div>

          {errors?.pets?.message ? (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.pets?.message}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12">
          <label htmlFor="vegetarian" className="form-label">
            Please enter vegetarian
          </label>
          <div className="radio_control_container">
            <div className="radio_container">
              <input
                type="radio"
                value="1"
                name="vegetarian"
                className="radio-control"
                {...register("vegetarian", {
                  required: "Please select an option",
                })}
                // checked={vegetarian === true}
                onChange={handleVegetarianChange}
              />
              <label className="radio-lable">Yes</label>
            </div>

            <div className="radio_container">
              <input
                type="radio"
                value="0"
                name="vegetarian"
                className="radio-control"
                {...register("vegetarian", {
                  required: "Please select an option",
                })}
                // checked={vegetarian === false}
                onChange={handleVegetarianChange}
              />
              <label className="radio-lable">No</label>
            </div>
          </div>

          {errors?.vegetarian?.message ? (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.vegetarian?.message}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-md-12">
          <label htmlFor="pescatarian" className="form-label">
            Please enter pescatarian
          </label>
          <div className="radio_control_container">
            <div className="radio_container">
              <input
                type="radio"
                value="1"
                name="pescatarian"
                className="radio-control"
                {...register("pescatarian", {
                  required: "Please select an option",
                })}
                // checked={vegetarian === false}
                onChange={handlePescatarianChange}
              />
              <label className="radio-lable">Yes</label>
            </div>

            <div className="radio_container">
              <input
                type="radio"
                value="0"
                name="pescatarian"
                className="radio-control"
                {...register("pescatarian", {
                  required: "Please select an option",
                })}
                // checked={vegetarian === true}
                onChange={handlePescatarianChange}
              />
              <label className="radio-lable">No</label>
            </div>
          </div>

          {errors?.pescatarian?.message ? (
            <div className="interest-validation" style={{ color: "red" }}>
              {errors?.pescatarian?.message}
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            type="button"
            className="btn btn-common"
            disabled={isupdatePersonaLoading || isUserPersonaLoading}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default Interest;
