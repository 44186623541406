import { in200s } from "helpers";
import { API } from "./ApiClient";

export function paymentConfirmation(user_id) {
    return API.put(`payment-confirmation/42irunefin094rr0nie21dwm0/${user_id} `)
      .then((response) => {
        
        if (in200s(response.status)) {
          return response;
        }
        return null;
      })
      .catch((error) => error.response);
  }