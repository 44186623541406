import { useContext } from "react";
import { AuthContext } from "../../../context/authContext";
import { useQuery } from "react-query";
import {
    checkUserAccountType
} from "../../../services/notificationApi";


function useCheckAccountType() {

  const { auth } = useContext(AuthContext);
  const user_id = auth?.userId ? auth?.userId.toString() : "";
  return useQuery(
    [ user_id],
    () =>
      // type === "myLikes" ? getUserListing(1) : getCommentsList(user_id),
     checkUserAccountType(user_id)
        
    ,
  );
}

export default useCheckAccountType;
