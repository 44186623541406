import moment from "moment";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  isNonEmptyString,
  parseStringArray,
  removeQuestionAndForwardSlash,
  removeWhitespaces,
} from "../../../helpers";
import Badge from "react-bootstrap/Badge";
import Image from "../../../components/image/Image";
import { FaPhoneAlt } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { TiMessages } from "react-icons/ti";

import bathroom from "../../../assets/images/bathroom.png";
import bedroom from "../../../assets/images/bedroom.png";
import reception from "../../../assets/images/reception.png";



import { useMutation } from "react-query";
import { manageMatchedListing } from "services/notificationApi";
import {
  hideLoadingSpinner,
  showLoadingSpinner,
} from "components/messageModal/MessageModal";
import Swal from "sweetalert2";
import { AuthContext } from "context/authContext";
import ModalComponent from "components/modalComponent/ModalComponent";
import { getUserFullName } from "utils/Storage";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useDeletePost from "pages/createPost/useDeletePost";
import useDeleteWantedProperty from "./useDeleteWantedProperty";

const ActivityCard = ({ item, type, tabIndex, setActivity, isBlur }) => {
 

  
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [phoneData, setPhoneData] = useState(null);
  const navigate = useNavigate();

  const { auth } = useContext(AuthContext);

  const FullName =
    isNonEmptyString(item?.forename) && isNonEmptyString(item?.surname)
      ? `${item?.forename}  ${item?.surname}`
      : "";
  const userProfileUrl = FullName.concat("_", item?.user_id);
  const titleLink = removeWhitespaces(
    removeQuestionAndForwardSlash(
      (item?.post_id || item?.property_wanted_id) + "".concat("_", item?.title)
    )
  );
  const { mutate: updateMutate } = useMutation(manageMatchedListing, {
    onMutate: () => showLoadingSpinner({}),
    onSuccess: async (res, variables) => {
      const action = variables?.data?.action;
      if (action === "Phone" || action === "Message") {
        setActivity(null);
        navigate(`/profile/${removeWhitespaces(userProfileUrl)}`, {
          replace: true,
        });
      }

      if (action === "Delete") {
        Swal.fire({
          title: "Success",
          text: res?.data?.message,
          icon: "success",
          confirmButtonText: "Ok",
        }).then(() => {
          setActivity(null);
          navigate(`/profile/${removeWhitespaces(userProfileUrl)}`, {
            replace: true,
          });
        });
      }
    },
    onError: (err, data) => {
      Swal.fire({
        title: "Update Listing",
        text: err?.response?.data?.message,
        icon: "error",
      });
    },
    onSettled: () => hideLoadingSpinner(),
  });

  const handleActionClick = (action) => {
    const user_id = auth?.userId ? auth?.userId.toString() : "";
    const data = {
      // id: 1,
      id: item.id,
      user_id: user_id,
      property_wanted_user_id: item.property_wanted_user_id,
      action: action,
      accepted: "1",
      post_id: item.post_id,
      property_wanted_id: item.property_wanted_id,
    };
    updateMutate({
      user_id: user_id,
      data: data,
    });
  };

  const customTooltipStyle = {
    padding: "5px",
    margin: "0",
  };

  const { mutate: deleteWantedProperty } = useDeleteWantedProperty(
    item?.user_id
  );

  return (
    <>
      <li>
        <div className="user-post">
          {isBlur ? (
            <Link to={"/"} className="active cursor-pointer">
              <figure>
                <Image
                  imageClassName="img-fluid"
                  src={isBlur ? null : parseStringArray(item?.images)?.[0]}
                  imageStyle={{
                    height: 220,
                  }}
                  errorImageStyle={{
                    objectFit: "contain",
                  }}
                />
              </figure>
            </Link>
          ) : (
            <Link
              to={
                type === "myPropertWanted"
                  ? `/property-wanted/${titleLink}`
                  : type === "matchedPropertyWanted" && Number(tabIndex) === 1
                  ? `/property-matched/${titleLink}`
                  : type === "Chain" && Number(tabIndex) === 0
                  ? `/property-matched/${titleLink}`
                  : `/property/${titleLink}`
              }
              className="active cursor-pointer"
              state={{
                property_wanted_id:
                  type === "matchedPropertyWanted" && item?.property_wanted_id
                    ? item?.property_wanted_id
                    : null,
              }}
              style={{ cursor: "pointer" }}
            >
              <figure>
                <Image
                  imageClassName="img-fluid"
                  src={parseStringArray(item?.images)?.[0]}
                  imageStyle={{
                    height: 220,
                  }}
                  errorImageStyle={{
                    objectFit: "contain",
                  }}
                />
              </figure>
            </Link>
          )}

          {isBlur ? (
            <figcaption>
              <h5>dummy title</h5>

              <div className="d-flex flex-column gap-lg-2 gap-1 ">
                <div className="flex justify-content-between align-items-center">
                  <div>
                    <Badge bg="success">12</Badge>
                  </div>
                  <div>
                    <ul className="post-rooms">
                      {item?.pages && (
                        <li className="post-room">
                          {/* <h5>Number of Rooms</h5> */}
                          <div className="d-flex align-items-end">
                            <img
                              src={bedroom}
                              alt="bedroom"
                              className="post-icon"
                            />
                            <span style={{ color: "black" }}>dummy</span>
                          </div>
                        </li>
                      )}
                      {item?.bathrooms && (
                        <li className="post-room">
                          {/* <h5>Number of Bathrooms</h5> */}
                          <div className="d-flex align-items-end">
                            <img
                              src={bathroom}
                              alt="bathroom"
                              className="post-icon"
                            />
                            <span style={{ color: "black" }}>dummy</span>
                          </div>
                        </li>
                      )}
                      {item?.reception_rooms && (
                        <li className="post-room">
                          {/* <h5>Number of Reception Rooms</h5> */}
                          <div className="d-flex align-items-end">
                            <img
                              src={reception}
                              alt="bedroom"
                              className="post-icon"
                            />
                            <span style={{ color: "black" }}>dummy</span>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="flex-shrink-0 dropdown listing-manage">
                  {/* chain  */}
                  {((type === "matchedPropertyWanted" &&
                    Number(tabIndex) === 1) ||
                    (type === "Chain" && Number(tabIndex) === 0)) && (
                    <div className="icon-container">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>Call</Tooltip>
                        }
                      >
                        <Link
                          to={`${isBlur ? "/dummy" : `tel:${item?.mobile}`}`}
                          target="_blank"
                          style={{ width: "auto", borderRadius: "0.25rem" }}
                          // className="phone post-icon item-center"
                          className="btn btn-warning btn-sm me-2"
                        >
                          <i
                            className="fa-solid fa-phone"
                            style={{ color: "white" }}
                          ></i>{" "}
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>Message</Tooltip>
                        }
                      >
                        <span className="btn btn-common btn-sm me-2">
                          {/* <TiMessages /> */}
                          <i class="fa-solid fa-message"></i>
                        </span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>Delete</Tooltip>
                        }
                      >
                        <span
                          // className="delete post-icon item-center"
                          className="btn btn-danger btn-sm"
                        >
                          {/* <MdDeleteOutline /> */}
                          <i className="fa fa-trash"></i>
                        </span>
                      </OverlayTrigger>
                    </div>
                  )}
                  {/* chain  */}

                  {type === "myPropertWanted" && (
                    <div className="icon-container">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>
                            Edit Property
                          </Tooltip>
                        }
                      >
                        <Link to="/" className="btn btn-common btn-sm me-2">
                          <i className="fa fa-edit"></i>{" "}
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>
                            Delete Property
                          </Tooltip>
                        }
                      >
                        <button className="btn btn-danger btn-sm">
                          <i className="fa fa-trash"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  )}
                  <span>dummy</span>
                </div>
              </div>
            </figcaption>
          ) : (
            <figcaption>
              <p className="mb-0">
                {/* <strong>You</strong>{" "}
            {!!item?.comment ? "commented on" : "liked on"}{" "}
            <strong>
              {item?.comment || item?.forename}
              {""} {item?.comment || item?.surname}
            </strong>{" "}
            :{" "} */}
              </p>

              <h5>{item?.title}</h5>
              {type === "myComments" && (
                <h6 className="my-comments">
                  <small>
                    <strong>Your Comments:</strong> {item?.comment}
                  </small>
                </h6>
              )}
              <div className="d-flex flex-column gap-lg-2 gap-1 ">
                <div className="flex justify-content-between align-items-center">
                  <div>
                    <Badge bg="success">
                      {item?.comment || item?.price || item?.min_price?.trim(" ")}
                    </Badge>
                  </div>
                  <div>
                    <ul className="post-rooms">
                      {(item?.pages || item.bedrooms) && (
                        <li className="post-room">
                          {/* <h5>Number of Rooms</h5> */}
                          <div className="d-flex align-items-end">
                            <img
                              src={bedroom}
                              alt="bedroom"
                              className="post-icon"
                            />
                            <span style={{ color: "black" }}>
                              {item?.pages
                                ? item?.pages
                                : item?.bedrooms
                                ? item?.bedrooms
                                : ""}
                            </span>
                          </div>
                        </li>
                      )}
                      {item?.bathrooms && (
                        <li className="post-room">
                          {/* <h5>Number of Bathrooms</h5> */}
                          <div className="d-flex align-items-end">
                            <img
                              src={bathroom}
                              alt="bathroom"
                              className="post-icon"
                            />
                            <span style={{ color: "black" }}>
                              {item?.bathrooms ? item?.bathrooms : ""}
                            </span>
                          </div>
                        </li>
                      )}
                      {item?.reception_rooms && (
                        <li className="post-room">
                          {/* <h5>Number of Reception Rooms</h5> */}
                          <div className="d-flex align-items-end">
                            <img
                              src={reception}
                              alt="bedroom"
                              className="post-icon"
                            />
                            <span style={{ color: "black" }}>
                              {item?.reception_rooms
                                ? item?.reception_rooms
                                : ""}
                            </span>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>

                <div className="flex-shrink-0 dropdown listing-manage">
                  {/* chain  */}
                  {((type === "matchedPropertyWanted" &&
                    Number(tabIndex) === 1) ||
                    (type === "Chain" && Number(tabIndex) === 0)) && (
                    <div className="icon-container">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>Call</Tooltip>
                        }
                      >
                        <Link
                          to={`tel:${item?.mobile}`}
                          target="_blank"
                          style={{ width: "auto", borderRadius: "0.25rem" }}
                          // className="phone post-icon item-center"
                          className="btn btn-warning btn-sm me-2"
                          onClick={() => handleActionClick("Phone")}
                        >
                          <i
                            className="fa-solid fa-phone"
                            style={{ color: "white" }}
                          ></i>{" "}
                        </Link>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>Message</Tooltip>
                        }
                      >
                        <span
                          className="btn btn-common btn-sm me-2"
                          onClick={() => handleActionClick("Message")}
                        >
                          {/* <TiMessages /> */}
                          <i class="fa-solid fa-message"></i>
                        </span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>Delete</Tooltip>
                        }
                      >
                        <span
                          // className="delete post-icon item-center"
                          className="btn btn-danger btn-sm"
                          onClick={() => handleActionClick("Delete")}
                        >
                          {/* <MdDeleteOutline /> */}
                          <i className="fa fa-trash"></i>
                        </span>
                      </OverlayTrigger>
                    </div>
                  )}
                  {/* chain  */}

                  {type === "myPropertWanted" && (
                    <div className="icon-container">
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>
                            Edit Property
                          </Tooltip>
                        }
                      >
                        <Link
                          to="/update-wanted-listing"
                          className="btn btn-common btn-sm me-2"
                          state={{
                            post: item,
                            title: item?.title,
                          }}
                        >
                          <i className="fa fa-edit"></i>{" "}
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip style={customTooltipStyle}>
                            Delete Property
                          </Tooltip>
                        }
                      >
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            deleteWantedProperty({
                              id: item.id,
                              user_id: auth.userId.toString(),
                            })
                          }   
                        >
                          <i className="fa fa-trash"></i>
                        </button>
                      </OverlayTrigger>
                    </div>
                  )}
                  <span>
                    {!!item?.created && moment(item?.created).fromNow()}
                  </span>
                </div>
              </div>
            </figcaption>
          )}
        </div>
      </li>
      <ModalComponent
        show={!!showPhoneModal}
        onHide={() => {
          setShowPhoneModal(!showPhoneModal);
          setPhoneData(null);
        }}
        heading={
          <h4>
            <strong>Phone Number</strong>
          </h4>
        }
        size="xl"
      >
        <div>
          <p>
            <strong>Phone Number:</strong>
            <Link to={`tel:${phoneData}`} target="_blank">
              {phoneData}
            </Link>
          </p>
        </div>
      </ModalComponent>
    </>
  );
};

export default ActivityCard;
