// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tag-container {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding-left: 14px;
    border: 1px grey solid;
    border-radius: 5px;
    color: black
  }
  
  .tag-container input {
    width: 100%;
    min-width: 50%;
    border: none;
    outline: none;
    border-radius: 5px;
    padding: 14px;
    padding-left: 14px;
  }
  
  .tag {
    display: flex;
    align-items: center;
    margin: 7px 0;
    margin-right: 10px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid orange;
    border-radius: 5px;
    background-color: orange;
    white-space: nowrap;
    color: white;
  }
  
  .tag button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: white;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/tagInputField/tagInputStyles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB;EACF;;EAEA;IACE,WAAW;IACX,cAAc;IACd,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,wBAAwB;IACxB,kBAAkB;IAClB,wBAAwB;IACxB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,YAAY;EACd","sourcesContent":[".tag-container {\n    display: flex;\n    overflow: scroll;\n    width: 100%;\n    max-width: 100%;\n    padding-left: 14px;\n    border: 1px grey solid;\n    border-radius: 5px;\n    color: black\n  }\n  \n  .tag-container input {\n    width: 100%;\n    min-width: 50%;\n    border: none;\n    outline: none;\n    border-radius: 5px;\n    padding: 14px;\n    padding-left: 14px;\n  }\n  \n  .tag {\n    display: flex;\n    align-items: center;\n    margin: 7px 0;\n    margin-right: 10px;\n    padding: 0 10px;\n    padding-right: 5px;\n    border: 1px solid orange;\n    border-radius: 5px;\n    background-color: orange;\n    white-space: nowrap;\n    color: white;\n  }\n  \n  .tag button {\n    display: flex;\n    padding: 6px;\n    border: none;\n    background-color: unset;\n    cursor: pointer;\n    color: white;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
