import { in200s } from "helpers";
import { API } from "./ApiClient";

export function getMeIntegration(user_id) {
  return API.get(`integrations/${user_id}`)
    .then((response) => {
    

      if (in200s(response.status)) {
        return response.data?.result;
      }
      return null;
    })
    .catch((error) => {
      console.log("error", error.response);
      return error.response;
  });
}


export function deleteSingleIntegration(data){
  return API.delete(`integrations/${data.id}`, {
    headers: {
      "Content-Type": "application/json",
    },
    data : data
  
  
  })
  .then((response) => {
      if (in200s(response.status)) {
        return response.data;
      }
      return null;
    })
    .catch((error) => {
      console.log("error", error.response);
      return error.response;
  })
}