import React from "react";
import "./index.css";
import card from "../../assets/images/cards.png"
import PaymentPopUp from "../popUp/index";
const PaymentLogo = () => {
  const [popUpOpen, setPopUpOpen] = React.useState(false);
  //pop-up for payments (crypto, stripe and paypal)
  return (
    <>
    {/* <div
      className="payment_logo_container border-0"
      onClick={() => setPopUpOpen(!popUpOpen)}
    >
      <img src={card} alt="paymentlogo" />
    </div> */}
    {
        popUpOpen && <PaymentPopUp popUpOpen={popUpOpen} setPopUpOpen={setPopUpOpen}/>
    }
    </>

  );
};

export default PaymentLogo;
