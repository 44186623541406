import ComingSoon from "../comingSoon/ComingSoon";

/* eslint-disable react/no-unescaped-entities */
const Privacy = () => {
  return (
    <div>
      <ComingSoon />
      {/* <div className="box-shadow">
        <div className="privacy-policy p-3">
          <>
            <ComingSoon />
          </>
        </div>
      </div> */}
    </div>
  );
};

export default Privacy;
