/* eslint-disable react/jsx-no-useless-fragment */
import parse from "html-react-parser";
import { useContext, useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  formatDate,
  getInitials,
  isNonEmptyArray,
  isNonEmptyString,
  parseStringArray,
  removeQuestionAndForwardSlash,
  removeWhitespaces,
} from "../../helpers";
import logo from "../../assets/images/logo.png";
import { API } from "../../services/ApiClient";
import "../../styles/globalStyles.css";
import Comments from "../home/components/Comments";
import usePostDetails from "./usePostDetails";
import { PostDetailContext } from "./PostDetailContext";
import PostStatus from "pages/profile/components/postStatus/PostStatus";
import useGetVerification from "hooks/query/AllUserProfile/useGetVerification";
import { IoLocationOutline } from "react-icons/io5";
import PostDetail from "./postDetail";

const PostDetailMain = ({ isMatched }) => {
  const { state } = useLocation();

  const {
    postsDetailsData,
    navigate,
    isOpenForBuy,
    postId,
    auth,
    postsDetailsLoading,
  } = usePostDetails(isMatched);

  // console.log("postsDetailsData", postsDetailsData);
  return (
    <>
      {/* {isNonEmptyArray(postsDetailsData?.post) ||
      isNonEmptyArray(postsDetailsData?.wanted) ? (
        <>
          {postsDetailsData.post.map((item, idx) => (
            <PostDetail item={item} isMatched={isMatched} idx={idx} />
          ))}
        </>
      ) : (
        <> */}
      {postsDetailsLoading ? (
        <main id="layoutSidenav_content">
          <div
            className="post position-relative"
            style={{
              background: "#ffffff",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            loading...
          </div>
        </main>
      ) : isNonEmptyArray(postsDetailsData) ? (
        postsDetailsData.map((item, idx) => (
          <PostDetail item={item} isMatched={isMatched} idx={idx} />
        ))
      ) : (
        postsDetailsData?.length === 0 && (
          <main id="layoutSidenav_content">
            <div
              className="post position-relative"
              style={{
                background: "#ffffff",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No found
            </div>
          </main>
        )
      )}
      {/* </> */}
    </>
  );
};

export default PostDetailMain;
