import React, { memo, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import ModalComponent from "../modalComponent/ModalComponent";
import Dropzone from "../dropzone/Dropzone";
import CustomButton from "../customButton/CustomButton";
import Swal from "sweetalert2";
import imageCompression from "browser-image-compression";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { set } from "ramda";

function CustomCropper({ mutate, onSuccess,post }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const handleModalOpen = ()=>{
    if(uploadStatus  === false || isOpen===false){
      setIsOpen(!isOpen)
    }
  }
  const cropperRef = useRef(null);
  const [file, setFile] = useState(null);
  const onCrop = () => {
    const cropper = cropperRef.current?.cropper;
    // cropper.getCroppedCanvas().toBlob(async (blob) => {
    //   setFile(blob);
    // });
  };
  const compressImage = async (file) => {
    try {
      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1, // Set your desired maximum file size
      });
      return compressedFile;
    } catch (error) {
      console.error("Error compressing image: ", error);
      throw new Error("Error compressing image");
    }
  };
  const onSubmit = () => {
    if (file && cropperRef.current?.cropper) {
      const cropper = cropperRef.current?.cropper;
      setIsLoading(true);

      cropper.getCroppedCanvas().toBlob(async (blob) => {
        setIsLoading(true);
        setUploadStatus(true)
        mutate(blob, {
          onSuccess: (res) => {
            handleModalOpen();
            setUploadStatus(false);
            setFile(null);
            onSuccess && onSuccess(res);
          },
          onError: () =>
            Swal.fire({
              title: "Error",
              text: "Something went wrong! While file has been uploaded",
              icon: "error",
            }),
          onSettled: () => setIsLoading(false),
        });
      });
    }
  };

  const onDrop = async (acceptedFiles) => {
    console.log("acc", acceptedFiles[0]);
    console.log("leng", acceptedFiles[0].size);
    try {
      const compressedFile = await compressImage(acceptedFiles[0]);
      console.log("compressedFile", compressedFile);
      console.log("compressedFile", compressedFile.size);
      setFile(compressedFile);
      handleModalOpen();
      // setUploadStatus("uploading");
    } catch (error) {
      console.error("Error uploading image: ", error);
    }
  };
  return (
    <>
      <Dropzone onDrop={onDrop} post={post} />
      <ModalComponent
        show={isOpen}
        onHide={handleModalOpen}
        size="xs"
        heading="Upload Image"
      >
        {file && <CropFn cropperRef={cropperRef} file={file} />}
        <div className="d-flex justify-content-center mt-2">
          <CustomButton
            title={"Add Photo"}
            onClick={onSubmit}
            isLoading={isLoading}
          />
        </div>
      </ModalComponent>
    </>
  );
}

export default CustomCropper;

const CropFn = memo(({ file, cropperRef }) => {
  return (
    <Cropper
      key="crop-image"
      src={file ? URL.createObjectURL(file) : null}
      style={{ height: 400, width: "100%" }}
      initialAspectRatio={16 / 9}
      aspectRatio={16 / 9}
      guides={true}
      ref={cropperRef}
      draggable={false}
      scalable={false}
      viewMode={1}
      minCropBoxWidth={400}
      minCropBoxHeight={400}
      background={false}
      responsive={true}
      checkOrientation={false}
      cropBoxResizable={false}
      autoCrop={true}
    />
  );
});
