import moment from "moment";
import { isNonEmptyString } from "../../../helpers";
import { useQuery, useQueryClient } from "react-query";
import { getUserAddressById } from "../../../services/addressApi";
import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/authContext";
import { countryService } from "../../../services/CountryService";
import { useParams ,useNavigate} from "react-router-dom";
import { is } from "ramda";
import useConnectedUsers from "hooks/query/AllUserProfile/useAllConnectedUsersList";
import { API } from "services/ApiClient";

const UserBio = ({
  userDetailsData,
  isUserDetailsLoading,
  userDetailsError,
  isBlur,
}) => {
  const param = useParams();
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext);
  const userId = auth?.userId;
  const queryClient = useQueryClient();
  const [countryList, SetCountryList] = useState([]);
  const {
    isLoading: isConnectedUserListLoading,
    error: connectedUserListError,
    data: connectedUserListData,
  } = useConnectedUsers(userId);
  useEffect(() => {
    async function getCounties() {
      const countryData = await countryService.GetCountryCodes();
      SetCountryList(countryData);
    }
    getCounties();
  }, []);

  const { data, isLoading } = useQuery(
    [`address`, userId],
    () =>
      getUserAddressById({
        userId: Number(param.username.split("_")[1]),
      }),
    {
      select: (res) => {
        return res;
      },
    }
  );

  if (isUserDetailsLoading) {
    return (
      <div>
        <p>...Loading</p>
      </div>
    );
  }
  const onConnectClick = async (e) => {
    e.preventDefault();

    if (auth?.userId) {
      const connectPayload = {
        user_id: auth?.userId,
        users_members_id: userDetailsData?.id,
      };
      // mutate(connectPayload);
      try {
        const res = await API.post("users-members", connectPayload);
        await queryClient.invalidateQueries(["connect-user"]);
      } catch (error) {}
    } else {

      navigate("/login");
    }
  };
  const FollowButton = () => {
    let followUser = connectedUserListData?.find(
      (row) =>
        row?.users_members_id === userDetailsData?.id && row?.user_id === userId
    );

    return !!!followUser ? (
      <button
        type="button"
        className="btn btn-common btn-follow px-3 "
        onClick={onConnectClick}
        // disabled={
        //   isConnectLoading ||
        //   connectedUserListData.filter(
        //     (user) => user.users_members_id === user.id
        //   )
        // }
      >
        Add
      </button>
    ) : null;
  };

  return (
    // <div className={`profile-bio ${!isBlur ? "blur" : ""}`}>
    <div className={`profile-bio`}>
      <div className={`${!isBlur ? "blur" : ""}`}>
        <div className="show_onhover">{<FollowButton/>}</div>
      </div>
      {userDetailsError && <p> {userDetailsError} </p>}

      <div className="personal-information">
        <h3 className="mb-4">Information</h3>
        <ul>
          <li>
            <div className="personal-information-title">Full Name</div>
            <div className="">
              {isBlur ? (
                <>
                  {isNonEmptyString(userDetailsData?.forename)
                    ? `${userDetailsData?.forename}`
                    : ""}{" "}
                  {isNonEmptyString(userDetailsData?.surname)
                    ? `${userDetailsData?.surname}`
                    : ""}
                </>
              ) : (
                "dummy name"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Bio</div>
            <div className="">
              {isBlur ? (
                <>
                  {isNonEmptyString(userDetailsData?.bio)
                    ? `${userDetailsData?.bio}`
                    : ""}
                </>
              ) : (
                "dummy bio"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Email Id</div>
            <div className="">
              {isBlur ? (
                <>
                  {isNonEmptyString(userDetailsData?.email)
                    ? `${userDetailsData?.email}`
                    : ""}
                </>
              ) : (
                "dummy email"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Gender</div>
            <div className="">
              {isBlur ? (
                <>
                  {isNonEmptyString(userDetailsData?.gender)
                    ? `${userDetailsData?.gender}`
                    : ""}
                </>
              ) : (
                "gender"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Age</div>
            <div className="">
              {isBlur ? (
                <>
                  {userDetailsData?.dob
                    ? moment(userDetailsData?.dob, "mm/dd/yyyy")
                        .fromNow()
                        .split(" ")
                        ?.filter((item) => item !== "ago")
                        .join(" ")
                    : ""}
                </>
              ) : (
                "age"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Contant No.</div>
            <div className="">
              {isBlur ? (
                <>
                  {isNonEmptyString(userDetailsData?.mobile)
                    ? `${userDetailsData?.mobile}`
                    : ""}
                </>
              ) : (
                "dummy contact"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Main User Type</div>
            <div className="">
              {" "}
              {isBlur ? (
                <>
                  {isNonEmptyString(userDetailsData?.main_user_type)
                    ? `${userDetailsData?.main_user_type}`
                    : ""}
                </>
              ) : (
                "dummy main user type"
              )}
            </div>
          </li>
          <li>
            <div className="personal-information-title">Referral Code</div>
            <div className="">
              {isBlur ? (
                <>{userDetailsData?.referral_code ?? "-"}</>
              ) : (
                "dummy referral code"
              )}
            </div>
          </li>
        </ul>
      </div>

      {!!data?.length && (
        <div className="addrssess my-4">
          <div className="personal-information">
            <h3 className="mb-4">Address</h3>
            <div className="row">
              {data?.map((e) => {
                return (
                  <div
                    key={e?.id}
                    className="col-md-4 mb-3"
                    style={{
                      opacity: e?.user_address_status === "active" ? 1 : 0.5,
                    }}
                  >
                    <div className="card h-100">
                      <div className="card-body">
                        <div className="address-tag">
                          {e?.address_type === "Business"
                            ? "Primary"
                            : "Secondary"}
                        </div>
                        <span className="address-list">
                          <strong>Street no.</strong>{" "}
                          {isBlur ? <>{e?.street_number}</> : "dummy street"}
                          <br></br>
                          <strong>Address Line 1:</strong>{" "}
                          {isBlur ? <>{e?.address_line_1},</> : "dummy address"}
                          <br></br>
                          {isNonEmptyString(e?.address_line_2) ? (
                            <span>
                              <strong>Address Line 2: </strong>
                              {isBlur ? (
                                <>{e?.address_line_2},</>
                              ) : (
                                "dummy address"
                              )}
                            </span>
                          ) : (
                            ""
                          )}
                          {isBlur ? (
                            <>
                              {e.city}, {e.region}, {e?.postal_code},{" "}
                            </>
                          ) : (
                            "dummy city"
                          )}
                          {isBlur ? (
                            <>
                              {
                                countryList?.find(
                                  (el) => el?.id == e?.country_id
                                )?.nick_name
                              }{" "}
                            </>
                          ) : (
                            "dummy country"
                          )}
                        </span>
                        {/* <ul>
                            <li>
                              <div className="personal-information-title">
                                Unit Number:
                              </div>
                              <div className="">{e?.unit_number}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                Address Line 1:
                              </div>
                              <div className="">{e?.address_line_1}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                Address Line 2:
                              </div>
                              <div className="">{e?.address_line_2}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                Street:
                              </div>
                              <div className="">{e?.street_number}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                City:
                              </div>
                              <div className="">{e.city}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                Region:
                              </div>
                              <div className="">{e.region}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                Zip code:
                              </div>
                              <div className="">{e?.postal_code}</div>
                            </li>
                            <li>
                              <div className="personal-information-title">
                                Country:
                              </div>
                              <div className="">
                                {
                                  countryList?.find(
                                    (el) => el?.id == e?.country_id
                                  )?.nick_name
                                }{" "}
                              </div>
                            </li>
                          </ul> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserBio;
