import React from "react";
import usePostDetails from "../usePostDetails";
import {
  calculateAge,
  capitalizeFirstLetter,
  checkSubscriptionStatus,
  formatDate,
  formatOnlyDate,
  getInitials,
  isNonEmptyArray,
  isNonEmptyString,
  parseStringArray,
} from "helpers";
import { Link, useLocation } from "react-router-dom";
import useGetVerification from "hooks/query/AllUserProfile/useGetVerification";
import usePostsById from "hooks/query/Posts/usePostsById";
import bathroom from "../../../assets/images/bathroom.png";
import bedroom from "../../../assets/images/bedroom.png";
import reception from "../../../assets/images/reception.png";
import useCheckAccountType from "pages/profile/myActivity/useCheckAccountType";
import moment from "moment";

const PostDetailPostWanted = ({ isMatched }) => {
  const { state, pathname } = useLocation();

  const {
    isLoading: postsDetailsLoading,
    error: postsDetailsError,
    data: postsDetailsData,
  } = usePostsById(
    state?.property_wanted_id ? state?.property_wanted_id : null,
    pathname,
    isMatched
  );
  const {
    data: userDataType,
    isLoading: isUserTypeLoading,
    isError: isUserError,
  } = useCheckAccountType();

  console.log("postsDetailsData", postsDetailsData);
  const verificationBadge = useGetVerification(
    postsDetailsData?.[0]?.user_verification == "1"
  );
  // console.log("postsDetailsDatadfsdsdfsdfsdf", postsDetailsData);
  const isBlur = checkSubscriptionStatus(userDataType);

  return (
    <>
      {isNonEmptyArray(postsDetailsData)
        ? postsDetailsData?.map((item, idx) => (
            <div style={{ position: "relative" }}>
              <button className="show_onhover btn btn-common">Add</button>
              {isBlur && <div className="blur"></div>}
              <div className="matched-property-profile">
                <div className="d-flex align-items-center justify-content-center gap-2 btn-container">
                  <Link
                    to={`/profile/${item?.forename}${item?.surname}_${item.user_id}`}
                    className="btn btn-common"
                    style={{ width: "50%" }}
                  >
                    Chat
                  </Link>{" "}
                  <Link
                    to={`tel:${item.mobil}`}
                    target="_blank"
                    className="btn btn-common"
                    style={{ width: "50%" }}
                  >
                    Call
                  </Link>{" "}
                </div>
                <div className="personal-information" style={{ width: "100%" }}>
                  <h3 className="mb-4">Property matched</h3>
                  {isNonEmptyArray(postsDetailsData)
                    ? postsDetailsData.map((item, idx) => (
                        <div className="" key={idx}>
                          <Link
                            // to={`/profile/${removeWhitespaces(userProfileUrl)}`}
                            to={"/"}
                            className="post-profile"
                          >
                            <figure>
                              {verificationBadge}

                              {isNonEmptyString(item?.profile_image) ? (
                                <picture>
                                  <source
                                    srcSet={item?.profile_image}
                                    type="image/webp"
                                  />
                                  <source
                                    srcSet={item?.profile_image}
                                    type="image/png"
                                  />
                                  <img
                                    loading="lazy"
                                    src={item?.profile_image}
                                    data-src={item?.profile_image}
                                    alt="user-img"
                                    className="img-fluid"
                                    width={50}
                                    height={50}
                                  />
                                </picture>
                              ) : (
                                <span>
                                  {isNonEmptyString(item?.forename) &&
                                  isNonEmptyString(item?.surname)
                                    ? getInitials(
                                        `${item?.forename}  ${item?.surname}`
                                      )
                                    : ""}
                                </span>
                              )}
                            </figure>
                            <figcaption>
                              <h5 className="mb-0">
                                {isNonEmptyString(item?.forename) &&
                                isNonEmptyString(item?.surname)
                                  ? `${item?.forename}  ${item?.surname}`
                                  : ""}
                              </h5>
                            </figcaption>
                          </Link>
                        </div>
                      ))
                    : null}
                  <div className="property_match_data">
                    <div className="left-side">
                      <h3>Bio</h3>
                      {isNonEmptyString(item?.mobile) && (
                        <div className="property-wanted-title">
                          <h5>Phone Number</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.mobile) &&
                            isNonEmptyString(item?.mobile)
                              ? `${item?.mobile}`
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.email) && (
                        <div className="property-wanted-title">
                          <h5>Email</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.email) &&
                            isNonEmptyString(item?.email)
                              ? `${item?.email}`
                              : ""}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.dob) && (
                        <div className="property-wanted-title">
                          <h5>Age</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.dob) &&
                            isNonEmptyString(item?.dob)
                              ? moment(item?.dob, "mm/dd/yyyy")
                                  .fromNow()
                                  .split(" ")
                                  ?.filter((item) => item !== "ago")
                                  .join(" ")
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.gender) && (
                        <div className="property-wanted-title">
                          <h5>Gender</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.gender) &&
                            isNonEmptyString(item?.gender)
                              ? `${capitalizeFirstLetter(item?.gender)}`
                              : ""}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.number_of_children) && (
                        <div className="property-wanted-title">
                          <h5>Number of children</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.number_of_children) &&
                            isNonEmptyString(item?.number_of_children)
                              ? `${item?.number_of_children}`
                              : ""}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.nationality) && (
                        <div className="property-wanted-title">
                          <h5>Nationality</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.nationality) &&
                            isNonEmptyString(item?.nationality)
                              ? `${item?.nationality}`
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.occupation) && (
                        <div className="property-wanted-title">
                          <h5>Occupation</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.occupation) &&
                            isNonEmptyString(item?.occupation)
                              ? `${item?.occupation}`
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.smoker) && (
                        <div className="property-wanted-title">
                          <h5>Smoker</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.smoker) &&
                            isNonEmptyString(item?.smoker) === "1"
                              ? `Yes`
                              : "No"}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.pets) && (
                        <div className="property-wanted-title">
                          <h5>Pets</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.pets) &&
                            isNonEmptyString(item?.pets) === "1"
                              ? `Yes`
                              : "No"}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.pescatarian) &&
                      isNonEmptyString(item?.vegetarian) &&
                      item?.pescatarian === "0" &&
                      item?.vegetarian === "0" ? (
                        ""
                      ) : (
                        <div className="property-wanted-title">
                          <h5>Diet</h5>
                          <div>
                            {isNonEmptyString(item?.pescatarian) &&
                            isNonEmptyString(item?.vegetarian) &&
                            item?.vegetarian === "1"
                              ? `Vegetarian`
                              : item?.pescatarian === "1"
                              ? "Pescatarian"
                              : ""}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="middle-side">
                      <h3>Property Interest</h3>
                      {isNonEmptyString(item?.title) && (
                        <div className="property-wanted-title">
                          <h5>Looking for</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.title) &&
                            isNonEmptyString(item?.title)
                              ? `${item?.title}`
                              : ""}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.description) && (
                        <div className="property-wanted-title">
                          <h5>More details</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.description) &&
                            isNonEmptyString(item?.description)
                              ? `${(item?.description).replace(
                                  /<\/?[^>]+(>|$)/g,
                                  ""
                                )}`
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.type) && (
                        <div className="property-wanted-title">
                          <h5>Property type</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.type) &&
                            isNonEmptyString(item?.type)
                              ? `${item?.type}`
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.location) && (
                        <div className="property-wanted-title">
                          <h5>Location</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.location) &&
                            isNonEmptyString(item?.location)
                              ? `${item?.location}`
                              : ""}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.bedrooms) && (
                        <div className="property-wanted-title">
                          <h5>Bedrooms</h5>

                          <div className="d-flex align-items-end">
                            <img
                              src={bedroom}
                              alt="bathroom"
                              className="post-icon"
                            />
                            <span>{item?.bedrooms ? item?.bedrooms : ""}</span>
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.bathrooms) && (
                        <div className="property-wanted-title">
                          <h5>Bathrooms</h5>

                          <div className="d-flex align-items-end">
                            <img
                              src={bathroom}
                              alt="bathroom"
                              className="post-icon"
                            />
                            <span>
                              {item?.bathrooms ? item?.bathrooms : ""}
                            </span>
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.reception_rooms) && (
                        <div className="property-wanted-title">
                          <h5>Reception rooms</h5>
                          <div className="d-flex align-items-end">
                            <img
                              src={reception}
                              alt="bathroom"
                              className="post-icon"
                            />
                            <span>
                              {item?.reception_rooms
                                ? item?.reception_rooms
                                : ""}
                            </span>
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.keywords) && (
                        <div className="property-wanted-title">
                          <h5>Tags</h5>
                          <div>
                            {item?.keywords
                              ? parseStringArray(item?.keywords).map(
                                  (tag, index) => (
                                    <span
                                      className="badge bg-common me-1"
                                      key={index}
                                    >
                                      {tag}
                                    </span>
                                  )
                                )
                              : ""}
                          </div>
                        </div>
                      )}

                      {isNonEmptyString(item?.amenities) && (
                        <div className="property-wanted-title">
                          <h5>Amenities</h5>
                          <div>
                            {item?.amenities
                              ? parseStringArray(item?.amenities).map(
                                  (tag, index) => (
                                    <span
                                      className="badge bg-common me-1"
                                      key={index}
                                    >
                                      {tag}
                                    </span>
                                  )
                                )
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.features) && (
                        <div className="property-wanted-title">
                          <h5>Features</h5>
                          <div>
                            {item?.features
                              ? parseStringArray(item?.features).map(
                                  (tag, index) => (
                                    <span
                                      className="badge bg-common me-1"
                                      key={index}
                                    >
                                      {tag}
                                    </span>
                                  )
                                )
                              : ""}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="right-side">
                      {" "}
                      <h3>Financials/Status</h3>
                      {isNonEmptyString(item?.for_sale) &&
                        item?.for_sale !== null && (
                          <div className="property-wanted-title">
                            <h5>For sale</h5>
                            <div>
                              {" "}
                              {isNonEmptyString(item?.for_sale) &&
                              isNonEmptyString(item?.for_sale)
                                ? `${item?.for_sale}`
                                : ""}
                            </div>
                          </div>
                        )}
                      {isNonEmptyString(item?.to_rent) &&
                        item?.to_rent !== null && (
                          <div className="property-wanted-title">
                            <h5>To rent</h5>
                            <div>
                              {" "}
                              {isNonEmptyString(item?.to_rent) &&
                              isNonEmptyString(item?.to_rent)
                                ? `${item?.to_rent}`
                                : ""}
                            </div>
                          </div>
                        )}
                      {isNonEmptyString(item?.payment_style_type) && (
                        <div className="property-wanted-title">
                          <h5>Payment style</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.payment_style_type) &&
                            isNonEmptyString(item?.payment_style_type)
                              ? item?.payment_style_type
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.min_price) && (
                        <div className="property-wanted-title">
                          <h5>Min Budget</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.min_price) &&
                            isNonEmptyString(item?.min_price)
                              ? item?.min_price
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.max_price) && (
                        <div className="property-wanted-title">
                          <h5>Max Budget</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.max_price) &&
                            isNonEmptyString(item?.max_price)
                              ? item?.max_price
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.min_available_date) && (
                        <div className="property-wanted-title">
                          <h5>Move-in (Earliest Handover) date</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.min_available_date) &&
                            isNonEmptyString(item?.min_available_date)
                              ? `${formatOnlyDate(item?.min_available_date)}`
                              : ""}
                          </div>
                        </div>
                      )}
                      {isNonEmptyString(item?.max_available_date) && (
                        <div className="property-wanted-title">
                          <h5>Leaving (Latest Handover) date</h5>
                          <div>
                            {" "}
                            {isNonEmptyString(item?.max_available_date) &&
                            isNonEmptyString(item?.max_available_date)
                              ? `${formatOnlyDate(item?.max_available_date)}`
                              : ""}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null}
    </>
  );
};

export default PostDetailPostWanted;
