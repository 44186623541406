import React, { useCallback, useEffect, useRef, useState } from "react";
import ModalComponent from "../../../components/modalComponent/ModalComponent";
import useMyActivity from "./useMyActivity";
import ActivityCard from "./ActivityCard";
import { Tabs, Tab, TabList, TabPanel } from "react-tabs";
import ActivityContainer from "./ActivityContainer";
import { checkSubscriptionStatus, scrollToTop } from "helpers";
import PaginationComp from "components/pagination";
import useCheckAccountType from "./useCheckAccountType";
import PricingCard from "components/pricingCard/PricingCard";
import success from "../../../assets/images/check.png";
import rocket from "../../../assets/images/rocket.png";
import whiteRocket from "../../../assets/images/white-rocket.png";
import PricingPlan from "components/PricingPlan/PricingPlan";
import useMyPropertyMatches from "components/PricingPlan/useMyPropertyMatches";

function MyActivities() {
  const [activity, setActivity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, isError } = useMyActivity({
    type: activity,
    currentPage: currentPage,
  });

  const {
    data: userTotalMatches,
    isLoading: isUserTotalMatchesLoading,
    isError: isUserTotalMatchesError,
  } = useMyPropertyMatches({
    type: activity,
  });

  const {
    data: userDataType,
    isLoading: isUserTypeLoading,
    isError: isUserError,
  } = useCheckAccountType();
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabClick = (index) => {
    if (index === 1) {
      setActivity("matchedPropertyWanted");
    } else if (index === 0) {
      setActivity("Chain");
    }
    setTabIndex(index);
  };

  const isBlur = checkSubscriptionStatus(userDataType);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="activtity-btns">
        <button
          type="button"
          className="btn btn-common btn-follow px-lg-3 px-md-2 px-1"
          onClick={() => setActivity("myLikes")}
        >
          <i className="fa-regular fa-heart"></i>
        </button>
        <button
          type="button"
          className="btn btn-common btn-follow px-lg-3 px-md-2 px-1"
          onClick={() => setActivity("myComments")}
        >
          <i className="fa-regular fa-comments"></i>
        </button>
        <button
          type="button"
          className="btn btn-common btn-follow px-lg-3 px-2"
          onClick={() => setActivity("myPropertWanted")}
        >
          <i class="fa-solid fa-house"></i>
        </button>
        <button
          type="button"
          className="btn btn-common btn-follow px-lg-3 px-md-2 px-1"
          onClick={() => {
            setActivity("Chain");
          }}
        >
          <i class="fa-solid fa-link"></i>
        </button>
      </div>

      <ModalComponent
        show={activity}
        onHide={() => {
          setActivity(null);
          setCurrentPage(1);
          setTabIndex(0);
        }}
        heading={
          activity === "myLikes"
            ? "My Likes"
            : activity === "myComments"
            ? "My Comments"
            : activity === "myPropertWanted"
            ? "My Wanted Properties"
            : activity === "Chain"
            ? "My Leads"
            : ""
        }
        size="xl"
      >
        <>
          <div>
            {activity === "Chain" || activity === "matchedPropertyWanted" ? (
              <Tabs selectedIndex={tabIndex} onSelect={handleTabClick}>
                <TabList>
                  <Tab>Property Leads</Tab>
                  <Tab>Qualified Leads</Tab>
                </TabList>
                <TabPanel>
                  <div style={{ position: "relative" }}>
                    {isBlur && !isLoading && data?.results?.length !== 0 && (
                      <div className="blur">
                        <PricingPlan />
                      </div>
                    )}
                    <ActivityContainer
                      isLoading={isLoading || isUserTotalMatchesLoading}
                      data={data?.results}
                      activity={activity}
                      setActivity={setActivity}
                      isBlur={isBlur}
                      tabIndex={tabIndex}
                    />
                    {(data?.previous || data?.next) && (
                      <PaginationComp
                        currentPage={currentPage}
                        data={data}
                        previous={data?.previous}
                        next={data?.next}
                        scroll={scrollToTop}
                        setCurrentPage={setCurrentPage}
                      />
                    )}
                  </div>
                </TabPanel>
                {tabIndex === 1 && (
                  <TabPanel>
                    <div style={{ position: "relative" }}>
                      {isBlur && !isLoading && data?.results?.length !== 0 && (
                        <div className="blur">
                          <PricingPlan data={userTotalMatches?.results[0]} />
                        </div>
                      )}
                      <ActivityContainer
                        isLoading={isLoading || isUserTotalMatchesLoading}
                        data={data?.results}
                        activity={activity}
                        setActivity={setActivity}
                        isBlur={isBlur}
                        tabIndex={tabIndex}
                      />
                      {(data?.previous || data?.next) && (
                        <PaginationComp
                          currentPage={currentPage}
                          data={data?.results}
                          previous={data?.previous}
                          next={data?.next}
                          scroll={scrollToTop}
                          setCurrentPage={setCurrentPage}
                        />
                      )}
                    </div>
                  </TabPanel>
                )}
              </Tabs>
            ) : (
              <>
                <ActivityContainer
                  isLoading={isLoading}
                  data={data?.results}
                  activity={activity}
                  setActivity={setActivity}
                />

                {(data?.previous || data?.next) && (
                  <PaginationComp
                    currentPage={currentPage}
                    data={data}
                    previous={data?.previous}
                    next={data?.next}
                    scroll={scrollToTop}
                    setCurrentPage={setCurrentPage}
                  />
                )}
              </>
            )}
          </div>
        </>
      </ModalComponent>
    </>
  );
}

export default MyActivities;
