import React, { useCallback, useEffect } from "react";

const PaginationComp = ({
  currentPage,
  data,
  previous,
  next,
  scroll,
  setCurrentPage,
}) => {
  useEffect(() => {
    scroll();
  }, [currentPage]);
  const nextPage = useCallback(() => {
    // scroll()
    if (next) {
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, data]);

  const previousPage = useCallback(() => {
    // scroll()

    if (previous) {
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage, data]);
  return (
    <>
      {(previous || next) && (
        <div className="pagination">
          {/* Custom pagination links */}
          <button
            onClick={previousPage}
            disabled={!previous}
            className={`pagination_btn ${!previous ? "disabled" : ""}`}
          >
            &lt; Prev
          </button>
          <span>Page {currentPage}</span>
          <button
            onClick={nextPage}
            disabled={!next}
            className={`pagination_btn ${!next ? "disabled" : ""}`}
          >
            Next &gt;
          </button>
        </div>
      )}
    </>
  );
};

export default PaginationComp;
