// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.changePasswordBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}`, "",{"version":3,"sources":["webpack://./src/pages/setting/details/changePassword/changePassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,qBAAqB;AACzB","sourcesContent":[".changePasswordBox {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    /* flex-wrap: wrap; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
