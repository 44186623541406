import { hideLoadingSpinner, showLoadingSpinner } from "components/messageModal/MessageModal";
import { useQuery } from "react-query";

import { getSetting } from "services/settings";
import Swal from "sweetalert2";

const getSettings = async (userId) => {
  const res = await getSetting(userId);

//   console.log("res", res);
  if (res) {
    return res;
  }
  return null;
};

export default function useGetSettings(userId) {
  const result = useQuery(
    ["settings", userId],
    () => getSettings(userId),
    {
      enabled: !!userId,
    }
  );


  return result;
}


