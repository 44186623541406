// import { useState } from "react";
// import "./tagEmail.css";

// const TagEmailField = ({ tags, setTags }) => {
//   const [input, setInput] = useState("");
//   //   const [tags, setTags] = useState([]);
//   const [isKeyReleased, setIsKeyReleased] = useState(false);
//   const onChange = (e) => {
//     const { value } = e.target;
//     setInput(value);
//   };

//   const onKeyDown = (e) => {
//     const { key } = e;
//     const trimmedInput = input.trim();

//     if (key === "," && trimmedInput.length && !tags.includes(trimmedInput)) {
//       e.preventDefault();
//       setTags((prevState) => [...prevState, trimmedInput]);
//       setInput("");
//     }

//     if (
//       key === "Enter" &&
//       trimmedInput.length &&
//       !tags.includes(trimmedInput)
//     ) {
//       e.preventDefault();
//       setTags((prevState) => [...prevState, trimmedInput]);
//       setInput("");
//     }
//     if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
//       const tagsCopy = [...tags];
//       const poppedTag = tagsCopy.pop();
//       e.preventDefault();
//       setTags(tagsCopy);
//       setInput(poppedTag);
//     }

//     setIsKeyReleased(false);
//   };
//   const onKeyUp = () => {
//     setIsKeyReleased(true);
//   };
//   const deleteTag = (index) => {
//     setTags((prevState) => prevState.filter((tag, i) => i !== index));
//   };

//   return (
//     <div className="tag-container mb-3 " style={{ padding: "10px" }}>
//       {tags.map((tag, index) => (
//         <div className="tag" key={index}>
//           {tag}
//           <button type="button" onClick={() => deleteTag(index)}>
//             x
//           </button>
//         </div>
//       ))}
//       <input
//         type="email"
//         value={input}
//         placeholder="Enter a email"
//         onKeyDown={onKeyDown}
//         onKeyUp={onKeyUp}
//         onChange={onChange}
//       />
//     </div>
//   );
// };

// export default TagEmailField;




import { useState } from "react";
import "./tagEmail.css";

const TagEmailField = ({ tags, setTags }) => {
  const [input, setInput] = useState("");
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyDown = (e) => {
    const { key } = e;
    const trimmedInput = input.trim();

    if (
      (key === "," || key === "Enter") &&
      trimmedInput.length &&
      !tags.includes(trimmedInput) &&
      emailRegex.test(trimmedInput)
    ) {
      e.preventDefault();
      setTags((prevState) => [...prevState, trimmedInput]);
      setInput("");
    }

    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag);
    }

    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
  };

  return (
    <div className="tag-container mb-3" style={{ padding: "10px" }}>
      {tags.map((tag, index) => (
        <div className="tag" key={index}>
          {tag}
          <button type="button" onClick={() => deleteTag(index)}>
            x
          </button>
        </div>
      ))}
      <input
        type="email"
        value={input}
        placeholder="Enter emails here"
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />
    </div>
  );
};

export default TagEmailField;
